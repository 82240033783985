// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class ShipmentResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}/shipments{/sid}'
  }

  metrics (...args) {
    const metrics = new AxiosResource()
    metrics.path = '/v2/companies/{cid}/metrics/shipments'
    return metrics.get(...args)
  }

  proposals (...args) {
    return this.action('GET', '/proposals', ...args)
  }

  references (...args) {
    return this.action('PUT', '/references', ...args)
  }

  expirationDate (...args) {
    return this.action('PUT', '/expiration-date', ...args)
  }

  phone (...args) {
    return this.action('PUT', '/shipper-phone-number', ...args)
  }

  travel (...args) {
    return this.action('GET', '/travel', ...args)
  }

  bookings (...args) {
    return this.action('GET', '/bookings', ...args)
  }

  billing (...args) {
    return this.action('GET', '/billing', ...args)
  }

  rate (...args) {
    return this.action('POST', '/bookings{/bid}/rate', ...args)
  }

  pay (...args) {
    return this.action('POST', '/bookings{/bid}/pay', ...args)
  }

  cancel (...args) {
    return this.action('POST', '/cancel', ...args)
  }

  contactPickup (...args) {
    return this.action('PUT', '/pickup-contact', ...args)
  }

  contactDelivery (...args) {
    return this.action('PUT', '/delivery-contact', ...args)
  }
}
