<template>
  <div
    :class="{
      'offer-item-proposals__card': card,
      'has-proposal': proposalData && !isMapModeWithBestProposal,
      'no-content': !hasBestProposals
    }"
    class="tw-flex offer-item-proposals"
  >
    <div
      v-if="hasBestProposals"
      class="offer-item-proposals__best tw-text-blue-500 tw-flex tw-items-center"
    >
      <offer-item-proposals-text
        :offer="offer"
        :proposal="proposal"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import OfferItemProposalsText from './_subs/OfferItemProposalsText/index.vue'

  /**
   * @module component - offerItemProposals
   * @param {object} offer
   * @param {boolean} card - Specify if the item proposals is used in a card or not
   */
  export default defineComponent({
    name: 'OfferItemProposals',
    components: {
      OfferItemProposalsText
    },
    props: {
      offer: {
        type: Object,
        default: null
      },
      proposal: {
        type: Object,
        default: null
      },
      card: {
        type: Boolean,
        default: false
      },
      hasBestProposals: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isMapModeWithBestProposal () {
        return this.card && this.hasBestProposals
      },
      proposalData () {
        return this.proposal || this.offer.proposal
      },
      proposalCount () {
        return this.hasSelfProposal ? this.offer.proposal_count - 1 : this.offer.proposal_count
      },
      /**
       * Returns true if the carrier has made a proposal that
       * is included in the proposals count.
       * @function hasSelfProposal
       */
      hasSelfProposal () {
        const {
          proposal_count: proposalCount
        } = this.offer

        return !!this.proposalData && (this.proposalData.state === 'pending' || this.proposalData.status === 'pending') && proposalCount >= 1
      }
    }
  })
</script>

<style lang="scss" scoped>

  .offer-item-proposals {
    border-radius: 0 0 2px 2px;

    &__self > div {
      margin: auto 0;
    }

    &__label,
    &__value {
      margin: auto 0;
    }

    &__value {
      background-color: white;
      border: 1px solid $info;
      color: $info;
      border-radius: 4px;
      padding: 0 8px;
    }

    &:not(.offer-item-proposals__card) {
      padding: 3px 30px 3px 40px;
    }

    &__card {
      padding: 5px 5px 10px 30px;
      text-align: left;

      &.active {
        border-top-color: darken(#ECEBEC, 10%);
      }
    }

    &.active {
      border-top-color: #F7F7F7;
    }

    &.no-content {
      padding: 0;
      border-top: none;
    }
  }

</style>
