<template>
  <!-- Single proposal from a carrier (ourselves) -->
  <span
    class="offer-item-proposals-text"
  >
    <span
      v-if="proposalCount === 0"
      class="offer-item-proposals-text"
      v-markdown="$tc(isOnDates ? 'offer_list.item.proposals.me_dates' : 'offer_list.item.proposals.me_amount', proposalCount, {
        count: $n(proposalCount),
        amount: isOnDates ? null : $options.filters.currency(offer.lowest_proposal_price, offer.pricing.currency, $i18n.locale)
      })"
    />

    <template
      v-else
    >
      <!-- More than one proposal -->
      <span
        v-if="isOnDates"
      >
        {{ $tc(hasSelfProposal ? 'offer_list.item.proposals.me_and_many_proposals_dates' : 'offer_list.item.proposals.many_proposals_dates', proposalCount, {
          count: $n(proposalCount)
        }) }}
      </span>

      <!-- Proposals on the amounts -->
      <span
        v-else
        v-markdown="$tc(hasSelfProposal ? 'offer_list.item.proposals.me_and_many_proposals' : 'offer_list.item.proposals.many_proposals_amount', proposalCount, {
          count: $n(proposalCount),
          amount: isOnDates ? null : $options.filters.currency(offer.lowest_proposal_price, offer.pricing.currency, $i18n.locale)
        })"
      />
    </template>
  </span>
</template>

<script>
  /**
   * Component used to show the various versions of proposals texts, used in the
   * list and the offer detail. It handles all the logic
   * @module component - offerItemProposalsText
   * @param {object} offer
   * @param {object} [proposal=null] - Optional proposal object
   */
  export default {
    name: 'OfferItemProposalsText',
    props: {
      offer: {
        type: Object,
        required: true
      },
      proposal: {
        type: Object,
        default: null
      }
    },
    computed: {
      proposalData () {
        return this.proposal || this.offer.proposal
      },
      proposalCount () {
        return this.hasSelfProposal ? this.offer.proposal_count - 1 : this.offer.proposal_count
      },
      price () {
        const { pricing } = this.offer
        return pricing.carrier_price || pricing.price
      },
      /**
       * Returns true if the proposals are on dates only.
       * @function isOnDates
       * @returns {boolean}
       */
      isOnDates () {
        return typeof this.offer.lowest_proposal_price === 'undefined'
      },
      /**
       * Returns true if the carrier has made a proposal that
       * is included in the proposals count.
       * @function hasSelfProposal
       */
      hasSelfProposal () {
        const {
          proposal_count: proposalCount
        } = this.offer

        return !!this.proposalData && (this.proposalData.state === 'pending' || this.proposalData.status === 'pending') && proposalCount >= 1
      }
    }
  }
</script>

<style lang="scss">

  .offer-item-proposals-text {
    font-size: 13px;

    &.markdown p,
    .markdown p {
      display: inline-block;
      margin: 0;
    }

    strong {
      font-weight: 500;
    }
  }

</style>
