// @ts-nocheck
export default {
  getInvoicesItems (state) {
    return state.invoices.items
  },
  getInvoicesMeta (state) {
    return state.invoices.meta
  },
  getInvoicesMetrics (state) {
    return state.invoices.metrics
  },
  getPaymentsMetrics: state => state.payments.metrics,
  getPaymentsItems (state) {
    return state.payments.items
  },
  getPaymentsMeta (state) {
    return state.payments.meta
  },
  getPaymentsCurrentPage (state) {
    return state.payments.currentPage
  },
  getInvoicesCurrentPage (state) {
    return state.invoices.currentPage
  },
  getCurrentInvoice (state, getters, rootState) {
    return state.invoices.items.find(item => item.uuid === rootState.route.params.stateUuid)
  },
  getCurrentPayment (state, getters, rootState) {
    return state.payments.items.find(item => item.uuid === rootState.route.params.uuid)
  },
  getCurrentPaymentInvoices (state, getters) {
    return getters.getCurrentPayment ? getters.getCurrentPayment.invoices : []
  }
}
