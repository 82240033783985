import SearchModel from './models/SearchModel'
import MetaOffersModel from './models/MetaOffersModel'
import Storage from '@/services/Storage'

export default () => {
  /**
   * Sanitize localStorage values. If the values are not included in the
   * available ones, set a default value.
   * TODO: Move this somewhere else
   */
  const AUTHORIZED_OFFERS_SORT_TYPES = ['expires_at', '-expires_at', 'price', '-price', 'pickup_date', '-pickup_date', 'delivery_date', '-delivery_date']

  let offersSortType = Storage && Storage.getItem('sortType')
  if (!offersSortType || (offersSortType && !AUTHORIZED_OFFERS_SORT_TYPES.includes(offersSortType))) offersSortType = 'pickup_date'

  return {
    currentSearch: new SearchModel(),
    sameZonesManual: false,
    searches: [],
    proposals: [],
    proposalState: 'all',
    offersMetrics: {
      total: null,
      unviewed_item_count: null
    },
    proposalsMetrics: {
      total: null,
      pending: null
    },
    proposalsMeta: {
      item_count: 0,
      pagination: {
        current_page: 0
      }
    },
    offers: [],
    offersSuggestions: {
      items: [],
      visible: false
    },
    currentOffersMeta: new MetaOffersModel(),
    offerPagination: 1,
    currentOffer: null,
    currentProposal: null,
    proposalGetState: false,
    offersSortType,
    keyboardStatus: true,
    itineraries: [],
    selectedDimension: 'spl',
    previousRoute: null
  }
}
