<template>
  <div class="tw-font-medium tw-text-center ctk-item-tracking tw-flex tw-flex-col tw-items-center">
    <div
      class="ctk-item-tracking__wrapper"
    >
      <div
        class="ctk-item-tracking__label tw-rounded px-2 tw-font-normal tw-text-blue-500 mb-2 dots-text"
        :style="{
          'border-bottom-right-radius': isLastStep ? '0' : '4px',
          'border-bottom-left-radius': isFirstStep ? '0' : '4px'
        }"
      >
        {{ label }}
      </div>
      <span
        class="ctk-item-tracking__arrow"
        :style="{
          left: arrowPosition
        }"
      />
      <div class="ctk-item-tracking__steps tw-flex tw-items-center">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  /**
   * @module component - CtkItemTracking
   * @param {string} label
   * @param {number} [activeStep=0]
   */
  export default {
    name: 'CtkItemTracking',
    props: {
      label: {
        type: String,
        required: true
      },
      activeStep: {
        type: Number,
        default: 0
      }
    },
    computed: {
      /**
       * Returns the number of steps
       * @function steps
       * @returns {number}
       */
      steps () {
        return this.$slots.default.filter(slot => slot.tag === 'div').length
      },
      /**
       * Returns true if the activeStep is the first
       * @function isFirstStep
       * @returns {boolean}
       */
      isFirstStep () {
        return this.activeStep === 0
      },
      /**
       * Returns true if the activeStep is the last one of the steps
       * @function isLastStep
       * @returns {boolean}
       */
      isLastStep () {
        return this.steps - 1 === this.activeStep
      },
      /**
       * Returns a CSS left value for the arrow position, according to the
       * number of children slots & the activeStep property.
       * @function arrowPosition
       * @returns {string}
       */
      arrowPosition () {
        const percent = (100 / (this.steps - 1)) * this.activeStep
        const arrowWidth = 10
        const offset = (arrowWidth * percent) / 100

        return `calc(${percent}% - ${offset}px)`
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-item-tracking {
    &__label {
      position: relative;
      background-color: $light-gray;
    }

    &__wrapper {
      position: relative;
      width: 70%;

      @media only screen and (max-width: $breakpoint-tablet) {
        width: 90%;
      }
    }

    &__arrow {
      position: absolute;
      bottom: 17px;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: $light-gray transparent transparent transparent;
    }

    &__steps {
      height: 14px;
    }
  }

</style>
