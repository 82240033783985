export default {
  methods: {
    mount () {
      window.addEventListener('keyup', this.keyPressed)
    },
    unmount () {
      window.removeEventListener('keyup', this.keyPressed)
    },
    /**
     * Function called whenever a key is pressed
     * @function keyPressed
     * @param {object} e - Event object
     */
    async keyPressed (e) {
      if (e.key === 'Escape') {
        this.close()
      }
    }
  }
}
