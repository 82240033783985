<template>
  <div
    class="ctk-summary-card tw-rounded tw-relative tw-pb-4 tw-pt-8 tw-px-5 tw-mt-4 tw-bg-white"
  >
    <ctk-summary-card-title
      v-if="title"
      :title="title"
      class="ctk-summary-card__title tw-absolute tw--top-4 tw-left-5"
      data-test="title"
    />

    <div
      class="tw-flex tw-flex-col"
    >
      <slot name="pickup" />
      <slot name="delivery" />
      <slot name="load" />
    </div>

    <div class="ctk-summary-card__price tw-absolute tw-right-5">
      <slot name="price" />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkSummaryCardTitle from './_subs/CtkSummaryCardTitle/index.vue'

  /**
   * @module component - CtkSummaryCard
   * @param {string} title - The title of the card.
   */
  export default defineComponent({
    name: 'CtkSummaryCard',
    components: {
      CtkSummaryCardTitle
    },
    props: {
      title: {
        type: String,
        default: null
      }
    }
  })
</script>
