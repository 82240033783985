import currency from './currency'
import capitalize from './capitalize'
import uppercase from './uppercase'
import telephone from './telephone'
import duration from './duration'

/** @type {{ [s: string]: Function }} */
const FILTERS = {
  currency,
  capitalize,
  telephone,
  duration,
  uppercase
}

export default {
  /**
   * @function create
   * @param {import('vue').VueConstructor} Vue
   */
  create: function (Vue) {
    for (const filterName in FILTERS) {
      Vue.filter(filterName, FILTERS[filterName])
    }
  }
}
