var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ctk-aside-dialog',{attrs:{"title":_vm.$options.filters.capitalize(_vm.$t('your_proposal')),"modal-class":("dialog-proposal dialog-proposal--step-" + _vm.step)},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('div',[_c('offer-summary-card',{staticClass:"tw-mb-5",attrs:{"title":_vm.$t('proposal_form.initial_offer'),"offer":_vm.getCurrentOffer}}),_c('new-shipment-acceptation-dialog-reassurance',{attrs:{"icon":require('@/assets/img/icons/quotation/contract.svg'),"title":_vm.$t('app.labels.engagement'),"content":_vm.$t('offers.paragraphs.proposal.engagement')}})],1)]},proxy:true}]),model:{value:(_vm.modalState),callback:function ($$v) {_vm.modalState=$$v},expression:"modalState"}},[_c('div',{staticClass:"tw-relative tw-flex tw-flex-col tw-p-4 md:tw-px-10 md:tw-pb-10 md:tw-pt-28 tw-h-full tw-overflow-y-auto"},[(_vm.hasOptions && _vm.step === 2)?_c('summary-offer',{attrs:{"offer":_vm.getCurrentOffer,"button":_vm.$t('proposal_form.submit_button'),"content":_vm.$t('offer.proposal_dialog.summary_offer.content'),"has-back-button":""},on:{"next":_vm.saveProposal,"back":function($event){_vm.step = 1}}}):(_vm.step === 0 || _vm.step === 1)?_c('div',{staticClass:"tw-flex tw-flex-col tw-h-full tw-flex-1"},[(_vm.step === 0)?_c('ValidationObserver',{ref:"priceDatesObserver",staticClass:"tw-flex-1",attrs:{"tag":"div"}},[_c('div',{staticClass:"tw-mb-8 md:tw-mb-16"},[_c('div',{staticClass:"tw-flex tw-items-center tw-mb-3"},[_c('ui-ctk-icon',{staticClass:"tw-text-blue-500 tw--ml-2",attrs:{"name":"budget"}}),_c('div',{staticClass:"tw-text-secondary tw-text-base",attrs:{"id":"proposal-different-price"},domProps:{"textContent":_vm._s(_vm.$t('offers.titles.different_price'))}})],1),_c('div',{staticClass:"tw-pl-7"},[_c('ui-fat-radio-group',{staticClass:"dialog-proposal__radio tw-flex tw-mb-5",attrs:{"items":[
                {
                  title: _vm.$i18n.t('yes'),
                  value: true
                },
                {
                  title: _vm.$i18n.t('no'),
                  value: false
                }
              ],"aria-labelledby":"proposal-different-price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var item = ref.item;
              var active = ref.active;
              var keydown = ref.keydown;
return [_c('ui-fat-radio-item',{attrs:{"title":item.title,"active":active},nativeOn:{"keydown":function($event){return keydown.apply(null, arguments)},"click":function($event){_vm.hasPriceChange = item.value}}},[_c('div',{domProps:{"textContent":_vm._s(item.title)}})])]}}],null,false,1383901072),model:{value:(_vm.hasPriceChange),callback:function ($$v) {_vm.hasPriceChange=$$v},expression:"hasPriceChange"}}),(_vm.hasPriceChange)?_c('div',[_c('ValidationProvider',{ref:"price-provider",attrs:{"name":_vm.$t('app.labels.price'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var invalid = ref.invalid;
              var validated = ref.validated;
return [_c('ctk-input-text',{attrs:{"id":"priceInput","hint":errors[0],"error":invalid && validated || errors && errors.length > 0,"label":_vm._f("capitalize")(_vm.$t('proposal_form.price.label')),"type":"number","min":"0","required":""},model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", _vm._n($$v))},expression:"formData.price"}})]}}],null,false,4135282076)}),(_vm.hasProposalWarning && _vm.proposalWarning)?_c('div',{staticClass:"tw-relative tw-flex tw-pl-3 tw-text-sm tw-font-medium proposal-warning tw-mt-1",class:[("proposal-warning--" + (_vm.proposalWarning.key))],domProps:{"textContent":_vm._s(_vm.$t(_vm.proposalWarning.message))}}):_vm._e()],1):_vm._e()],1)]),_c('div',[_c('div',{staticClass:"tw-flex tw-items-center tw-mb-3"},[_c('ui-ctk-icon',{staticClass:"tw-text-blue-500 tw--ml-2",attrs:{"name":"calendar"}}),_c('div',{staticClass:"tw-text-secondary tw-text-base",attrs:{"id":"proposal-different-dates"},domProps:{"textContent":_vm._s(_vm.$t('offers.titles.different_dates'))}})],1),_c('div',{staticClass:"tw-pl-7"},[_c('ui-fat-radio-group',{staticClass:"dialog-proposal__radio tw-flex tw-mb-5",attrs:{"items":[
                {
                  title: _vm.$i18n.t('yes'),
                  value: true
                },
                {
                  title: _vm.$i18n.t('no'),
                  value: false
                }
              ],"aria-labelledby":"proposal-different-dates"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var item = ref.item;
              var active = ref.active;
              var keydown = ref.keydown;
return [_c('ui-fat-radio-item',{attrs:{"title":item.title,"active":active},nativeOn:{"keydown":function($event){return keydown.apply(null, arguments)},"click":function($event){_vm.hasDatesChange = item.value}}},[_c('div',{domProps:{"textContent":_vm._s(item.title)}})])]}}],null,false,4261228858),model:{value:(_vm.hasDatesChange),callback:function ($$v) {_vm.hasDatesChange=$$v},expression:"hasDatesChange"}}),(_vm.hasDatesChange)?_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-flex-1 tw-mb-4"},[_c('ValidationProvider',{ref:'pickup_date-provider',attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
              var errors = ref.errors;
              var clear = ref.clear;
              var validated = ref.validated;
return [_c('ctk-date-time-picker',{attrs:{"id":'pickup-date',"label":_vm._f("capitalize")(_vm.$t('pickup_date')),"locale":_vm.$i18n.locale,"min-date":_vm.allowedDates.pickup,"hint":errors[0],"error":invalid && validated || errors && errors.length > 0,"color":"#287696","format":"YYYY-MM-DD","formatted":"ddd LL","no-button":"","only-date":"","auto-close":"","position":"top"},on:{"input":clear},model:{value:(_vm.formData.dates.pickup),callback:function ($$v) {_vm.$set(_vm.formData.dates, "pickup", $$v)},expression:"formData.dates.pickup"}})]}}],null,false,1509822539)})],1),_c('div',{staticClass:"tw-flex-1"},[_c('ValidationProvider',{ref:"delivery_date-provider",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
              var errors = ref.errors;
              var clear = ref.clear;
              var validated = ref.validated;
return [_c('ctk-date-time-picker',{attrs:{"id":'delivery-date',"label":_vm._f("capitalize")(_vm.$t('delivery_date')),"locale":_vm.$i18n.locale,"min-date":_vm.allowedDates.delivery,"hint":errors[0],"error":invalid && validated || errors && errors.length > 0,"color":"#287696","format":"YYYY-MM-DD","formatted":"ddd LL","no-button":"","only-date":"","auto-close":"","position":"top"},on:{"input":clear},model:{value:(_vm.formData.dates.delivery),callback:function ($$v) {_vm.$set(_vm.formData.dates, "delivery", $$v)},expression:"formData.dates.delivery"}})]}}],null,false,3240463691)})],1)]):_vm._e()],1)]),(_vm.hasDatesChange === false && _vm.hasPriceChange === false)?_c('p',{staticClass:"tw-text-gray-800 tw-bg-gray-200 tw-px-4 tw-py-3 tw-rounded tw-mt-8",domProps:{"textContent":_vm._s(_vm.$t('offers.paragraphs.proposal.select_price_dates'))}}):_vm._e()]):_vm._e(),(_vm.step === 1)?_c('ValidationObserver',{ref:"expirationObserver",staticClass:"tw-flex-1",attrs:{"tag":"div"}},[_c('h5',{staticClass:"tw-font-normal tw-mb-4",domProps:{"textContent":_vm._s(_vm.$options.filters.capitalize(_vm.$t('proposal_form.proposal_expiration')))}}),_c('p',{staticClass:"tw-text-gray-700 tw-mb-5",domProps:{"textContent":_vm._s(_vm.$t('proposal_form.proposal_expiration_explication'))}}),_c('div',{staticClass:"tw-flex tw-mb-4"},[_c('ValidationProvider',{ref:"expiration_date-provider",attrs:{"rules":"required","name":_vm.$t('app.labels.date'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
              var errors = ref.errors;
              var clear = ref.clear;
              var validated = ref.validated;
return [_c('ctk-date-time-picker',{staticClass:"expiry-date",attrs:{"id":"expiration-date","label":_vm._f("capitalize")(_vm.$t('proposal_form.expiration_date')),"locale":_vm.$i18n.locale,"minute-interval":10,"min-date":_vm.allowedDates.pickup,"hint":errors[0],"error":invalid && validated || errors && errors.length > 0,"color":"#287696","format":"YYYY-MM-DDTHH:mm","no-button-now":""},on:{"input":clear},model:{value:(_vm.formData.expiration),callback:function ($$v) {_vm.$set(_vm.formData, "expiration", $$v)},expression:"formData.expiration"}})]}}],null,false,2485988135)})],1),_c('ValidationProvider',{ref:"comment-provider",attrs:{"name":_vm.$t('comment'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
              var errors = ref.errors;
              var validated = ref.validated;
return [_c('ctk-input-textarea',{attrs:{"id":"commentInput","label":_vm.$t('app.labels.comment'),"hint":errors[0],"error":invalid && validated || errors && errors.length > 0},model:{value:(_vm.formData.expirationComment),callback:function ($$v) {_vm.$set(_vm.formData, "expirationComment", $$v)},expression:"formData.expirationComment"}})]}}],null,false,580635978)})],1):_vm._e(),_c('div',{staticClass:"dialog-proposal__footer tw-w-full tw-pt-3 tw-mt-4 tw-flex-shrink-0"},[_c('div',{staticClass:"tw-flex tw-flex-col-reverse md:tw-flex-row tw-items-center tw-justify-between"},[_c('div',{staticClass:"tw-w-full md:tw-w-auto"},[_c('ui-button',{staticClass:"tw-w-full md:tw-w-auto tw-mt-4 md:tw-mt-0",attrs:{"variant":"link"},on:{"click":_vm.previous}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t(_vm.step === 0 ? 'cancel' : 'back')))+" ")])],1),_c('ui-button',{staticClass:"tw-w-full md:tw-w-auto",attrs:{"disabled":_vm.isNextDisabled || _vm.$wait.is('creating proposal'),"loading":_vm.$wait.is('creating proposal'),"variant":"primary"},on:{"click":function($event){_vm.step === 0 ? _vm.savePriceDates() : _vm.saveExpiration()}}},[_vm._v(" "+_vm._s(_vm.hasOptions ? _vm.$t('app.buttons.next') : _vm.$t('proposal_form.submit_button'))+" ")])],1)])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }