// @ts-nocheck
export default {
  SET_USERS (state, users) {
    state.users.items = users
  },
  SET_USERS_META (state, meta) {
    state.users.meta = meta
  },
  SET_INVITES (state, invites) {
    state.invites.items = invites
  },
  SET_INVITES_META (state, meta) {
    state.invites.meta = meta
  },
  ADD_USER (state, user) {
    state.users.items.push(user)
  },
  ADD_INVITE (state, invite) {
    state.invites.items.push(invite)
  },
  ADD_USERS (state, users) {
    state.users = [
      ...state.users,
      ...users
    ]
  },
  ADD_INVITES (state, invites) {
    state.invites = [
      ...state.invites,
      ...invites
    ]
  },
  REMOVE_INVITE (state, uuid) {
    const index = state.invites.items.findIndex(invite => invite.uuid === uuid)
    if (index !== -1) state.invites.items.splice(index, 1)
  },
  REMOVE_MEMBER (state, uuid) {
    const index = state.users.items.findIndex(user => user.uuid === uuid)
    if (index !== -1) state.users.items.splice(index, 1)
  }
}
