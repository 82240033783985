<template>
  <div class="ctk-notifications-dialog-item br-4">
    <ctk-item
      :to="{
        name: 'Shipment',
        params: {
          uuid: shipment.uuid
        }
      }"
      class="flex flex-direction-column ctk-notifications-dialog-item__link br-4"
      touchable-type="router-link"
      @click.native="clicked"
    >
      <ctk-notifications-dialog-item-header
        :shipment="shipment"
        data-test="header"
      />

      <div class="tw-flex tw-py-2 tw-relative tw-px-4">
        <shipment-item-address-dates
          :shipment="shipment"
          class="tw-flex-1 tw-truncate"
        />
        <shipment-item-options
          :shipment="shipment"
          class="tw-hidden md:tw-flex pr-3 pl-4 tw-justify-end"
        />
      </div>
    </ctk-item>

    <ctk-notifications-dialog-item-footer
      :shipment="shipment"
      data-test="footer"
      @click="clicked"
    />
  </div>
</template>

<script>
  import CtkItem from '@/components/CtkItem/index.vue'
  import ShipmentItemAddressDates from '@/views/Shippers/Shipments/components/ShipmentsList/_subs/ShipmentItem/_subs/ShipmentItemContent/_subs/ShipmentItemAddressDates/index.vue'
  import ShipmentItemOptions from '@/views/Shippers/Shipments/components/ShipmentsList/_subs/ShipmentItem/_subs/ShipmentItemContent/_subs/ShipmentItemOptions/index.vue'
  import CtkNotificationsDialogItemHeader from './_subs/CtkNotificationsDialogItemHeader/index.vue'
  import CtkNotificationsDialogItemFooter from './_subs/CtkNotificationsDialogItemFooter/index.vue'

  /**
   * @module component - shipmentItem
   * @param {object} shipment
   * @emits click
   */
  export default {
    name: 'ShipmentItem',
    components: {
      CtkItem,
      CtkNotificationsDialogItemHeader,
      CtkNotificationsDialogItemFooter,
      ShipmentItemAddressDates,
      ShipmentItemOptions
    },
    props: {
      shipment: {
        type: Object,
        required: true
      }
    },
    methods: {
      clicked () {
        this.$emit('click')
      }
    }
  }
</script>

<style lang="scss">

  .ctk-notifications-dialog-item {
    position: relative;
    background-color: white;
    border: 1px solid #E9E9EA;

    &__link {
      .ctk-notifications-dialog-item-header,
      .ctk-notifications-dialog-item-content {
        transition: background-color 200ms ease;
      }

      &:hover {
        .ctk-notifications-dialog-item-header {
          background-color: #DADADB;
        }

        .ctk-notifications-dialog-item-content {
          background-color: #F3F3F3;
        }

        .ctk-item-tracking__label,
        .ctk-item-tracking-divider:not(.active),
        .ctk-item-tracking-dot:not(.active):not(.completed) {
          background-color: white;
        }

        .ctk-item-tracking__arrow {
          border-color: white transparent transparent transparent;
        }
      }

      &:focus {
        position: relative;
        outline: none;
        box-shadow: 0 1px 3px rgba(black, 0.2);

        .ctk-notifications-dialog-item-header {
          background-color: darken($divider, 10%);
        }

        .ctk-notifications-dialog-item-content {
          background-color: $divider;
        }
      }
    }

    &-footer {
      display: none;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      .ctk-notifications-dialog-item-footer {
        display: flex;
        min-height: 16px;
      }
    }
  }

</style>
