<template>
  <div class="shipment-item-rate tw-flex tw-flex-col">
    <ctk-input-rate
      id="rate"
      v-model="rate"
      :read-only="hasRate"
      :star-size="20"
      :keep-focus="false"
    />
  </div>
</template>

<script>
  import { EventBus } from '@/services/EventBus'
  import CtkInputRate from '@/components/CtkInputs/CtkInputRate'

  /**
   * @module component shipmentItemRate
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentItemRate',
    props: {
      shipment: {
        type: Object,
        required: true
      }
    },
    components: {
      CtkInputRate
    },
    computed: {
      /**
       * Returns true if the shipment has been rated
       * @function hasRate
       * @returns {boolean}
       */
      hasRate () {
        const { mission } = this.shipment
        return !!(mission && mission.rating && mission.rating.score)
      },
      rate: {
        get () {
          return this.shipment.mission && this.shipment.mission.rating && this.shipment.mission.rating.score
        },
        set (value) {
          EventBus.$emit('shipments:rate-shipment', {
            value,
            shipment: this.shipment
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-item-rate {
    & .ctk-input-rate {
      position: relative;
      width: 70%;
      margin: auto;

      @media only screen and (max-width: $breakpoint-tablet) {
        width: 100%;
      }
    }
  }

</style>
