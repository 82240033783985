<template>
  <button
    :class="{
      active: $attrs.active
    }"
    :title="title"
    :disabled="$attrs.disabled"
    type="button"
    class="tabs-bar-item tw-cursor-pointer tw-appearance-none tw-border-none tw-text-center tw-items-center tw-flex tw-h-full tw-max-h-full tw-px-3"
  >
    <span
      class="tw-mx-auto tw-truncate"
      data-test="title"
      v-text="title"
    />
  </button>
</template>

<script>
  /**
   * @module component - TabsBarItem
   * @param {string} title
   */
  export default {
    name: 'TabsBarItem',
    inheritAttrs: true,
    props: {
      title: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
.tabs-bar-item {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
  transition: color 200ms;
  background: transparent;
  outline: none;
}
.tabs-bar-item.active {
  color: $info;
  position: relative;
}
.tabs-bar-item:disabled {
  color: #CCC;
  cursor: not-allowed;
}
</style>
