<template>
  <button
    class="ctk-nav-bar-mobile-sidebar-button tw-flex tw-border-none tw-appearance-none tw-cursor-pointer"
    type="button"
  >
    <div
      id="icon"
      :class="{ open }"
    >
      <span />
      <span />
      <span />
    </div>
  </button>
</template>

<script>
  /**
   * @module component - CtkNavBarMobileSidebarButton
   * @param {boolean} [open=false]
   */
  export default {
    name: 'CtkNavBarMobileSidebarButton',
    props: {
      open: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-nav-bar-mobile-sidebar-button {
    background: transparent;
    color: rgba(white, 0.8);
    transition: background-color 200ms, color 200ms;

    &:hover {
      background-color: rgba(#303144, 0.8);
    }

    &:focus,
    &.active {
      background-color: #303144;
      color: white;
    }

    &.active {
      border-bottom: 3px solid white;
    }

    /* Icon 1 */

    #icon {
      width: 30px;
      height: 24px;
      position: relative;
      transform: rotate(0deg) scale(0.7);
      transition: 0.5s ease-in-out;
      cursor: pointer;
      margin: auto;
    }

    #icon span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: white;
      border-radius: 2;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }

    #icon span:nth-child(1) {
      top: 0;
      transform-origin: left center;
    }

    #icon span:nth-child(2) {
      top: 12px;
      transform-origin: left center;
    }

    #icon span:nth-child(3) {
      top: 24px;
      transform-origin: left center;
    }

    #icon.open span:nth-child(1) {
      transform: rotate(45deg);
      top: 0;
      left: 4px;
    }

    #icon.open span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    #icon.open span:nth-child(3) {
      transform: rotate(-45deg);
      top: 21px;
      left: 4px;
    }
  }

</style>
