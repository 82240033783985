<template>
  <header
    class="ctk-nav-bar tw-relative tw-flex tw-flex-grow-0 tw-flex-shrink-0 tw-w-full md:tw-justify-between tw-justify-center"
  >
    <ctk-nav-bar-mobile-sidebar-button
      class="tw-flex tw-absolute tw-left-0 tw-top-0 md:tw-hidden"
      :open="sidebarOpen"
      @click.native="sidebarOpen = !sidebarOpen"
    />
    <ctk-nav-bar-logo
      class="tw-mt-auto tw-mb-auto tw-ml-5 tw-mr-0"
    />
    <nav class="ctk-nav-bar-navigation__desktop">
      <ctk-nav-bar-navigation
        class="tw-hidden md:tw-flex ctk-nav-bar-navigation--desktop tw-items-center"
      />
    </nav>
    <div class="ctk-nav-bar-actions__desktop tw-hidden md:tw-flex">
      <ctk-nav-bar-actions />
      <ctk-nav-bar-user-menu />
    </div>

    <transition
      name="navigation-sidebar-transition"
      tag="div"
      mode="out-in"
    >
      <ctk-nav-bar-mobile-sidebar
        v-if="sidebarOpen"
        class="tw-absolute tw-flex md:tw-hidden"
        @close="sidebarOpen = false"
      >
        <template #navigation>
          <ctk-nav-bar-navigation
            class="tw-flex tw-flex-col ctk-nav-bar-navigation--mobile"
            @close="sidebarOpen = false"
          />
        </template>
        <template #actions>
          <ctk-nav-bar-actions
            class="ctk-nav-bar-actions--mobile"
            @close="sidebarOpen = false"
          />
        </template>
        <template #user-menu>
          <ctk-nav-bar-mobile-user-menu
            @close="sidebarOpen = false"
          />
        </template>
      </ctk-nav-bar-mobile-sidebar>
    </transition>
  </header>
</template>

<script>
  import CtkNavBarUserMenu from './_subs/CtkNavBarUserMenu/index.vue'
  import CtkNavBarLogo from './_subs/CtkNavBarLogo/index.vue'
  import CtkNavBarNavigation from './_subs/CtkNavBarNavigation/index.vue'
  import CtkNavBarActions from './_subs/CtkNavBarActions/index.vue'
  import CtkNavBarMobileSidebar from './_subs/CtkNavBarMobileSidebar/index.vue'
  import CtkNavBarMobileSidebarButton from './_subs/CtkNavBarMobileSidebar/_subs/CtkNavBarMobileSidebarButton/index.vue'
  import CtkNavBarMobileUserMenu from './_subs/CtkNavBarMobileUserMenu/index.vue'

  /**
   * Main navigation component, used in the layout.
   * @module component - ctkNavBar
   */
  export default {
    name: 'CtkNavBar',
    components: {
      CtkNavBarUserMenu,
      CtkNavBarLogo,
      CtkNavBarNavigation,
      CtkNavBarActions,
      CtkNavBarMobileSidebar,
      CtkNavBarMobileSidebarButton,
      CtkNavBarMobileUserMenu
    },
    data () {
      return {
        sidebarOpen: false
      }
    }
  }
</script>

<style lang="scss">

  $navbar-height: 55px;

  .ctk-nav-bar {
    height: $navbar-height;
    background-color: $secondary;

    .ctk-nav-bar-mobile-sidebar {
      @media only screen and (max-width: $breakpoint-tablet) {
        top: $navbar-height;
        left: 0;
        width: 100%;
        width: 100vw;
        height: 100%;
        height: calc(100% - #{$navbar-height});
        height: calc(100vh - #{$navbar-height});
        overflow: hidden;
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      .ctk-nav-bar-mobile-sidebar-button {
        height: $navbar-height;
      }
    }
  }

  .navigation-sidebar-transition {
    &-enter-active,
    &-leave-active {
      transition: opacity 200ms ease-in-out;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }

</style>
