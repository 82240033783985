<template>
  <ctk-item-header
    class="ctk-notifications-dialog-item-header"
  >
    <div
      v-text="$t('app.paragraphs.shipment_ref', {
        reference: shipment.reference
      })"
      class="tw-my-auto"
    />
  </ctk-item-header>
</template>

<script>
  import CtkItemHeader from '@/components/CtkItem/_subs/CtkItemHeader/index.vue'

  /**
   * @module component - CtkNotificationsDialogItemHeader
   * @param {object} shipment
   */
  export default {
    name: 'CtkNotificationsDialogItemHeader',
    components: {
      CtkItemHeader
    },
    props: {
      shipment: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-notifications-dialog-item-header {
    padding: 0 1rem;
  }

</style>
