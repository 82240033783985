import URITemplate from 'urijs/src/URITemplate'
import axios from './axios'

/**
 * @typedef AxiosURI
 * @property {any} [uriTemplate]
 *
 * @typedef {import('axios').AxiosRequestConfig & AxiosURI} AxiosResourceConfig
 */

export default class AxiosResource {
  constructor () {
    // @ts-ignore
    this.basePath = null
  }

  // @ts-ignore
  get path () {
    // @ts-ignore
    return this.basePath
  }

  // @ts-ignore
  set path (v) {
    this.basePath = URITemplate(v)
  }

  /**
   * @function get
   * @param {object} [params={}]
   * @param {AxiosResourceConfig} [config]
   */
  get (params = {}, config) {
    const url = this.path.expand(params)

    return this.run({
      ...config,
      url,
      method: 'GET'
    })
  }

  /**
   * @function save
   * @param {object} [params={}]
   * @param {any} [data=null]
   * @param {object} [config]
   */
  save (params = {}, data, config) {
    const url = this.path.expand(params)

    return this.run({
      ...config,
      url,
      method: 'POST',
      data
    })
  }

  /**
   * @function update
   * @param {object} [params={}]
   * @param {any} [data=null]
   * @param {object} [config]
   */
  update (params = {}, data, config) {
    const url = this.path.expand(params)

    return this.run({
      ...config,
      url,
      method: 'PUT',
      data
    })
  }

  /**
   * @function delete
   * @param {object} [params={}]
   * @param {object} [config]
   */
  delete (params = {}, config) {
    const url = this.path.expand(params)

    return this.run({
      ...config,
      url,
      method: 'DELETE'
    })
  }

  /**
   * @param {AxiosResourceConfig} config
   */
  run (config) {
    config.uriTemplate = this.path
    return axios(config)
  }

  /**
   * @param {import('axios').Method} method
   * @param {string} path
   * @param {import('urijs/src/URITemplate').URITemplateInput} [params]
   * @param {any} [data]
   * @param {import('axios').AxiosRequestConfig} [config]
   */
  action (method, path, params = {}, data, config) {
    const url = URITemplate(`${this.path.expand(params)}${path}`)
      .expand(params)
      .toString()

    return this.run({
      ...config,
      data,
      method,
      url
    })
  }
}
