<template functional>
  <span
    class="ctk-timeslot-date"
    :class="data.staticClass"
    :style="data.staticStyle"
  >
    <span
      class="ctk-timeslot-date__sign"
      :class="{
        'ctk-timeslot-date__sign--flexible': props.flexible,
        'tw-text-red-500': !props.flexible
      }"
    >
      {{ props.flexible
        ? (props.direction === 'pickup' ? '&#8805;' : '&#8804;')
        : '='
      }}
    </span>
    <slot />
  </span>
</template>

<script>
  /**
   * @module component - CtkTimeslotDate
   * @param {string} direction
   * @param {boolean} flexible
   */
  export default {
    name: 'CtkTimeslotDate'
  }
</script>

<style lang="scss" scoped>

  .ctk-timeslot-date {
    &__sign {
      &--flexible {
        color: $primary;
      }
    }
  }

</style>
