import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import '@stripe/stripe-js'

import './plugins'
import './directives'

import VueWait from 'vue-wait'
import smoothscroll from 'smoothscroll-polyfill'
import 'url-search-params-polyfill'
import '@babel/polyfill'

import { i18n, icu } from '@/locales'
import filters from '@/filters'

import '@/assets/scss/ctk-main.scss'
import 'virtual:windi-components.css'
import 'virtual:windi-utilities.css'

import store from './store'
import router from './router'
import './polyfills'
import './components'

import App from './App'

sync(store, router)

Vue.config.productionTip = false

smoothscroll.polyfill()

filters.create(Vue)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  icu,
  components: { App },
  wait: new VueWait({
    useVuex: true
  }),
  template: '<App/>'
})
