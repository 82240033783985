// @ts-nocheck
export default () => ({
  token: null,
  impersonate: null,
  userInfos: null,
  isImpersonating: false,
  paymentSources: [],
  company: null,
  redirectUrl: null
})
