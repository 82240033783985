<template>
  <div
    :class="{
      'tw-bg-yellow-500': variant === 'warning',
      'tw-bg-red-500': variant === 'danger'
    }"
    class="tw-relative tw-h-32 tw-flex tw-justify-end tw-rounded-tl tw-rounded-tr"
  >
    <ui-ctk-icon
      :name="variant === 'warning' ? 'warning' : 'canceled'"
      class="ctk-alert-banner-dialog-header__icon tw-text-white tw-m-auto"
      data-test="icon"
    />

    <button
      :title="$t('close') | capitalize"
      type="button"
      class="ctk-alert-banner-dialog-header__close tw-absolute tw-top-0 tw-right-0 tw-text-white tw-appearance-none tw-border-none tw-h-12 tw-w-12 tw-p-0"
      data-test="close"
      @click="$emit('close')"
    >
      <ui-ctk-icon
        name="close"
        data-test="close-icon"
      />
    </button>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import UiCtkIcon from '@/components/UI/Icon/CtkIcon/index.vue'

  /**
   * @module component - CtkAlertBannerDialogHeader
   * @param {string} variant
   * @emits close
   */
  export default defineComponent({
    components: {
      UiCtkIcon
    },
    props: {
      variant: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-alert-banner-dialog-header {
    &__close {
      background: transparent;
    }

    &__icon {
      font-size: 6.5rem;
    }
  }

</style>
