<template>
  <router-link
    :to="{ name: isUserShipper ? 'Dashboard' : 'Offers' }"
    class="ctk-navbar-logo tw-h-full tw-flex tw-items-center tw-px-2"
  >
    <picture>
      <source
        srcset="@/assets/img/logo_icon.svg"
        media="(max-width: 1024px) and (min-width: 768px)"
      >
      <img
        src="@/assets/img/logo.svg"
        alt="Logo Chronotruck"
        height="38"
        class="tw-my-auto tw-mx-0"
      >
    </picture>
  </router-link>
</template>

<script>
  import { mapGetters } from 'vuex'

  /**
   * Show the logo within a link to the main view (here offers).
   * @module component - CtkNavbarLogo
   */
  export default {
    name: 'CtkNavbarLogo',
    computed: {
      ...mapGetters([
        'isUserShipper'
      ])
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-navbar-logo {
    transition: background-color 200ms;

    &:focus,
    &:hover {
      outline: none;
      background-color: $secondary-lighten;
    }

    img {
      max-height: 34px;
    }
  }

</style>
