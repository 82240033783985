import { loadLanguageAsync, i18n } from '@/locales'

export default async (to, from, next) => {
  /**
   * A list of required translations for that route
   * @const requiredTranslations
   */
  const { translations } = to.meta
  const requiredTranslations = translations && translations.length > 0 ? translations : []

  /**
   * The current route file, based on the path of the user
   * @const currentRouteFile
   */
  const currentRouteFile = to.path.split('/')[0] || to.path.split('/')[1]

  /**
   * A list of translations to load, including the required ones and the current route
   * @const translationsToLoad
   */
  const translationsToLoad = [
    ...requiredTranslations,
    currentRouteFile
  ]

  let locale = i18n.locale

  /**
   * If the "locale" query parameter is specified in the URL, use
   * it instead of the current locale.
   */
  if (to.query.locale) {
    locale = to.query.locale.substr(0, 2)
    i18n.locale = locale
  }

  await loadLanguageAsync(locale, translationsToLoad)
  next()
}
