import Vue from 'vue'

import ScriptPlugin from './../common/ScriptPlugin'
import router from '@/router'

export class HubspotService extends ScriptPlugin {
  /**
   * @constructor
   * @param {Object} options
   * @param {boolean} options.debug
   */
  constructor ({ debug }) {
    super()

    this.scriptSrc = 'https://js.hsforms.net/forms/v2.js'
    this.debug = debug
    this.hsq = window._hsq = window._hsq || []
  }

  /**
   * @function trackPage
   * @param {string} path
   */
  trackPage (path) {
    if (this.debug) console.log('Hubspot: Track page', path)

    this.hsq.push(['setPath', path])
    this.hsq.push(['trackPageView'])
  }

  /**
   * @function setUserData
   * @param {{
   *   email: string
   *   first_name: string
   *   last_name: string
   *   companies: Array<{name: string}>
   * }} data
   */
  setUserData (data) {
    if (this.debug) console.log('Hubspot: Set user data', data)

    this.hsq.push(['identify', {
      email: data.email,
      firstname: data.first_name,
      lastname: data.last_name,
      company: data.companies[0].name
    }])
  }
}

const DEFAULT_OPTIONS = {
  debug: false
}

export class VueHubspot {
  /**
   * @function install
   * @param {import('vue').VueConstructor} vue
   * @param {{
   *   router: import('vue-router').default,
   *   debug: boolean
   * }} options
   */
  install (vue, { router, debug }) {
    const options = Object.assign(DEFAULT_OPTIONS, {
      debug
    })

    const hubspotService = new HubspotService(options)
    // @ts-ignore
    vue.hubspot = hubspotService
    vue.prototype.$hubspot = hubspotService

    window.hsConversationsSettings = {
      loadImmediately: true
    }

    if (router) {
      router.afterEach(to => {
        vue.nextTick(() => {
          hubspotService.trackPage(to.fullPath)
        })
      })
    }
  }
}

const hubspotInstance = new VueHubspot()
Vue.use(hubspotInstance, {
  router,
  debug: false
})
