
    const jsonData = {
  "drivers.titles.main": "Drivers",
  "drivers.titles.drivers": "{count} {count, plural,\n  =0 {Driver}\n  one {Driver}\n  other {Drivers}\n}\n",
  "drivers.titles.delete_driver": "Remove a driver",
  "drivers.titles.no_driver": "You have no driver",
  "drivers.values.missions": "{count} {count, plural,\n  =0 {mission}\n  one {mission}\n  other {missions}\n}\n",
  "drivers.labels.last_gps_position": "Last registered location:",
  "drivers.buttons.add_driver": "Add a driver",
  "drivers.buttons.delete_driver": "Delete",
  "drivers.buttons.delete_driver_confirmation": "Confirm",
  "drivers.buttons.add_driver_confirmation": "Add",
  "drivers.buttons.add_phone_number": "Add a phone number",
  "drivers.buttons.edit_phone_number": "Edit phone number",
  "drivers.values.last_gps_date": "GPS: {date, date, medium} at {date, time, short}",
  "drivers.values.no_gps_data": "GPS: -",
  "drivers.paragraphs.add_driver": "Please fill out the form below. We will send a text message to your driver with all instructions to connect to the Chronotruck app!",
  "drivers.paragraphs.delete_driver.confirmation": "You're about to remove a driver, be careful, this operation cannot be cancelled.",
  "drivers.paragraphs.delete_driver.warning_deletion": "Once deleted, {name} will no longer be able to log in to the app, and will no longer appear in your drivers list.",
  "drivers.labels.no_gps_data": "No GPS data",
  "drivers.values.metrics.state.planned": "{count, plural,\n  =0 {scheduled}\n  one {scheduled}\n  other {scheduled}\n}\n",
  "drivers.values.metrics.state.in_progress": "{count, plural,\n  =0 {in progress}\n  one {in progress}\n  other {in progress}\n}\n",
  "drivers.values.metrics.state.late_pickup": "{count, plural,\n  =0 {late in the pickup}\n  one {late in the pickup}\n  other {late in the pickup}\n}\n",
  "drivers.values.metrics.state.late_delivery": "{count, plural,\n  =0 {late in the delivery}\n  one {late in the delivery}\n  other {late in the delivery}\n}\n",
  "drivers.values.metrics.state.completed": "{count, plural,\n  =0 {completed}\n  one {completed}\n  other {completed}\n}\n",
  "drivers.values.user_state.no_access": "no access",
  "drivers.values.user_state.validated": "validated",
  "drivers.values.user_state.invited": "invited",
  "drivers.values.user_state.waiting_validation": "awaiting validation",
  "drivers.values.user_state.disabled": "disabled",
  "drivers.values.user_state.deleted": "delete",
  "drivers.paragraphs.edit_driver_phone_number": "You'll change the phone number of {name} (current: {phone})",
  "drivers.paragraphs.add_driver_phone_number": "You'll add a phone number to {name}",
  "drivers.paragraphs.missing_drivers_phone_number": "Please provide a phone number for your driver to give him access to the mobile app.",
  "drivers.paragraphs.all_loaded": "All the drivers are displayed above."
};
    module.exports = jsonData;
  