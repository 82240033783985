import {
  parsePhoneNumberFromString,
  getExampleNumber
} from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import { i18n } from '@/locales'

/** @type {import('vee-validate/dist/types/types').ValidationRuleSchema} */
const validator = {
  /** @type {import('vee-validate/dist/types/types').ValidationMessageGenerator} */
  /** @param {any} args */
  message: (field, args) => {
    /** @type {any} */
    const countryCode = args[0]
    if (!countryCode) throw new Error('A country code must be specified (e.g phone:FR).')

    /**
     * Get the phone number example to pass to the
     * translation key.
     */
    const phoneNumber = countryCode
      ? getExampleNumber(countryCode, examples)
      : null

    const example = phoneNumber
      ? phoneNumber.formatNational()
      : null

    return i18n.t(example
      ? 'validator.telephone_example'
      : 'validator.telephone', {
      field,
      example
    }).toString()
  },
  /** @type {import('vee-validate/dist/types/types').ValidationRuleFunction} */
  /** @param {any} args */
  validate (value, args) {
    /** @type {any} */
    const countryCode = args[0]
    if (!countryCode) throw new Error('A country code must be specified (e.g phone:FR).')

    const parsing = value && countryCode
      ? parsePhoneNumberFromString(value, countryCode)
      : null

    return parsing
      ? parsing.isValid()
      : false
  }
}

export default {
  name: 'telephone',
  validator
}
