// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class OnboardingResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}/onboarding'
  }

  documents (...args) {
    return this.action('POST', '/documents', ...args)
  }
}
