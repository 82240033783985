<template>
  <ValidationObserver
    ref="observer"
    slim
  >
    <form
      @submit.prevent="saveDriver"
      class="add-driver-form tw-flex tw-flex-col"
    >
      <div class="add-driver-form__content tw-flex-1">
        <div class="tw-flex tw-flex-col md:tw-flex-row tw-mb-4">
          <div class="tw-flex-1 md:tw-pr-2 tw-mb-4 md:tw-mb-0">
            <ValidationProvider
              :name="$t('firstname')"
              rules="required|min:1|max:255"
              slim
            >
              <template
                slot-scope="{ invalid, validated, errors }"
              >
                <ctk-input-text
                  id="firstname"
                  v-model="formData.firstName"
                  :hint="invalid ? errors[0] : null"
                  :error="invalid && validated"
                  :label="$t('firstname') | capitalize"
                  name="first_name"
                  type="text"
                  autocomplete="given-name"
                  required
                />
              </template>
            </ValidationProvider>
          </div>
          <div class="tw-flex-1 md:tw-pl-2">
            <ValidationProvider
              :name="$t('name')"
              rules="required|min:1|max:255"
              slim
            >
              <template
                slot-scope="{ invalid, validated, errors }"
              >
                <ctk-input-text
                  id="lastname"
                  v-model="formData.lastName"
                  :hint="invalid ? errors[0] : null"
                  :error="invalid && validated"
                  :label="$t('name') | capitalize"
                  name="last_name"
                  type="text"
                  autocomplete="family-name"
                  required
                />
              </template>
            </ValidationProvider>
          </div>
        </div>
        <div class="tw-flex">
          <div class="tw-flex-1">
            <ValidationProvider
              :name="$t('phone')"
              :rules="`telephone:${country}`"
              slim
            >
              <template
                slot-scope="{ invalid, validated, errors }"
              >
                <ctk-phone-number
                  id="PhoneNumber"
                  v-model="formData.phoneNumber"
                  :country.sync="country"
                  :hint="invalid ? errors[0] : null"
                  :error="invalid && validated"
                  name="phone"
                  autocomplete="tel-national"
                  @update="phoneUpdated"
                />
              </template>
            </ValidationProvider>
          </div>
        </div>
        <div
          v-if="errorMessage"
          style="border: 1px solid;"
          class="error-container tw-rounded tw-text-red-500 py-2 px-3 mt-3"
        >
          {{ errorMessage }}
        </div>
      </div>

      <div
        :class="hasBackButton ? 'tw-justify-between' : 'tw-justify-end'"
        class="add-driver-form__footer tw-flex tw-flex-col-reverse md:tw-flex-row tw-items-center tw-w-full tw-py-3 tw-mt-4"
        data-test="footer"
      >
        <ui-button
          v-if="hasBackButton"
          type="button"
          variant="link"
          class="tw-mt-4 md:tw-mt-0 tw-w-full md:tw-w-auto"
          data-test="back-button"
          @click="$emit(summaryOptionsNeeded.length ? 'back' : 'close')"
        >
          {{ (summaryOptionsNeeded.length ? $t('back') : $t('cancel')) | capitalize }}
        </ui-button>

        <ui-button
          class="tw-w-full md:tw-w-auto"
          variant="primary"
          type="submit"
          data-test="add-driver"
        >
          {{ textButton }}
        </ui-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import { defineComponent, reactive, ref } from '@vue/composition-api'

  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import CtkPhoneNumber from '@/components/CtkPhoneNumber/index.vue'

  export default defineComponent({
    name: 'CtkAddDriverForm',
    components: {
      CtkInputText,
      CtkPhoneNumber
    },
    props: {
      errorMessage: {
        type: String,
        default: null
      },
      textButton: {
        type: String,
        required: true,
        default: String
      },
      hasBackButton: {
        type: Boolean,
        default: true
      },
      summaryOptionsNeeded: {
        type: Array,
        default: () => ([])
      }
    },
    emits: [
      'save-driver',
      'back',
      'close'
    ],
    setup (props, { emit }) {
      /**
       * @type {{
       *  firstName: string|null
       *  lastName: string|null
       *  phoneNumber: string|null
       * }}
       */
      const formData = reactive({
        firstName: null,
        lastName: null,
        phoneNumber: null
      })

      /** @type {import('@vue/composition-api').Ref<string|null>} */
      const phoneFormatted = ref(null)
      const country = ref('FR')
      const observer = ref(null)

      /**
       * @function phoneUpdated
       * @param {{ countryCode: string, formattedNumber: string }} value
       */
      function phoneUpdated (value) {
        country.value = value.countryCode || 'FR'
        phoneFormatted.value = value.formattedNumber
      }

      function saveDriver () {
        // @ts-ignore
        observer.value.validate()
          // @ts-ignore
          .then(valid => {
            if (!valid) return

            const { firstName, lastName } = formData

            if (!firstName || !lastName) return

            const driverData = {
              first_name: firstName.trim(),
              last_name: lastName.trim(),
              ...(phoneFormatted.value && { phone: phoneFormatted.value })
            }

            emit('save-driver', driverData)
          })
      }

      return {
        observer,
        formData,
        phoneFormatted,
        country,
        phoneUpdated,
        saveDriver
      }
    }
  })
</script>
