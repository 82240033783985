<template>
  <div class="offer-summary-card-direction tw-flex tw-flex-col">
    <ctk-address
      :address="address"
      :has-street-name="false"
      class="tw-flex tw-flex-shrink-0 tw-truncate tw-font-medium"
      data-test="address"
    />

    <ctk-timeslot-date
      :flexible="timeSlot.flexible"
      :direction="direction"
      class="tw-text-gray-700 tw-ml-8"
      data-test="date"
    >
      {{ timeSlot.date | moment('LL') }}
    </ctk-timeslot-date>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkAddress from '@/components/CtkAddress/index.vue'
  import CtkTimeslotDate from '@/components/CtkTimeslotDate/index.vue'

  /**
   * @module component - OfferSummaryCardDirection
   * @param {string} direction
   * @param {Object} timeSlot
   * @param {Object} address
   */
  export default defineComponent({
    name: 'OfferSummaryCardDirection',
    components: {
      CtkAddress,
      CtkTimeslotDate
    },
    props: {
      direction: {
        type: String,
        required: true
      },
      timeSlot: {
        type: Object,
        required: true
      },
      address: {
        type: Object,
        required: true
      }
    }
  })
</script>
