// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class PaymentResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}/payments{/pid}'
  }

  metrics (...args) {
    const metrics = new AxiosResource()
    metrics.path = '/v2/companies/{cid}/metrics/payments'
    return metrics.get(...args)
  }

  invoices (...args) {
    return this.action('GET', '/invoices', ...args)
  }
}
