import store from '@/store'

export default (/** @type {import('axios').AxiosRequestConfig} */ config) => {
  const impersonate = store.getters['auth/getImpersonate']

  if (impersonate && config.headers) {
    config.headers['X-Impersonate'] = impersonate.username
  }

  return config
}
