// @ts-nocheck
import moment from 'moment'

import { CompanyMission, MeMission } from '@/resources'

export default {
  getMissions ({ commit, getters, rootGetters }, { status, limit }) {
    commit('SET_MISSIONS', {
      items: []
    })

    const filters = Object.assign({}, getters.getMissionsFilters)

    if (status !== 'in_progress') {
      delete filters.tracking_to_pickup
      delete filters.tracking_to_deliver
      delete filters.tracking_to_upload_pod
    }

    /**
     * Re-map the filters to get a stringified version
     */
    const tracking = []
    const filtersToMap = ['to_pickup', 'to_deliver', 'to_upload_pod']
    filtersToMap.forEach(filter => {
      if (filters[`tracking_${filter}`]) {
        tracking.push(filter)
      }
      delete filters[`tracking_${filter}`]
    })

    const hasFilters = tracking.length > 0
    if (hasFilters) filters.tracking = tracking.join(',')

    return new Promise((resolve, reject) => {
      const queryParams = {
        limit: limit,
        sort: '-pickup_date',
        page: 1,
        state: status,
        ...(status === 'completed' && { sort: '-update_date' }),
        ...(status === 'cancelled' && { sort: '-cancellation_date' }),
        ...filters
      }

      const params = {
        cid: rootGetters['auth/getCid']
      }

      /**
       * Set the type according to the active filter
       * @const type
       */
      const type = filters.your_missions ? 'me' : 'company'

      const requestType = !!filters.your_missions || type === 'me'
        ? MeMission.get(params, {
          params: queryParams
        })
        : CompanyMission.get(params, {
          params: queryParams
        })

      requestType
        .then(response => {
          commit('SET_MISSIONS', {
            items: response.data.items
          })
          commit('SET_MISSIONS_META', {
            meta: response.data.meta
          })

          if (!hasFilters) {
            commit('SET_MISSIONS_METRICS', {
              type,
              status,
              meta: response.data.meta
            })
          }

          resolve(response)
        }, error => {
          console.log('Error getMissions', error)
          reject(error)
        })
    })
  },
  getMoreMissions ({ commit, getters, rootGetters }, { status, limit }) {
    const missionsMeta = getters.getMissionsMeta

    if (missionsMeta.pagination.current_page < missionsMeta.pagination.page_count) {
      return new Promise((resolve, reject) => {
        const filters = Object.assign({}, getters.getMissionsFilters)

        if (status !== 'in_progress') {
          delete filters.tracking_to_pickup
          delete filters.tracking_to_deliver
          delete filters.tracking_to_upload_pod
        }

        /**
         * Re-map the filters to get a stringified version
         */
        const tracking = []
        const filtersToMap = ['to_pickup', 'to_deliver', 'to_upload_pod']
        filtersToMap.forEach(filter => {
          if (filters[`tracking_${filter}`]) {
            tracking.push(filter)
          }
          delete filters[`tracking_${filter}`]
        })

        const hasFilters = tracking.length > 0
        if (hasFilters) filters.tracking = tracking.join(',')

        /**
         * Set the type according to the active filter
         * @const type
         */
        const type = filters.your_missions ? 'me' : 'company'

        const queryParams = {
          limit: limit,
          sort: '-pickup_date',
          page: missionsMeta.pagination.current_page + 1,
          state: status,
          ...(status === 'completed' && { sort: '-update_date' }),
          ...(status === 'cancelled' && { sort: '-cancellation_date' }),
          ...filters
        }

        const params = {
          cid: rootGetters['auth/getCid']
        }

        const request = !!filters.your_missions || type === 'me'
          ? MeMission.get(params, {
            params: queryParams
          })
          : CompanyMission.get(params, {
            params: queryParams
          })

        request.then(response => {
          commit('PUSH_MISSIONS', {
            items: response.data.items
          })
          commit('SET_MISSIONS_META', {
            meta: response.data.meta
          })

          if (!hasFilters) {
            commit('SET_MISSIONS_METRICS', {
              type,
              status,
              meta: response.data.meta
            })
          }
          resolve(response)
        }, error => {
          console.log('Error getOffers', error)
          reject(error)
        })
      })
    }
  },
  getMetrics ({ commit, rootGetters }, { type }) {
    return new Promise((resolve, reject) => {
      const request = type === 'me'
        ? MeMission.metrics()
        : CompanyMission.metrics({ cid: rootGetters['auth/getCid'] })

      return request
        .then(({ data }) => {
          commit('SET_METRICS', {
            type,
            counts: data.counts
          })
          resolve(data.counts)
        }, (error) => {
          reject(error)
        })
    })
  },
  getMission ({ commit, rootGetters }, uuid) {
    return CompanyMission.get({
      cid: rootGetters['auth/getCid'],
      mid: uuid
    })
      .then((response) => {
        commit('SET_MISSION_UUID_TO_SHOW', uuid)
        commit('SET_CURRENT_MISSION', response.data)
      })
  },
  companyMissionActions ({ state, commit, rootGetters, dispatch }) {
    let request, direction
    const { uuid, pickup } = state.currentMission
    const params = { cid: rootGetters['auth/getCid'], mid: uuid }

    if (['pending', 'late'].includes(pickup.state)) {
      request = CompanyMission.pickupComplete(params, {})
      direction = 'pickup'
    } else {
      request = CompanyMission.deliveryComplete(params, {})
      direction = 'delivery'
    }

    return request
      .then(() => {
        commit('UPDATE_MISSION', {
          value: {
            uuid,
            data: {
              [direction]: {
                ...state.currentMission[direction],
                state: 'completed',
                completed_at: moment().format()
              }
            }
          }
        })

        dispatch('getMetrics')
      })
  },
  setCurrentMission ({ commit }, value) {
    commit('SET_CURRENT_MISSION', value)
  },
  setCurrentMissionStatus ({ commit }, value) {
    commit('SET_CURRENT_MISSION_STATUS', value)
  },
  resetCurrentMission ({ commit }) {
    commit('RESET_CURRENT_MISSION')
  },
  setMissionUuidToShow ({ commit }, uuid) {
    commit('SET_MISSION_UUID_TO_SHOW', uuid)
  },
  updateProofOfDelivery ({ commit }, payload) {
    commit('UPDATE_PROOF_OF_DELIVERY', payload)
  },
  getMissionTravel ({ state, rootGetters }) {
    return CompanyMission.travel({
      cid: rootGetters['auth/getCid'],
      mid: state.currentMission.uuid
    }, {})
  },
  getCurrentMissionBilling ({ state, rootGetters, commit }) {
    return CompanyMission.billing({
      cid: rootGetters['auth/getCid'],
      mid: state.currentMission.uuid
    }, {}).then((response) => {
      commit('SET_MISSION_BILLING', response.data)
    })
  },
  pushMissionItinerary ({ commit }, missionItinerary) {
    commit('PUSH_MISSION_ITINERARY', missionItinerary)
  },
  updateCurrentMissionDriver ({ commit }, payload) {
    commit('UPDATE_CURRENT_MISSION_DRIVER', payload)
  },
  toggleMissionsFilter ({ commit }, filter) {
    commit('TOGGLE_MISSIONS_FILTER', {
      filter
    })
  },
  setMissionsFilter ({ commit }, { filter, value }) {
    commit('SET_MISSIONS_FILTER', {
      filter,
      value
    })
  },
  resetMissionsFilters ({ commit }) {
    const filters = ['tracking_to_pickup', 'tracking_to_deliver', 'tracking_to_upload_pod', 'your_missions']
    filters.forEach(filter => {
      commit('SET_MISSIONS_FILTER', {
        filter,
        value: false
      })
    })
  }
}
