// @ts-nocheck
/* eslint-disable */

(function (global, factory) {
  if (typeof define === 'function' && define.amd) {
    define(['exports'], factory)
  } else if (typeof exports !== 'undefined') {
    factory(exports)
  } else {
    var mod = {
      exports: {}
    }
    factory(mod.exports)
    global.actual = mod.exports
  }
})(this, function (exports) {
  'use strict'

  Object.defineProperty(exports, '__esModule', {
    value: true
  })

  const { TEST_ENV, NODE_ENV, VUE_APP_API_BUILD } = process.env

  const preEnv = VUE_APP_API_BUILD || NODE_ENV

  /** @const {boolean} isTesting */
  exports.isTesting = !!TEST_ENV || NODE_ENV === 'testing' || VUE_APP_API_BUILD === 'testing'

  /**
   * TODO: Quick workaround about the issue where we have multiple testing environments.
   * Remove this when we will only have one single test env.
   */
  /** @const {string} env */
  exports.env = preEnv === 'test' ? 'testing' : preEnv
})
