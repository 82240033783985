<template>
  <ctk-item-carrier
    v-if="shipment.price_type !== 'pallet_network' && shipment.mission"
    :user="shipment.mission.driver"
    :name="driverName"
    class="shipment-item-carrier tw-truncate"
  >
    {{ companyName }}
  </ctk-item-carrier>
  <ctk-item-carrier
    v-else-if="shipment.price_type === 'pallet_network' && shipment.mission"
    :user="shipment.mission.driver"
    :name="companyName"
    class="shipment-item-carrier tw-truncate"
  />
</template>

<script>
  import CtkItemCarrier from '@/components/CtkItem/CtkItemCarrier/index.vue'

  /**
   * @module component - shipmentItemCarrier
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentItemCarrier',
    components: {
      CtkItemCarrier
    },
    props: {
      shipment: {
        type: Object,
        required: true
      }
    },
    computed: {
      /**
       * Returns the driver full name
       * @function driverName
       * @returns {string} fullName
       */
      driverName () {
        const { mission } = this.shipment
        return `${mission.driver.first_name} ${mission.driver.last_name}`
      },
      /**
       * Returns the company name
       * @function companyName
       * @returns {string} name
       */
      companyName () {
        const { mission } = this.shipment
        const { carrier } = mission.driver
        return carrier.name
      }
    }
  }
</script>
