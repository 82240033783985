<template>
  <div class="tw-flex tw-flex-col countries-radio">
    <b-form-radio-group
      v-model="localeValue"
      data-test="locales"
      name="locales"
      stacked
      class="focus:tw-outline-none"
    >
      <b-form-radio
        v-for="locale in locales"
        :key="locale.value"
        :value="locale.value"
        class="locale-radio"
      >
        <div class="tw-flex tw-items-center locale-radio__content tw-relative">
          <ui-flag
            :country="locale.country"
            inline
            data-test="flag"
          />
          <span
            v-text="locale.name"
            data-test="name"
          />
        </div>
      </b-form-radio>
    </b-form-radio-group>
  </div>
</template>

<script>
  import { supportedLocales } from '@/locales/constants'

  /**
   * @module component - countriesRadio
   */
  export default {
    name: 'CountriesRadio',
    props: {
      value: {
        type: String,
        default: null
      }
    },
    computed: {
      localeValue: {
        get () {
          // @ts-ignore
          return this.value
        },
        set (v) {
          // @ts-ignore
          this.$emit('input', v)
        }
      },
      /**
       * Returns a mapped list of locales, with the associated country flag.
       * @function locales
       * @returns {Array<{ value: string, name: string, country: string }>}
       */
      locales () {
        return supportedLocales
          .map(({ name, locale }) => ({
            value: locale,
            name,
            country: locale === 'en' ? 'gb' : locale
          }))
      }
    }
  }
</script>
