// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class AddressResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}/addresses'
  }

  distance (...args) {
    return this.action('POST', '/distance-request', ...args)
  }
}
