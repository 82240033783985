<template>
  <ctk-dialog
    :value="dialogValue"
    modal-class="ctk-welcome-dialog"
    hide-header
    hide-footer
    @handle-close="close"
  >
    <div class="ctk-welcome-dialog__wrapper">
      <div class="ctk-welcome-dialog__header tw-flex tw-justify-end">
        <button
          :title="$t('close') | capitalize"
          type="button"
          class="ctk-welcome-dialog__header__close"
          data-test="close"
          @click="close"
        >
          <ui-ctk-icon
            name="close"
            data-test="icon"
          />
        </button>
      </div>
      <div class="ctk-welcome-dialog__content">
        <h1
          data-test="title"
          class="ctk-welcome-dialog__content__title fs-22"
        >
          {{ $t('app.welcome.titles.main', {
            first_name: getUserInfos.first_name
          }) }}
        </h1>
        <p data-test="welcome">
          {{ $t(isUserShipper
            ? 'app.welcome.paragraphs.welcome.shipper'
            : 'app.welcome.paragraphs.welcome.carrier') }}
        </p>
        <p data-test="getting-started">
          {{ $t(isUserShipper
            ? 'app.welcome.paragraphs.getting_started.shipper'
            : 'app.welcome.paragraphs.getting_started.carrier') }}
        </p>
        <div class="tw-flex ctk-welcome-dialog__footer tw-justify-center">
          <ui-button
            :to="{
              name: isUserShipper ? 'NewShipment' : 'Offers'
            }"
            data-test="cta"
            variant="primary"
            type="submit"
            @click.native="close"
          >
            {{ $t(isUserShipper
              ? 'app.welcome.buttons.new_shipment'
              : 'app.welcome.buttons.search_offer') }}
          </ui-button>
        </div>
      </div>
    </div>
  </ctk-dialog>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent, watch } from '@vue/composition-api'

  import store from '@/store'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import CtkDialog from '@/components/CtkDialog/index.vue'

  /**
   * @module component - CtkWelcomeDialog
   */
  export default defineComponent({
    name: 'CtkWelcomeDialog',
    components: {
      CtkDialog
    },
    props: {
      value: {
        type: Boolean,
        required: true
      }
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      watch(dialogValue, (value) => {
        if (!value) {
          store.dispatch('ui/setWelcomeDialogClosed')
        }
      })

      function close () {
        dialogValue.value = false
      }

      return {
        close,
        dialogValue
      }
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      ...mapGetters('auth', [
        'getUserInfos'
      ])
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-welcome-dialog {
    &__header__close {
      appearance: none;
      border: none;
      background: transparent;
      height: 50px;
      width: 50px;
      padding: 0;
      color: $secondary-text;
    }

    &__content {
      padding: 32px 40px;

      &__title {
        padding-bottom: 32px;
        margin-bottom: 0;
      }
    }

    &__footer {
      margin-top: 32px;
    }

    &__wrapper {
      position: relative;
      padding-bottom: 170px;

      &::after {
        position: absolute;
        bottom: 16px;
        width: 100%;
        min-height: 170px;
        content: '';
        background-size: cover;
        background-image: url('~@/assets/img/illustrations/welcome.svg');
        background-position: center bottom;
        opacity: 0.8;
      }
    }
  }

</style>
