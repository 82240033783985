<template>
  <div class="ctk-impersonate-nav-bar tw-flex">
    <div class="ctk-impersonate-nav-bar__content tw-m-auto tw-flex tw-items-center">
      <p
        data-test="user-message"
        class="ctk-impersonate-nav-bar__content__user tw-text-blue-500 tw-font-medium tw-mb-0 tw-mr-4"
        v-text="$t('app.paragraphs.impersonating', {
          full_name: getUserFullName,
          company_name: getUserInfos.companies[0].name
        })"
      />
      <ctk-impersonate-nav-bar-locales
        data-test="locale"
        class="tw-h-full tw-flex tw-my-auto tw-items-center"
      />
    </div>
    <ui-button
      class="ctk-impersonate-nav-bar__close tw-text-blue-500 hover:tw-text-blue-500 tw-my-auto btn-fab tw-mr-2"
      :title="$t('close') | capitalize"
      data-test="close"
      @click="setImpersonateNavbarHidden"
    >
      <ui-ctk-icon
        name="close"
        data-test="icon"
      />
    </ui-button>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkImpersonateNavBarLocales from './_subs/CtkImpersonateNavBarLocales/index.vue'

  /**
   * @module component - CtkImpersonateNavBar
   */
  export default defineComponent({
    name: 'CtkImpersonateNavBar',
    components: {
      CtkImpersonateNavBarLocales
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos',
        'getUserFullName'
      ])
    },
    methods: {
      ...mapActions('ui', [
        'setImpersonateNavbarHidden'
      ])
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-impersonate-nav-bar {
    background-color: $gray;
    height: 50px;

    &__close {
      box-shadow: none;

      &,
      &:hover {
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &__content {
        margin: 0 0 0 auto;
      }

      &__content__user {
        display: none;
      }
    }
  }

</style>
