<script>
  import { defineComponent } from '@vue/composition-api'

  import UiLoader from '@/components/UI/Loader/index.vue'

  const ALLOWED_VARIANTS = ['primary', 'info', 'link', 'light', 'danger', 'white', 'gray']
  const ALLOWED_SIZES = ['sm', 'md', 'lg']

  /**
   * Wrapper component for the bootstrap button. Includes common features that is
   * not available in the bootstrap one.
   * @module component - UIButton
   */
  export default defineComponent({
    name: 'UIButton',
    inheritAttrs: false,
    props: {
      variant: {
        type: String,
        default: 'primary',
        validator: (/** @type {string} */ value) => ALLOWED_VARIANTS.includes(value)
      },
      loading: {
        type: Boolean,
        default: false
      },
      to: {
        type: [Object, String],
        default: null
      },
      size: {
        type: String,
        default: 'md',
        validator: (/** @type {string} */ value) => ALLOWED_SIZES.includes(value)
      },
      outline: {
        type: Boolean,
        default: false
      },
      fab: {
        type: Boolean,
        default: false
      }
    },
    render (createElement) {
      const _this = this

      const componentType = this.to
        ? 'router-link'
        : this.$attrs && this.$attrs.href
          ? 'a'
          : 'button'

      /**
       * @function hasSlot
       * @param {string} slotName
       * @returns {boolean}
       */
      function hasSlot (slotName) {
        // @ts-ignore
        return _this.$slots[slotName] && !!_this.$slots[slotName]
      }

      const hasContent = hasSlot('default')
      const hasLeftIcon = hasSlot('left-icon')
      const hasRightIcon = hasSlot('right-icon')

      const classes = []
      if (this.variant) classes.push(`ui-button--${this.variant}`)
      if (this.size) classes.push(`ui-button--${this.size}`)
      if (this.loading) classes.push('ui-button--loading')
      if (this.outline) classes.push('ui-button--outline')
      if (this.fab) classes.push('ui-button--fab')
      if (hasLeftIcon) classes.push('ui-button--left-icon')
      if (hasRightIcon) classes.push('ui-button--right-icon')
      if (hasContent) classes.push('ui-button--content')

      const componentProps = {}
      if (this.to) componentProps.to = this.to

      const loaderComponent = createElement(UiLoader, {
        props: {
          type: 'spinner',
          size: 18,
          white: true
        },
        staticClass: 'ui-button__loader tw-absolute tw-opacity-0 tw-left-0 tw-top-0 tw-bottom-0 tw-right-0 tw-m-auto'
      })

      const container = createElement('div', {
        staticClass: 'ui-button__container tw-inline-flex tw-justify-center tw-items-center tw-w-full tw-m-auto',
        class: [this.$attrs && this.$attrs['outer-class']]
      }, [
        hasLeftIcon && this.$slots['left-icon'],
        hasContent && this.$slots.default,
        hasRightIcon && this.$slots['right-icon']
      ])

      return createElement(componentType, {
        class: classes,
        staticClass: `ui-button ${this.$attrs.class || ''}`,
        props: {
          ...componentProps
        },
        attrs: this.$attrs,
        on: this.$listeners
      }, [
        container,
        this.loading && loaderComponent
      ])
    }
  })
</script>

<style lang="scss">
.ui-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border-color: transparent;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-weight: 400;
  font-size: 0.875rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  transition-property: background-color, color, border-color;
}
.ui-button:hover {
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
  text-decoration: none;
}
.ui-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.ui-button:focus-visible {
  outline: 2px solid currentColor;
  outline-offset: 2px;
}
.ui-button--primary {
  --tw-bg-opacity: 1;
  background-color: rgba(150, 191, 49, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.ui-button--primary:hover:not(:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgba(118, 150, 39, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.ui-button--primary:focus-visible {
  outline: 2px solid #96BF31;
  outline-offset: 2px;
}
.ui-button--primary:disabled {
  --tw-bg-opacity: 0.65;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-text-opacity: 0.9;
}
.ui-button--info {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(40, 118, 150, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
.ui-button--info:hover:not(:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 118, 150, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.ui-button--info:focus-visible {
  outline: 2px solid #287696;
  outline-offset: 2px;
}
.ui-button--info:disabled {
  --tw-border-opacity: 0.6;
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
  --tw-text-opacity: 0.6;
}
.ui-button--light {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 233, 233, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
.ui-button--light:hover:not(:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 216, 216, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
.ui-button--light:focus-visible {
  outline: 2px solid #287696;
  outline-offset: 2px;
}
.ui-button--light:disabled {
  --tw-bg-opacity: 0.6;
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
  --tw-text-opacity: 0.6;
}
.ui-button--danger {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 40, 63, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.ui-button--danger:hover:not(:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgba(172, 32, 50, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.ui-button--danger:focus-visible {
  outline: 2px solid rgba(239, 68, 68, var(--tw-outline-opacity, 1));
  outline-offset: 2px;
}
.ui-button--danger:disabled {
  --tw-bg-opacity: 0.6;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-text-opacity: 0.6;
}
.ui-button--link {
  background-color: transparent;
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
.ui-button--link:hover:not(:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(29, 86, 110, var(--tw-text-opacity));
}
.ui-button--link:disabled {
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
  --tw-text-opacity: 0.6;
}
.ui-button--white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(33, 34, 46, var(--tw-text-opacity));
}
.ui-button--white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 249, 249, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(33, 34, 46, var(--tw-text-opacity));
}
.ui-button--outline {
  border-style: solid;
  border-width: 1px;
}
.ui-button--outline.ui-button--danger {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(215, 40, 63, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(215, 40, 63, var(--tw-text-opacity));
}
.ui-button--outline.ui-button--danger:hover:not(:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 40, 63, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.ui-button--outline.ui-button--danger:disabled {
  --tw-border-opacity: 0.6;
  --tw-text-opacity: 1;
  color: rgba(215, 40, 63, var(--tw-text-opacity));
  --tw-text-opacity: 0.6;
}
.ui-button--md, .ui-button--sm {
  font-size: 0.875rem;
}
.ui-button--md {
  min-height: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.ui-button--sm {
  min-height: 2rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.ui-button--lg {
  font-size: 1rem;
  min-height: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.ui-button--fab {
  min-height: 0px;
  padding: 0.25rem;
}
.ui-button--fab:hover {
  text-decoration: none;
}
.ui-button:not(.ui-button--fab) {
  min-width: 4rem;
}
.ui-button--left-icon:not(.ui-button--content), .ui-button--right-icon:not(.ui-button--content) {
  min-width: 0px;
}
.ui-button--left-icon:not(.ui-button--content).ui-button--md, .ui-button--left-icon:not(.ui-button--content).ui-button--sm, .ui-button--right-icon:not(.ui-button--content).ui-button--md, .ui-button--right-icon:not(.ui-button--content).ui-button--sm {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.ui-button--left-icon:not(.ui-button--content).ui-button--lg, .ui-button--right-icon:not(.ui-button--content).ui-button--lg {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}
.ui-button--left-icon.ui-button--md .ctk-font::before, .ui-button--left-icon.ui-button--sm .ctk-font::before, .ui-button--left-icon.ui-button--lg .ctk-font::before, .ui-button--right-icon.ui-button--md .ctk-font::before, .ui-button--right-icon.ui-button--sm .ctk-font::before, .ui-button--right-icon.ui-button--lg .ctk-font::before {
  position: relative;
}
.ui-button--left-icon.ui-button--md .ctk-font, .ui-button--left-icon.ui-button--lg .ctk-font, .ui-button--right-icon.ui-button--md .ctk-font, .ui-button--right-icon.ui-button--lg .ctk-font {
  width: 20px;
  height: 20px;
}
.ui-button--left-icon.ui-button--md .material-icons, .ui-button--left-icon.ui-button--lg .material-icons, .ui-button--right-icon.ui-button--md .material-icons, .ui-button--right-icon.ui-button--lg .material-icons {
  font-size: 20px;
}
.ui-button--left-icon.ui-button--md .ctk-font::before, .ui-button--right-icon.ui-button--md .ctk-font::before {
  top: -9px;
  margin-left: -6px;
  font-size: 32px;
}
.ui-button--left-icon.ui-button--lg .ctk-font::before, .ui-button--right-icon.ui-button--lg .ctk-font::before {
  top: -9px;
  margin-left: -8px;
  font-size: 32px;
}
.ui-button--left-icon.ui-button--lg .ui-icon, .ui-button--right-icon.ui-button--lg .ui-icon {
  font-size: 1rem;
  max-height: 1rem;
  max-width: 1rem;
}
.ui-button--left-icon.ui-button--sm .ctk-font, .ui-button--right-icon.ui-button--sm .ctk-font {
  width: 12px;
  height: 12px;
}
.ui-button--left-icon.ui-button--sm .ctk-font::before, .ui-button--right-icon.ui-button--sm .ctk-font::before {
  top: -17px;
  margin-left: -6px;
  font-size: 24px;
}
.ui-button--left-icon.ui-button--sm .material-icons, .ui-button--right-icon.ui-button--sm .material-icons {
  font-size: 12px;
}
.ui-button--left-icon.ui-button--content .ui-icon:first-child {
  margin-right: 0.75rem;
}
.ui-button--right-icon.ui-button--content .ui-icon:last-child {
  margin-left: 0.75rem;
}
.ui-button .ui-loader, .ui-button > span:first-of-type {
  transition: opacity 200ms;
}
.ui-button--loading .ui-button__container {
  opacity: 0;
  visibility: hidden;
}
.ui-button--loading .ui-loader {
  opacity: 1;
}
.ui-button--loading .ui-loader path {
  fill: currentColor !important;
}
.ui-button:disabled {
  cursor: not-allowed;
}
a.ui-button {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
a.ui-button > span {
  margin: auto;
}
</style>
