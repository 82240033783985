// @ts-nocheck
import { Shipment, EstimationRequest, Company } from '@/resources'
import { AUTHORIZED_DEFAULT_PALLET_FORMATS, AUTHORIZED_OVERRIDE_PALLET_FORMATS } from '@/composables/constants'

export default {
  setShipment ({ commit }, shipment) {
    commit('SET_SHIPMENT', {
      shipment
    })
  },
  setShipmentsItems ({ commit }, items) {
    commit('SET_SHIPMENTS_ITEMS', {
      items
    })
  },
  pushShipmentsItems ({ commit }, items) {
    commit('PUSH_SHIPMENTS_ITEMS', {
      items
    })
  },
  setShipmentsMetrics ({ commit }, metrics) {
    commit('SET_SHIPMENTS_METRICS', {
      metrics
    })
  },
  setShipmentsMeta ({ commit }, meta) {
    commit('SET_SHIPMENTS_META', {
      meta
    })
  },
  setShipmentData ({ commit }, {
    uuid,
    data
  }) {
    commit('SET_SHIPMENT_DATA', {
      uuid,
      data
    })
  },
  setShipmentsFilter ({ commit }, { filter, value }) {
    commit('SET_SHIPMENTS_FILTER', {
      filter,
      value
    })
  },
  resetEstimation ({ commit }) {
    commit('SET_ESTIMATION', null)
  },
  resetShipmentsFilters ({ dispatch }) {
    const filters = [
      'tracking_to_pickup',
      'tracking_to_deliver',
      'tracking_to_upload_pod',
      'has_pending_proposals'
    ]
    filters.forEach(filter => {
      dispatch('setShipmentsFilter', {
        filter,
        value: 0
      })
    })
    dispatch('setShipmentsFilter', {
      filter: 'expires_in',
      value: null
    })
  },
  toggleShipmentsFilter ({ commit }, filter) {
    commit('TOGGLE_SHIPMENTS_FILTER', {
      filter
    })
  },
  setExpressQuoteAddress ({ commit }, { direction, address }) {
    commit('SET_EXPRESS_QUOTE_ADDRESS', {
      direction,
      address
    })
  },
  retrieveEstimation ({ rootGetters, commit, dispatch }, { pickup, delivery, goods }) {
    const {
      quantity,
      type,
      height,
      weight,
      width,
      length,
      format
    } = goods

    let loadType = type
    let overridedQuantity = quantity
    let overridedWidth = width
    let overridedLength = length

    function getPalletMpl (length, width, quantity) {
      const mpl = (parseInt(length, 10) === 114 && parseInt(width, 10) === 114) ? 0.6 : (parseInt(length, 10) * parseInt(width, 10)) / 24000
      const roundedMpl = Math.ceil(mpl * parseInt(quantity, 10) * 100) / 100

      return roundedMpl
        ? roundedMpl.toFixed(1)
        : null
    }

    if (loadType === 'pallets') {
      /**
       * Check if the format specified are standard formats or not
       */
      const isStandardFormat = Object.keys(AUTHORIZED_DEFAULT_PALLET_FORMATS).includes(format)
      if (isStandardFormat) {
        const tempFormat = AUTHORIZED_DEFAULT_PALLET_FORMATS[format]
        overridedWidth = tempFormat.width
        overridedLength = tempFormat.length
      } else {
        loadType = 'custom'

        /**
         * Handle special case if there is only one pallet (from the overrided pallets formats e.g 60x40)
         * because the length & width sent to the API is incorrect.
         * If there are more than one pallet of those formats, fallback to the
         * default calculus since it's not handled.
         *
         * See https://trello.com/c/EGKzKv3K/194-traitement-des-multi-pallettes-en-moins-de-1-mpl
         * for more context.
         */
        if (Object.keys(AUTHORIZED_OVERRIDE_PALLET_FORMATS).includes(format)) {
          const parsedDimension = AUTHORIZED_OVERRIDE_PALLET_FORMATS[format]

          overridedLength = getPalletMpl(parsedDimension.length, parsedDimension.width, overridedQuantity) * 100
          overridedWidth = 240

          if (parseInt(overridedQuantity, 10) === 1) {
            overridedWidth = AUTHORIZED_OVERRIDE_PALLET_FORMATS[format].width
            overridedLength = AUTHORIZED_OVERRIDE_PALLET_FORMATS[format].length
          }

          overridedQuantity = 1
        }
      }
    } else {
      overridedWidth = goods.width
      overridedLength = goods.length
      overridedQuantity = 1
    }

    const load = {
      type: loadType,
      quantity: parseInt(overridedQuantity, 10),
      length: parseInt(overridedLength, 10),
      height: parseInt(height, 10),
      width: parseInt(overridedWidth, 10),
      weight: parseInt(weight, 10)
    }

    const { address: pickupAddress } = pickup
    const { address: deliveryAddress } = delivery

    return EstimationRequest.save({
      cid: rootGetters['auth/getCid']
    }, {
      pickup_address: {
        postal_code: pickupAddress.postalCode,
        country: pickupAddress.country,
        location: pickupAddress.location
      },
      delivery_address: {
        postal_code: deliveryAddress.postalCode,
        country: deliveryAddress.country,
        location: deliveryAddress.location
      },
      load
    })
      .then(res => {
        commit('SET_ESTIMATION', res.data)
        return res
      })
      .finally(() => {
        /**
         * TODO: Quick workaround to handle the custom 60x40 formats.
         */
        if (Object.keys(AUTHORIZED_OVERRIDE_PALLET_FORMATS).includes(format)) {
          const parsedDimension = AUTHORIZED_OVERRIDE_PALLET_FORMATS[format]

          commit('SET_EXPRESS_QUOTE_LOAD', {
            ...load,
            quantity,
            type,
            width: parsedDimension.width,
            length: parsedDimension.length,
            format
          })
        } else {
          commit('SET_EXPRESS_QUOTE_LOAD', {
            ...load,
            quantity,
            type,
            width,
            length,
            format
          })
        }

        dispatch('setExpressQuoteAddress', {
          direction: 'pickup',
          address: pickup
        })
        dispatch('setExpressQuoteAddress', {
          direction: 'delivery',
          address: delivery
        })
      })
  },
  retrieveShipmentsMetrics ({ dispatch, rootGetters }) {
    return Shipment.metrics({
      cid: rootGetters['auth/getCid']
    })
      .then(res => {
        dispatch('setShipmentsMetrics', res.data)
      })
      .catch(() => {})
  },
  retrieveShipments ({ dispatch, getters, rootGetters }, { state }) {
    dispatch('setShipmentsItems', [])
    dispatch('setShipmentsMeta', {
      item_count: 0
    })

    const filters = Object.assign({}, getters.getShipmentsFilters)

    if (!filters.expires_in) delete filters.expires_in

    /**
     * Ignore available filters if the state is available
     */
    if (state !== 'available') {
      delete filters.has_pending_proposals
      delete filters.expires_in
    }

    /**
     * Ignore in progress filters if the state is not in progress
     */
    const inProgressStates = ['started', 'transit', 'near_delivery', 'delivered']
    if (inProgressStates.includes(state)) {
      delete filters.tracking_to_pickup
      delete filters.tracking_to_deliver
      delete filters.tracking_to_upload_pod
    }

    /**
     * Re-map the filters to get a stringified version
     */
    const tracking = []
    const filtersToMap = ['to_pickup', 'to_deliver', 'to_upload_pod']
    filtersToMap.forEach(filter => {
      if (filters[`tracking_${filter}`]) {
        tracking.push(filter)
      }
      delete filters[`tracking_${filter}`]
    })

    if (tracking.length > 0) filters.tracking = tracking.join(',')

    return Shipment.get({
      cid: rootGetters['auth/getCid']
    }, {
      params: {
        limit: 30,
        state,
        ...filters
      }
    })
      .then(res => {
        dispatch('setShipmentsItems', res.data.items)
        dispatch('setShipmentsMeta', res.data.meta)
      })
      .catch(() => {})
  },
  retrieveMoreShipments ({ dispatch, getters, rootGetters }, { state }) {
    return new Promise((resolve, reject) => {
      const meta = getters.getShipmentsMeta
      const canLoadMore = meta.pagination.current_page + 1 <= meta.pagination.page_count

      if (canLoadMore) {
        const filters = Object.assign({}, getters.getShipmentsFilters)

        if (!filters.expires_in) delete filters.expires_in

        /**
         * Ignore pending proposal if the state is available
         */
        if (state !== 'available') {
          delete filters.has_pending_proposals
          delete filters.expires_in
        }

        /**
         * Ignore in progress filters if the state is not in progress
         */
        const inProgressStates = ['started', 'transit', 'near_delivery', 'delivered']
        if (inProgressStates.includes(state)) {
          delete filters.tracking_to_pickup
          delete filters.tracking_to_deliver
          delete filters.tracking_to_upload_pod
        }

        /**
         * Re-map the filters to get a stringified version
         */
        const tracking = []
        const filtersToMap = ['to_pickup', 'to_deliver', 'to_upload_pod']
        filtersToMap.forEach(filter => {
          if (filters[`tracking_${filter}`]) {
            tracking.push(filter)
          }
          delete filters[`tracking_${filter}`]
        })

        if (tracking.length > 0) filters.tracking = tracking.join(',')

        Shipment.get({
          cid: rootGetters['auth/getCid']
        }, {
          params: {
            limit: 30,
            state,
            page: meta.pagination.current_page + 1,
            ...filters
          }
        })
          .then(res => {
            dispatch('pushShipmentsItems', res.data.items)
            dispatch('setShipmentsMeta', res.data.meta)
            resolve(res)
          })
          .catch(reject)
      } else {
        resolve()
      }
    })
  },
  retrieveShipment ({ dispatch, rootGetters }, { sid }) {
    return Shipment.get({
      cid: rootGetters['auth/getCid'],
      sid: sid
    })
      .then((res) => {
        dispatch('setShipment', res.data)
        dispatch('pushShipmentsItems', [res.data])

        return res
      })
      .catch(() => {})
  },
  retrieveAllShipmentProposals ({ dispatch, getters, rootGetters }, { uuid }) {
    // eslint-disable-next-line
    let page = 1
    let proposals = []

    const shipmentIndex = getters.getShipmentsItems.findIndex(shipment => shipment.uuid === uuid)
    if (shipmentIndex === -1) return

    return new Promise((resolve, reject) => {
      function retrieveProposals () {
        return Shipment.proposals({
          cid: rootGetters['auth/getCid'],
          sid: uuid
        }, {}, {
          params: {
            limit: 30,
            page
          }
        })
          .then(response => {
            proposals = proposals.concat(response.data.items)

            const pagination = response.data.meta.pagination

            if (pagination.current_page < pagination.page_count) {
              page += 1
              retrieveProposals()
            } else {
              dispatch('setShipmentData', {
                uuid,
                data: {
                  ...getters.getShipmentsItems[shipmentIndex],
                  proposals
                }
              })
              resolve(proposals)
            }
          }, error => reject(error))
      }

      retrieveProposals()
    })
  },
  retrieveShipmentProposals ({ dispatch, getters, rootGetters }, { uuid, options }) {
    return new Promise((resolve, reject) => {
      Shipment.proposals({
        cid: rootGetters['auth/getCid'],
        sid: uuid
      }, {}, {
        params: options
      })
        .then(res => {
          const shipmentIndex = getters.getShipmentsItems.findIndex(shipment => shipment.uuid === uuid)
          if (shipmentIndex === -1) return

          dispatch('setShipmentData', {
            uuid,
            data: {
              ...getters.getShipmentsItems[shipmentIndex],
              proposals: res.data.items
            }
          })
          resolve(res)
        })
        .catch(reject)
    })
  },
  /**
   * @function retrieveDashboardMetrics
   * @returns {Promise}
   */
  retrieveDashboardMetrics ({ commit, rootGetters }, { start, end }) {
    return Company.metrics({
      cid: rootGetters['auth/getCid']
    }, {}, {
      params: {
        start_date: start || null,
        end_date: end || null
      }
    })
      .then(({ data }) => {
        commit('SET_DASHBOARD_METRICS', data)
      })
      .catch(() => {})
  }
}
