import Config from '@/services/Config'

export default {
  /**
   * @function inject
   * @returns {void}
   */
  inject () {
    if (Config.get('hotjar.enabled') === 'false') return

    if (typeof window !== 'undefined') {
      if (window._hotjar_injected) return

      const scriptTag = document.createElement('script')
      scriptTag.setAttribute('type', 'text/javascript')
      scriptTag.setAttribute('defer', 'defer')
      scriptTag.innerHTML = `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${Config.get('hotjar.id')},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `

      document.body.appendChild(scriptTag)
      window._hotjar_injected = true
    }
  },
  /**
   * @function wrap
   * @param {(hj: any) => void} cb
   */
  wrap (cb) {
    // @ts-ignore
    // eslint-disable-next-line
    window.hj = window.hj||function(){(hj.q=hj.q||[]).push(arguments)}
    cb(window.hj)
  },
  /**
   * @function event
   * @param {string} eventName
   */
  event (eventName) {
    this.wrap(hj => {
      hj('event', eventName)
    })
  },
  /**
   * @function tag
   * @param {string} name
   */
  tag (name) {
    this.wrap(hj => {
      hj('tagRecording', [name])
    })
  },
  /**
   * @function identify
   * @param {string} uuid
   */
  identify (uuid) {
    this.wrap(hj => {
      hj('identify', uuid)
    })
  }
}
