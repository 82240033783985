// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class UserInviteResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}/user-invites{/uid}'
  }

  resend (...args) {
    return this.action('POST', '/resend', ...args)
  }
}
