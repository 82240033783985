<template>
  <ctk-dialog
    :value="dialogValue"
    modal-class="ctk-alert-banner-dialog"
    hide-header
    hide-footer
    @handle-close="dialogValue = false"
  >
    <div class="ctk-alert-banner-dialog__wrapper tw-pb-8">
      <ctk-alert-banner-dialog-header
        :variant="variant"
        data-test="header"
        @close="dialogValue = false"
      />

      <div class="ctk-alert-banner-dialog__content tw-px-8 tw-py-16 tw-text-center">
        <h1
          :class="{
            'tw-text-yellow-500': variant === 'warning',
            'tw-text-red-500': variant === 'danger'
          }"
          class="ctk-alert-banner-dialog__content__title tw-font-bold tw-text-2xl tw-mb-8 tw--mt-2 tw--ml-1"
          data-test="title"
          v-text="title"
        />
        <div
          data-test="content"
          class="tw-text-sm"
        >
          <slot />
        </div>
      </div>
    </div>
  </ctk-dialog>
</template>

<script>
  import chronoinfos from '@/../config/chronoinfos'
  import CtkDialog from '@/components/CtkDialog'
  import CtkAlertBannerDialogHeader from './_subs/CtkAlertBannerDialogHeader/index.vue'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @module component - CtkAlertBannerDialog
   * @param {string} title
   * @param {string} variant
   */
  export default {
    name: 'CtkAlertBannerDialog',
    components: {
      CtkDialog,
      CtkAlertBannerDialogHeader
    },
    props: {
      title: {
        type: String,
        required: true
      },
      variant: {
        type: String,
        required: true
      },
      value: {
        type: Boolean,
        required: true
      }
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        dialogValue
      }
    },
    data () {
      return {
        chronoinfos
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-alert-banner-dialog {
    &__content {
      color: $secondary-text;
    }
  }

</style>
