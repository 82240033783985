<template>
  <div>
    <p
      v-text="$t(getCompanyAnimation
        ? animationContent
        : defaultContent)"
      class="tw-font-medium"
    />
    <div
      v-if="getCompanyAnimation"
      v-text="`${getCompanyAnimation.first_name} ${getCompanyAnimation.last_name}`"
      class="tw-font-medium"
      data-test="name"
    />
    <ul class="tw-list-none tw-m-0 tw-p-0">
      <i18n
        tag="li"
        path="app.paragraphs.account_locked.more_informations.phone"
      >
        <template #phone>
          <span
            v-text="getCompanyAnimation && getCompanyAnimation.phone
              ? $options.filters.telephone(getCompanyAnimation.phone)
              : defaultPhone"
            class="tw-whitespace-nowrap"
          />
        </template>
      </i18n>
      <i18n
        tag="li"
        path="app.paragraphs.account_locked.more_informations.email"
      >
        <template #email>
          <span
            v-text="getCompanyAnimation && getCompanyAnimation.email
              ? getCompanyAnimation.email
              : defaultEmail"
            class="tw-whitespace-nowrap"
          />
        </template>
      </i18n>
    </ul>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  /**
   * Component used to show the contact informations in either account locked
   * or unpaid invoices dialog components.
   * It's purpose is to replace the default contact informations values
   * with the current company animation user.
   * @module component - ContactInfosAnimationUser
   * @param {string} defaultContent
   * @param {string} animationContent
   * @param {string} defaultPhone
   * @param {string} defaultEmail
   */
  export default {
    name: 'ContactInfosAnimationUser',
    props: {
      defaultContent: {
        type: String,
        required: true
      },
      animationContent: {
        type: String,
        required: true
      },
      defaultPhone: {
        type: String,
        required: true
      },
      defaultEmail: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCompanyAnimation'
      ])
    }
  }
</script>
