var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ctk-dialog',{attrs:{"value":_vm.dialogValue,"modal-class":"ctk-email-validation-dialog","hide-header":"","hide-footer":"","persistent":""},on:{"handle-close":_vm.close}},[_c('div',{staticClass:"ctk-email-validation-dialog__wrapper"},[_c('div',{staticClass:"ctk-email-validation-dialog__header tw-h-12 tw-flex tw-justify-end"},[_c('ctk-dialog-close-button',{nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}})],1),_c('div',{staticClass:"ctk-email-validation-dialog__content tw-px-4 tw-pb-6 md:tw-pb-8 md:tw-px-10 tw-flex tw-flex-col",class:{
        'tw-pt-6 md:tw-pt-10': _vm.confirmation || _vm.emailConfirmation,
        'tw-pt-0': !(_vm.confirmation || _vm.emailConfirmation)
      }},[(_vm.confirmation || _vm.emailConfirmation)?[_c('img',{staticClass:"tw-mx-auto tw-mb-8",attrs:{"src":require("@/assets/img/icons/email.svg"),"width":"60","height":"63","alt":""}}),_c('h1',{staticClass:"ctk-email-validation-dialog__content__title tw-font-normal tw-mb-5 tw-text-2xl tw-text-center",domProps:{"textContent":_vm._s(_vm.$t('app.titles.email_validation.resent'))}}),_c('p',{staticClass:"tw-text-center",domProps:{"textContent":_vm._s(_vm.$t('app.paragraphs.email_validation.resent'))}})]:[_c('h1',{staticClass:"ctk-email-validation-dialog__content__title-form tw-font-normal tw-mb-10 tw-text-2xl",domProps:{"textContent":_vm._s(_vm.$t('app.titles.email_validation.update'))}}),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('form',{staticClass:"tw-flex tw-flex-col",attrs:{"disabled":_vm.$wait.is('updating email')},on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('ValidationProvider',{ref:"email-provider",attrs:{"name":_vm.$t('app.fields.email'),"rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var invalid = ref.invalid;
      var validated = ref.validated;
      var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-6",attrs:{"id":"email","label":_vm.$t('app.labels.email'),"disabled":_vm.$wait.is('updating email'),"error":invalid && validated,"hint":errors[0],"type":"email","name":"email","autocomplete":"email","required":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}])}),_c('ui-button',{staticClass:"tw-mb-10 tw-mx-auto",attrs:{"loading":_vm.$wait.is('updating email'),"disabled":_vm.$wait.is('updating email'),"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.validate_email'))+" ")])],1)])]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }