<template>
  <div
    ref="tabsContent"
    :style="[{height: height}]"
    style="z-index: 1"
    class="tabs-content tw-flex tw-items-start"
  >
    <slot />
  </div>
</template>

<script>
  import { defineComponent, onBeforeUnmount, onMounted, toRefs, ref } from '@vue/composition-api'
  import ResizeObserver from 'resize-observer-polyfill'

  export default defineComponent({
    name: 'TabsContent',
    props: {
      currentTab: {
        type: Number,
        required: true
      }
    },
    setup (props) {
      const { currentTab } = toRefs(props)

      /** @type {import('@vue/composition-api').Ref<ResizeObserver|null>} */
      const observer = ref(null)
      /** @type {import('@vue/composition-api').Ref<HTMLDivElement|null>} */
      const content = ref(null)
      const height = ref('0px')

      // @ts-ignore
      observer.value = new ResizeObserver(entries => {
        // @ts-ignore
        entries.forEach(() => {
          if (!content.value) return

          // @ts-ignore
          /** @type {HTMLDivElement|null} */
          const element = content.value.querySelector(`.tabs-content-item:nth-child(${currentTab.value + 1})`)
          if (element) {
            height.value = `${element.offsetHeight}px`
          }
        })
      })

      onMounted(() => {
        if (content.value && observer.value) {
          observer.value.observe(content.value)
        }
      })

      onBeforeUnmount(() => {
        if (observer.value) {
          observer.value.disconnect()
        }
      })

      return {
        height,
        content
      }
    }
  })
</script>

<style lang="scss" scoped>

  .tabs-content {
    position: relative;
    transition: all 0.5s;
    will-change: height;
  }

</style>
