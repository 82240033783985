// @ts-nocheck
import jstz from 'jstimezonedetect'

import { Registration } from '@/resources'
import Config from '@/services/Config'
import { i18n } from '@/locales'

export default {
  setCompany ({ commit }, company) {
    commit('SET_COMPANY', company)
  },
  setAccount ({ commit }, infos) {
    commit('SET_ACCOUNT', infos)
  },
  retrieveQuestions ({ commit }, isShipper = false) {
    const query = isShipper ? Registration.shipper() : Registration.carrier()
    return query
      .then(response => {
        commit('SET_QUESTIONS', response.data)
      })
  },
  setAnswer ({ commit }, { questionKey, answers }) {
    commit('SET_ANSWER', { questionKey, answers })
  },
  requestRegister ({ getters }, isShipper = false) {
    const { version } = getters.getQuestions
    const company = getters.getCompany
    const account = getters.getAccount
    const answers = Object.keys(getters.getAnswers)
      .map(key => ({
        key,
        answers: getters.getAnswers[key]
      }))

    /**
     * Due to browser limitations, we cannot guess at 100% the user's timezone.
     * The IANA time zone name may not be available in older browsers.
     * We fallback to Europe/Paris if that's the case.
     */
    let timezone
    try {
      const tz = jstz.determine()
      timezone = tz.name()
    } catch (e) {
      console.error('Could not determine user timezone', e)
    }

    const data = {
      version,
      questions: answers,
      name: company.name,
      vat_number: company.vatNumber,
      phone: account.phone,
      address: {
        street: company.address.streetName,
        city: company.address.city,
        postal_code: company.address.postalCode,
        country: company.address.country
      },
      origin: Config.get('api.clientId'),
      user: {
        first_name: account.firstName,
        last_name: account.lastName,
        email: account.email,
        password: account.password,
        terms_of_service: !!account.tos,
        professionals: !!account.pro,
        locale: i18n.locale,
        timezone: timezone || 'Europe/Paris'
      }
    }

    const query = isShipper ? Registration.saveShipper({}, data) : Registration.saveCarrier({}, data)
    return query
  },
  resetForm ({ commit }) {
    commit('RESET_FORM')
  }
}
