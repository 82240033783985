<template functional>
  <i
    :class="[
      data.class,
      data.staticClass
    ]"
    class="ui-icon tw-select-none"
    aria-hidden="true"
  >
    <slot />
  </i>
</template>

<script>
  /**
   * @module component - Icon
   */
  export default {
    name: 'Icon'
  }
</script>
