// @ts-nocheck
export default {
  isAppReady: state => state.appReady,
  getNotificationsPoolingInterval: state => state.notificationsPoolingInterval,
  getUserRoles (state, getters, rootState, rootGetters) {
    const userInfos = rootGetters['auth/getUserInfos']
    if (!userInfos) return []
    return userInfos.companies[0].roles
  },
  isUserMono (state, getters) {
    return getters.getUserRoles.includes('MONO_DRIVER')
  },
  isUserDispatcher (state, getters) {
    return getters.getUserRoles.includes('DISPATCHER')
  },
  isUserDispatcherDriver (state, getters) {
    return getters.getUserRoles.includes('DISPATCHER_DRIVER')
  },
  isUserShipper (state, getters) {
    return getters.getUserRoles.includes('COMPANY')
  }
}
