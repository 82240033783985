import { datadogRum } from '@datadog/browser-rum'

import Config from '@/services/Config'
import { isTesting } from '@/../utils/env'
// @ts-ignore
import { version } from '@/../config/chronoinfos'

const { NODE_ENV } = process.env

if (NODE_ENV === 'production' && !isTesting) {
  const appId = Config.get('datadog.applicationId')
  const token = Config.get('datadog.token')
  const environment = Config.get('datadog.environment')

  datadogRum.init({
    applicationId: appId,
    clientToken: token,
    site: 'datadoghq.eu',
    service: 'Client App',
    env: environment,
    version: version,
    sampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [/^http(s*):\/\/app(\..+|\.)chronotruck\.com$/]
  })
}
