// @ts-nocheck
import Vue from 'vue'
import SearchModel from './models/SearchModel'

export default {
  SET_CURRENT_SEARCH (state, search) {
    state.currentSearch = search
  },
  SET_SAME_ZONES (state, v) {
    state.sameZonesManual = v
  },
  RESET_CURRENT_SEARCH (state) {
    state.currentSearch = new SearchModel()
  },
  SET_CURRENT_SEARCH_ISO_CODES (state, { direction, isoCodes }) {
    Vue.set(state.currentSearch, direction + '_iso_codes', isoCodes)
  },
  ADD_ISO_CODES_IN_CURRENT_SEARCH (state, { direction, isoCode }) {
    state.currentSearch[direction + '_iso_codes'].push(isoCode)
  },
  SET_CURRENT_SEARCH_ATTRIBUT (state, { key, val }) {
    Vue.set(state.currentSearch, key, val)
  },
  SET_SEARCH_ATTRIBUT (state, { search, key, val }) {
    const objIndex = state.searches.findIndex((obj) => obj.uuid === search.uuid)
    Vue.set(state.searches[objIndex], key, val)
  },
  SET_SEARCHES (state, val) {
    state.searches = val
  },
  SET_COUNT_FOR_A_SEARCHES (state, { index, count, unviewed }) {
    Vue.set(state.searches[index], 'count', count)
    Vue.set(state.searches[index], 'unviewed', unviewed)
  },
  DELETE_SEARCH_IN_SEARCHES_WITH_ID (state, sid) {
    state.searches = state.searches.filter((element) => { return element.uuid !== sid })
  },
  UPDATE_A_SEARCH (state, search) {
    const index = state.searches.findIndex((obj) => obj.uuid === search.uuid)
    Vue.set(state.searches, index, search)
  },
  ADD_SEARCH_IN_SEARCHES (state, search) {
    state.searches.push(search)
  },
  SET_PROPOSALS (state, proposals) {
    state.proposals = proposals
  },
  RESET_PROPOSALS (state) {
    state.proposals = []
  },
  UPDATE_PROPOSAL_IN_PROPOSALS_AND_IN_CURRENT_OFFER (state, { proposal }) {
    const proposalIndex = state.proposals.findIndex(p => p.uuid === proposal.uuid)
    if (proposalIndex !== -1) {
      Vue.set(state.proposals, proposalIndex, Object.assign({}, state.proposals[proposalIndex], proposal))

      /**
       * TODO: Unsure about the usefull-ness of this part. The whole mutation should be
       * rethinked.
       */
      const offer = state.proposals[proposalIndex].offer
      if (!offer) return

      const offerIndex = state.offers.findIndex(o => o.uuid === offer.uuid)
      if (offerIndex !== -1) {
        Vue.set(state.offers[offerIndex], 'proposal', proposal)
      } else {
        throw new Error('Cannot update offer proposal since offer not in the list.')
      }
    }
  },
  PUSH_OFFERS (state, values) {
    values.forEach((value) => {
      const foundIndex = state.offers.findIndex(e => e.uuid === value.uuid)
      if (foundIndex !== -1) {
        /**
         * The offer already exists in the store, update it
         */
        Vue.set(state.offers, foundIndex, value)
      } else {
        /**
         * Push it as a new value otherwise
         */
        state.offers.push(value)
      }
    })
  },
  SET_OFFERS (state, val) {
    state.offers = val

    /**
     * When the offers list is fully re-updated, we want to check if the currentOffer belongs
     * to this new list.
     * If it does not belong, we reset it to avoid issues.
     */
    const offerIndex = state.offers.findIndex(offer => offer.uuid === state.currentOffer)
    if (offerIndex === -1 && state.currentOffer !== null) {
      state.currentOffer = null
    }
  },
  DELETE_OFFER_IN_OFFERS_WITH_ID (state, sid) {
    state.offers = state.offers.filter(offer => offer.uuid !== sid)
  },
  ADD_KEY_VALUE_TO_AN_OFFER (state, { offerUuid, key, value }) {
    const offerIndex = state.offers.findIndex(offer => offer.uuid === offerUuid)

    if (offerIndex !== -1) {
      /**
       * Forces the reactivity on the offers array
       * when we update a specific offer.
       */
      Vue.set(state.offers[offerIndex], key, value)
    }
  },
  SET_CURRENT_OFFERS_META (state, meta) {
    state.currentOffersMeta = meta
  },
  SET_OFFER_PAGINATION (state, number) {
    state.offerPagination = number
  },
  /**
   * TODO: This update mutation is very similar to the SET_CURRENT_OFFER mutation.
   * Perhaps we should use one of those.
   */
  UPDATE_CURRENT_OFFER (state, offer) {
    state.currentOffer = offer.uuid

    const offerIndex = state.offers.findIndex(o => o.uuid === offer.uuid)
    if (offerIndex !== -1) {
      Vue.set(state.offers, offerIndex, Object.assign({}, state.offers[offerIndex], offer))
    }
  },
  SET_CURRENT_OFFER (state, offer) {
    if (!offer) {
      state.currentOffer = null

      return
    }

    const foundIndex = state.offers.findIndex((_offer) => offer.uuid === _offer.uuid)
    if (foundIndex !== -1) {
      Vue.set(state.offers, foundIndex, offer)
    } else {
      state.offers.push(offer)
    }

    state.currentOffer = offer.uuid
  },
  RESET_CURRENT_OFFER (state) {
    state.currentOffer = null
  },
  UPDATE_PROPOSAL_TO_CURRENT_OFFER (state, { proposal, offer: offerUuid }) {
    const offerIndex = state.offers.findIndex(offer => offer.uuid === offerUuid)
    if (offerIndex !== -1) {
      Vue.set(state.offers[offerIndex], 'proposal', proposal)
    }
  },
  SET_CURRENT_PROPOSAL (state, proposal) {
    state.currentProposal = proposal
  },
  DELETE_PROPOSAL_IN_PROPOSALS_WITH_ID (state, pid) {
    state.proposals = state.proposals.filter(proposal => proposal.uuid !== pid)
  },
  RESET_CURRENT_PROPOSAL (state) {
    state.currentProposal = null
  },
  SET_OFFERS_SORT_TYPE (state, payload) {
    state.offersSortType = payload.value
  },
  SET_KEYBOARD_STATUS (state, payload) {
    state.keyboardStatus = payload.value
  },
  PUSH_ITINERARY (state, { uuid, itinerary }) {
    const found = state.itineraries.findIndex(e => e.uuid === uuid)
    if (found === -1) {
      state.itineraries.push({
        uuid,
        itinerary
      })
    }
  },
  SET_OFFERS_SUGGESTIONS (state, { offers }) {
    state.offersSuggestions.items = offers
  },
  SET_OFFERS_SUGGESTION_VISIBILITY (state, { visible }) {
    state.offersSuggestions.visible = visible
  },
  SET_SELECTED_DIMENSION (state, { value }) {
    state.selectedDimension = value
  },
  SET_PROPOSAL_KEY (state, { proposal, key, value }) {
    const proposalIndex = state.proposals.findIndex(p => p.uuid === proposal.uuid)
    if (proposalIndex !== -1) {
      Vue.set(state.proposals[proposalIndex], key, value)
    } else {
      const offerIndex = state.offers.findIndex(p => p.uuid === state.currentOffer)
      if (offerIndex !== -1) {
        Vue.set(state.offers[offerIndex].proposal, key, value)
      } else {
        throw new Error('Cannot update the proposal since it\'s not on the proposals list nor current offer')
      }
    }
  },
  PUSH_PROPOSALS (state, { proposals }) {
    proposals.forEach((proposal) => {
      /**
       * Update any proposal with the same uuid,
       * Push it to the proposals array otherwise
       */
      const index = state.proposals.findIndex(innerProposal => innerProposal.uuid === proposal.uuid)
      if (index !== -1) {
        Vue.set(state.proposals, index, proposal)
      } else {
        state.proposals.push(proposal)
      }
    })
  },
  SET_OFFERS_METRICS (state, value) {
    state.offersMetrics = {
      ...state.offersMetrics,
      ...value
    }
  },
  SET_PROPOSALS_METRICS (state, value) {
    state.proposalsMetrics = {
      ...state.proposalsMetrics,
      ...value
    }
  },
  SET_PROPOSALS_META (state, { meta }) {
    state.proposalsMeta = meta
  },
  SET_PROPOSALS_FILTER (state, { filter }) {
    state.proposalState = filter
  },
  SET_OFFER_PREVIOUS_ROUTE (state, from) {
    state.previousRoute = from
  }
}
