<template>
  <button
    :class="{
      'offer-card--viewed': offer.is_viewed,
      'has-chips': hasChips,
      'enable-hover': !disableHover,
      'bg-f2': proposition,
      'offer-card--active': !proposition && getCurrentOffer && getCurrentOffer.uuid === offer.uuid
    }"
    class="offer-card tw-relative tw-bg-white focus:tw-outline-none focus-visible:tw-outline-solid-blue tw-border-none tw-p-0 tw-text-sm tw-w-full tw-text-gray-900 hover:tw-text-gray-900 tw-transition-colors tw-transition-shadow"
  >
    <slot name="chips" />
    <div class="offer-card__content tw-relative tw-flex tw-px-5 tw-pt-6 tw-pb-2 tw-truncate">
      <div class="tw-flex-1 tw-truncate">
        <div
          v-for="(direction, k) in ['pickup', 'delivery']"
          :key="k"
          :class="{
            'tw-pb-3': direction === 'pickup'
          }"
          class="offer-card__content__line tw-truncate"
        >
          <ctk-address
            :address="offer[direction].address"
            :has-street-name="false"
            class="tw-flex tw-flex-1 tw-truncate tw--mb-1"
          />

          <ctk-timeslot-date
            :flexible="offer[direction].time_slot.flexible"
            :direction="direction"
            class="tw-text-gray-700 tw-ml-8"
          >
            {{ offer[direction].time_slot.date | moment('LL') }}
          </ctk-timeslot-date>
        </div>
      </div>

      <price-chip
        class="tw-text-base tw-mb-auto tw-flex-shrink-0"
      >
        {{ (offer.pricing.carrier_price || offer.pricing.price) | currency(offer.pricing.currency, $i18n.locale, true) }}
      </price-chip>
    </div>

    <div class="offer-card-proposal-container">
      <proposal-triangle
        v-if="haveProposalTriangle && offer.proposal"
        :proposal="offer.proposal"
        :currency="offer.pricing.currency"
        small
      />
      <offer-item-proposals
        v-if="offer.proposal || offer.proposal_count > 0"
        :class="{
          active: !proposition && getCurrentOffer && getCurrentOffer.uuid === offer.uuid
        }"
        :offer="offer"
        :has-best-proposals="offer.proposal_count > 0"
        class="tw-ml-6"
        card
      />
    </div>

    <div class="offer-card__footer tw-flex tw-items-center tw-px-4 tw-mb-6">
      <!-- Linear meter -->
      <div
        v-if="offer.load.type !== 'pallets'"
        class="offer-card__footer__section tw-flex tw-items-center tw-justify-center tw-mr-8"
        data-test="lm"
      >
        <ui-ctk-icon
          class="tw-text-blue-600 tw--ml-1"
          name="size"
          data-test="icon"
        />
        <div
          v-text="$t('lm', {
            lm: $n(offer.load.linear_meters)
          })"
          class="offer-card__footer__section__value tw-text-secondary"
          data-test="value"
        />
      </div>

      <!-- Pallets -->
      <div
        v-if="offer.load.type === 'pallets'"
        class="offer-card__footer__section tw-flex tw-items-center tw-justify-center tw-mr-8"
        data-test="pallets"
      >
        <ui-ctk-icon
          class="tw-text-blue-600 tw--ml-1"
          name="pallets"
          data-test="icon"
        />
        <div
          v-text="`${$t('pallet_unit', {
            count: $n(offer.load.quantity)
          })} ${offer.load.format}`"
          class="offer-card__footer__section__value tw-text-secondary"
          data-test="value"
        />
      </div>

      <!-- Weight -->
      <div
        class="offer-card__footer__section tw-flex tw-items-center tw-justify-center tw-mr-8"
        data-test="weight"
      >
        <ui-ctk-icon
          class="tw-text-blue-600 tw--ml-2"
          name="weight"
          data-test="icon"
        />
        <div
          v-text="$t('weight_unit', {
            weight: $n(offer.load.weight)
          })"
          class="offer-card__footer__section__value tw-text-secondary"
          data-test="value"
        />
      </div>

      <!-- Options -->
      <div
        v-if="isTailLiftRequired || isHandledByDriver"
        class="tw-flex tw-items-center tw-justify-center tw-text-blue-600 tw--ml-2"
      >
        <ui-ctk-icon
          v-if="isTailLiftRequired"
          v-b-tooltip.hover
          :title="tailLiftTooltip"
          name="hatch"
          data-test="tail-lift"
        />

        <ui-ctk-icon
          v-if="isHandledByDriver"
          v-b-tooltip.hover
          :title="driverHandlingTooltip"
          name="logistic"
          data-test="driver-handling"
        />

        <ui-ctk-icon
          v-if="hasRdv"
          v-b-tooltip.hover
          :title="rdvTooltip"
          name="scheduled"
          data-test="rdv"
        />

        <ui-ctk-icon
          v-if="hasAccessibility"
          v-b-tooltip.hover
          :title="accessibilityTooltip"
          name="warning-full"
          class="tw-text-yellow-500"
          data-test="accessibility"
        />
      </div>
    </div>
  </button>
</template>

<script >
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  import CtkAddress from '@/components/CtkAddress/index.vue'
  import CtkTimeslotDate from '@/components/CtkTimeslotDate/index.vue'
  import ProposalTriangle from '@/views/Carriers/Offers/components/OffersList/_subs/ProposalTriangle/index.vue'
  import OfferItemProposals from '@/views/Carriers/Offers/components/OffersList/_subs/OfferItemProposals/index.vue'
  import PriceChip from '@/components/CtkPricing/_subs/PriceTotal/_subs/PriceChip/index.vue'

  import useI18n from '@/composables/useI18n'
  import useStore from '@/composables/useStore'

  export default defineComponent({
    name: 'CtkOfferCard',
    components: {
      PriceChip,
      CtkAddress,
      CtkTimeslotDate,
      OfferItemProposals,
      ProposalTriangle
    },
    props: {
      offer: {
        type: Object,
        required: true
      },
      disableHover: {
        type: Boolean,
        default: false
      },
      proposition: {
        type: Boolean,
        default: false
      },
      haveProposalTriangle: {
        type: Boolean,
        default: false
      },
      hasChips: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { offer } = toRefs(props)
      const i18n = useI18n()
      const store = useStore()

      const getCurrentOffer = computed(() => store.value.getters['offers/getCurrentOffer'])

      /**
       * @function getPriceLine
       * @param {string} lineKey
       * @returns {{
       *   key: string
       * } | undefined}
       */
      function getPriceLine (lineKey) {
        if (!offer.value.pricing || !offer.value.pricing.price_lines) return

        // @ts-ignore
        return offer.value.pricing?.price_lines?.find(line => line.key === lineKey)
      }

      /** @type {import('@vue/composition-api').ComputedRef<boolean>} */
      const isHandledByDriver = computed(() => offer.value.pickup.handling.driver || offer.value.delivery.handling.driver)
      /** @type {import('@vue/composition-api').ComputedRef<boolean>} */
      const isTailLiftRequired = computed(() => offer.value.pickup.handling.tail_lift || offer.value.delivery.handling.tail_lift)

      const tailLiftTooltip = computed(() => {
        const { pickup, delivery } = offer.value

        if (pickup.handling.tail_lift && delivery.handling.tail_lift) {
          return i18n.value.t('need_hatch_at_pickup_and_delivery')
        }

        if (pickup.handling.tail_lift) {
          return i18n.value.t('need_hatch_at_pickup')
        }

        return i18n.value.t('need_hatch_at_delivery')
      })

      const driverHandlingTooltip = computed(() => {
        const { pickup, delivery } = offer.value

        if (pickup.handling.driver && delivery.handling.driver) {
          return i18n.value.t('handling_by_the_driver_at_pickup_and_delivery')
        }

        if (pickup.handling.driver) {
          return i18n.value.t('handling_by_the_driver_at_pickup')
        }

        return i18n.value.t('handling_by_the_driver_at_delivery')
      })

      const pickupRdv = computed(() => offer.value.pickup.meeting)
      const deliveryRdv = computed(() => offer.value.delivery.meeting)
      const hasRdv = computed(() => pickupRdv.value || deliveryRdv.value)

      const rdvTooltip = computed(() => {
        if (pickupRdv.value && deliveryRdv.value) {
          return i18n.value.t('app.labels.rdv_option.pickup_delivery')
        }

        if (pickupRdv.value) {
          return i18n.value.t('app.labels.rdv_option.pickup')
        }

        return i18n.value.t('app.labels.rdv_option.delivery')
      })

      const hasPickupAccessibility = computed(() => {
        if (getPriceLine('pickup_address_accessibility')) return true
        const { accessibility } = offer.value.pickup.address
        if (accessibility) {
          return accessibility.vehicle_type !== 'standard_trailer'
        }

        return false
      })

      const hasDeliveryAccessibility = computed(() => {
        if (getPriceLine('delivery_address_accessibility')) return true
        const { accessibility } = offer.value.delivery.address
        if (accessibility) {
          return accessibility.vehicle_type !== 'standard_trailer'
        }

        return false
      })

      const hasAccessibility = computed(() => hasPickupAccessibility.value || hasDeliveryAccessibility.value)

      const accessibilityTooltip = computed(() => {
        if (hasPickupAccessibility.value && hasDeliveryAccessibility.value) {
          return i18n.value.t('app.labels.accessibility.pickup_delivery')
        }

        if (hasPickupAccessibility.value) {
          return i18n.value.t('app.labels.accessibility.pickup')
        }

        return i18n.value.t('app.labels.accessibility.delivery')
      })

      return {
        getCurrentOffer,
        isHandledByDriver,
        driverHandlingTooltip,
        isTailLiftRequired,
        tailLiftTooltip,
        rdvTooltip,
        hasAccessibility,
        accessibilityTooltip,
        hasRdv
      }
    }
  })
</script>

<style lang="scss">
.offer-card--active {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}
.offer-card--viewed .price-chip {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 164, 164, var(--tw-bg-opacity));
}
.offer-card--viewed .offer-card__footer .ctk-font, .offer-card--viewed .offer-card__footer__section__value, .offer-card--viewed .offer-item-proposals__best {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
}
.offer-card--viewed .offer-card__content .ctk-address__content {
  font-weight: 400;
}
.offer-card:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.offer-card:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.offer-card:not(.offer-card--active):hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 249, 249, var(--tw-bg-opacity));
}
.offer-card:hover {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0/0.1),0 2px 4px -2px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  z-index: 10;
}
.offer-card:not(:last-child)::after {
  --tw-bg-opacity: 1;
  background-color: rgba(164, 164, 164, var(--tw-bg-opacity));
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  content: '';
  height: 1px;
}
.offer-card__content__line {
  position: relative;
  text-align: left;
}
.offer-card__content__line .ctk-address__content {
  font-weight: 500;
}
.offer-card__content__line:first-child::after {
  position: absolute;
  content: '';
  background-image: url('~@/assets/img/division-line.svg');
  height: 1.6rem;
  width: 1px;
  left: 9px;
  top: 23px;
}
.offer-card__footer__section {
  position: relative;
}
.offer-card__footer__section:not(:last-child)::after {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 216, 216, var(--tw-bg-opacity));
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 0px;
  bottom: 0px;
  content: '';
  width: 1px;
  height: 20px;
  right: -1rem;
}
.offer-card.has-chips {
  margin-top: 1rem;
}
.offer-card.has-chips .offer-card__content {
  padding-top: 0.75rem;
}
.offer-card.has-chips .offer-card__footer {
  margin-bottom: 0.5rem;
}
.offer-card .chips {
  margin-bottom: 10px;
}
.offer-card .chips .chip {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 118, 150, var(--tw-bg-opacity));
  padding: 0.25rem;
  position: absolute;
  left: 0px;
  top: -0.75rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
</style>
