import { computed, getCurrentInstance } from '@vue/composition-api'

/**
 * Composable used when needing to by-pass the non 2-way binding of the props
 * by using an intermediate value to emit an update event to the parent
 * component.
 * @function useModelGetterSetter
 */
export default function useModelGetterSetter (props, name = 'value') {
  const vm = getCurrentInstance()

  const state = computed({
    get () { return props[name] },
    set (v) {
      if (name === 'value') {
        vm.emit('input', v)
      } else {
        vm.emit(`update:${name}`, v)
      }
    }
  })

  return {
    state
  }
}
