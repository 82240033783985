// @ts-nocheck
import Vue from 'vue'

export default {
  SET_NOTIFICATION_AS_READ (state, notification) {
    const index = state.notifications.items.findIndex(item => item.uuid === notification.uuid)
    if (index !== -1) {
      Vue.set(state.notifications.items[index], 'read', true)
    }
  },
  SET_NOTIFICATIONS_ITEMS (state, items) {
    state.notifications.items = items
  },
  SET_NOTIFICATIONS_META (state, meta) {
    state.notifications.meta = meta
  }
}
