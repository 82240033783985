<template>
  <div class="tw-rounded info-container">
    <img
      src="@/assets/img/icons/info.png"
      alt=""
      class="info-container-icon"
      width="50"
    >
    <slot />
  </div>
</template>

<script>
  /**
   * @module component - CtkInfoContainer
   */
  export default {
    name: 'CtkInfoContainer'
  }
</script>

<style lang="scss" scoped>

  .info-container {
    position: relative;
    background-color: white;
    border: 1px solid #DEDEDE;
    color: $primary-text;
    padding: 16px 20px 0 30px;
    margin-left: 25px;
    margin-top: 16px;

    &,
    & p {
      text-align: left;
    }

    &-icon {
      position: absolute;
      left: -25px;
      top: -25px;
      user-select: none;
    }
  }

</style>
