import * as Rules from 'vee-validate/dist/rules'
import { i18n } from '@/locales'

/**
 * Override the default VeeValidate "mime" rule to change the error message
 * & use a more usefull mime format.
 */
/** @type {import('vee-validate/dist/types/types').ValidationRuleSchema} */
const validator = {
  ...Rules.mimes,
  /** @type {import('vee-validate/dist/types/types').ValidationMessageGenerator} */
  /** @param {any} args */
  message (field, args) {
    const formats = Object.keys(args)
      .filter((/** @type {any} */ arg) => {
        if (typeof args[arg] !== 'string') return false

        const splittedValue = args[arg].split('/')
        return splittedValue[0] && splittedValue[1]
      })
      .map((/** @type {any} */ arg) => {
        return args[arg].split('/')[1]
      })

    return i18n.t('validator.mimes', {
      formats: formats.join(', ')
    }).toString()
  }
}

export default {
  name: 'mimes',
  validator
}
