import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration, CaptureConsole } from '@sentry/integrations'

import { isTesting } from '@/../utils/env'
import Config from '@/services/Config'

// @ts-ignore
import { version } from '@/../config/chronoinfos'

const { NODE_ENV } = process.env

if (NODE_ENV === 'production' && !isTesting) {
  const dsn = Config.get('sentry.dsn')
  const environment = Config.get('sentry.environment')

  const ignoreErrors = [
    'tooltip unable to find target element in document',
    '[vue-matomo] An error occurred trying to load',
    'Failed to load Stripe.js',
    'The operation is insecure.'
  ]

  Sentry.init({
    dsn,
    environment,
    ignoreErrors,
    release: `app@${version}`,
    integrations: [
      new CaptureConsole({
        levels: ['error']
      }),
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true
      })
    ]
  })
}
