<template>
  <ctk-item-cancellation-date
    :label="$t(label)"
    class="shipment-item-cancellation-dates"
  >
    {{ $t(isExpired ? 'shipments.values.expires_at' : 'shipments.values.cancelled_at', {
      date: $moment(isExpired ? shipment.expires_at : shipment.cancellation.date).format('LLL')
    }) }}
  </ctk-item-cancellation-date>
</template>

<script>
  import CtkItemCancellationDate from '@/components/CtkItem/CtkItemCancellationDate'

  /**
   * @module component - shipmentItemCancellationDates
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentItemCancellationDates',
    components: {
      CtkItemCancellationDate
    },
    props: {
      shipment: {
        type: Object,
        required: true
      }
    },
    computed: {
      /**
       * Returns true if the shipment is expired
       * @function isExpired
       * @returns {boolean}
       */
      isExpired () {
        return this.shipment.state === 'expired'
      },
      /**
       * Returns the appropriate label key according to the state & the cancellation
       * @function label
       * @returns {string|null}
       */
      label () {
        if (this.isExpired) {
          return 'shipments.labels.expired'
        }

        if (!this.shipment.cancellation) return null

        switch (this.shipment.cancellation.type) {
        case 'cancelled_by_shipper':
          return 'shipments.labels.cancelled_by_shipper'
        case 'cancelled_by_chronotruck':
          return 'shipments.labels.cancelled_by_chronotruck'
        case 'payment_expired':
          return 'shipments.labels.payment_expired'
        default:
          return null
        }
      }
    }
  }
</script>
