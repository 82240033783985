// @ts-nocheck

import { Company } from '@/resources'

export default {
  retrieveDocuments ({ commit, rootGetters }) {
    return Company.onboarding({
      cid: rootGetters['auth/getCid']
    })
      .then(res => {
        commit('SET_DOCUMENTS', res.data.documents)

        return res
      })
  }
}
