export default () => ({
  invoices: {
    items: [],
    meta: {
      item_count: 0
    },
    metrics: {},
    currentPage: 1
  },
  payments: {
    items: [],
    meta: {
      item_count: 0
    },
    metrics: {},
    currentPage: 1
  }
})
