<template>
  <div
    :class="{'no-shadow': noShadow, 'tw-max-h-full': !isCurrent}"
    class="tabs-content-item"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'TabsContentItem',
    props: {
      noShadow: {
        type: Boolean,
        default: false
      },
      isCurrent: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .tabs-content-item {
    position: relative;
    overflow-y: hidden;
    width: 100%;
    flex: 1 0 100%;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);

    &.no-shadow {
      box-shadow: none;
    }
  }

</style>
