<template functional>
  <div
    :class="{
      'tw-bg-red-500': props.variant === 'danger',
      'tw-bg-yellow-500': props.variant === 'warning',
      'tw-bg-blue-500': props.variant === 'info'
    }"
    class="ctk-alert-banner-icon tw-w-full tw-flex tw-text-white"
  >
    <ui-ctk-icon
      :name="props.variant === 'danger'
        ? 'canceled'
        : props.variant === 'warning'
          ? 'warning'
          : 'info'"
      class="tw-text-6xl tw-m-auto"
      data-test="icon"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import UiCtkIcon from '@/components/UI/Icon/CtkIcon/index.vue'

  /**
   * @module component - CtkAlertBannerIcon
   * @param {string} variant
   */
  export default defineComponent({
    components: {
      UiCtkIcon
    },
    props: {
      variant: {
        type: String,
        default: 'danger'
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-alert-banner-icon {
    max-width: 75px;
    min-height: 75px;
  }

</style>
