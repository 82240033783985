<template>
  <svg
    :class="{
      'ui-loader-spinner--white': white,
      'ui-loader-spinner--info': !white
    }"
    :width="`${size}px`"
    :height="`${size}px`"
    xmlns="http://www.w3.org/2000/svg"
    class="ui-loader-spinner"
    viewBox="0 0 50 50"
  >
    <defs />
    <path d="M44 25.1a18.7 18.7 0 10-37.4 0h4a14.6 14.6 0 0129.3 0h4z" />
  </svg>
</template>

<script>
  /**
   * @module component - UiLoaderSpinner
   * @param {number} [size=40]
   * @param {boolean} [white=false]
   */
  export default {
    name: 'UiLoaderSpinner',
    props: {
      size: {
        type: [Number, String],
        default: 40
      },
      white: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ui-loader-spinner {
    animation: spin 0.6s linear infinite;

    &--info path {
      fill: $info;
    }

    &--white path {
      fill: white;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }

</style>
