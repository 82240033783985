import Vue from 'vue'
import VueGTM from 'vue-gtm'

import router from '@/router'
import store from '@/store'
import { i18n } from '@/locales'
import Config from '@/services/Config'
import { isTesting } from '@/../utils/env'

/**
 * Do not allow the Vue GTM plugin in "testing" environments.
 */
if (!isTesting) {
  Vue.use(VueGTM, {
    id: Config.get('gtm.id'),
    enabled: false, // Disabled since we need the GET /me informations in order to enable the plugin
    debug: true,
    loadScript: false
  })

  router.afterEach(to => {
    const userInfos = store.getters['auth/getUserInfos']

    const name = to.name
    // @ts-ignore
    const dataLayer = (window.dataLayer = window.dataLayer || [])
    dataLayer.push({
      event: 'virtualPageEvents',
      virtualPagePath: to.fullPath,
      language: i18n.locale,
      userId: userInfos
        ? userInfos.uuid
        : null,
      environment: 'client app',
      pageTemplate: name
    })
  })
}
