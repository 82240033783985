<template>
  <ctk-dialog
    v-if="visible"
    modal-class="ctk-locale-dialog"
    hide-header
    hide-footer
    max-width="875px"
  >
    <div class="ctk-locale-dialog__image" />
    <div class="ctk-locale-dialog__content p-4">
      <div class="content__title">
        {{ $t('app.locale.titles.main') }}
      </div>
      <p class="mb-4">
        {{ $t('app.locale.paragraphs.instructions') }}
      </p>
      <form
        @submit.prevent="submitted"
      >
        <countries-radio
          v-model="locale"
        />

        <ui-button
          class="ctk-locale-dialog__content__save tw-mt-6 tw-mr-4"
          variant="primary"
          type="submit"
        >
          {{ $t('app.locale.buttons.validate') }}
        </ui-button>
      </form>
    </div>
  </ctk-dialog>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapActions } from 'vuex'

  import { loadLanguageAsync } from '@/locales'
  import Storage from '@/services/Storage'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CountriesRadio from './_subs/CountriesRadio/index.vue'

  /**
   * @module component - ctkLocaleDialog
   */
  export default defineComponent({
    name: 'CtkLocaleDialog',
    components: {
      CtkDialog,
      CountriesRadio
    },
    data () {
      return {
        visible: true,
        locale: 'en'
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos'
      ])
    },
    methods: {
      ...mapActions('auth', [
        'setUserInfos'
      ]),
      submitted () {
        if (!this.locale) return

        /**
         * Update the locale locally then close the dialog
         */
        Storage && Storage.setItem('userLocale', this.locale)
        loadLanguageAsync(this.locale)
          .finally(() => {
            this.setUserInfos({
              ...this.getUserInfos,
              locale: this.locale
            })
            this.visible = false
          })
      }
    }
  })
</script>

<style lang="scss">

  .ctk-locale-dialog {
    .modal-wrapper {
      padding: 0 16px;
    }

    .modal-container {
      overflow: hidden;
    }

    .modal-body {
      display: flex;
      width: 800px;
      min-height: 500px;

      @media only screen and (max-width: $breakpoint-laptop-s) {
        width: 600px;
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        flex-direction: column;
        width: 100%;
      }
    }

    &__image,
    &__content {
      width: 50%;

      @media only screen and (max-width: $breakpoint-tablet) {
        width: 100%;
      }
    }

    &__content {
      margin: auto 0;

      .content__title {
        font-size: 22px;
        margin-bottom: 30px;
      }
    }

    &__image {
      background-image: url('~@/assets/img/backgrounds/locale.jpg');
      background-size: cover;
      background-position: center;

      @media only screen and (max-width: $breakpoint-tablet) {
        min-height: 150px;
      }
    }
  }

</style>
