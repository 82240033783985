import fromEntries from 'fromentries'

export const KEYCODES = {
  BACKSPACE: 8,
  TAB: 9,
  RETURN: 13,
  ESC: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  DELETE: 46
}

/**
 * List of possible pallet formats across the app.
 * Note that the "override" means that this pallet format requires
 * a special calculus handling (since less than 1 lm when 1 pallet is specified.)
 *
 * See https://trello.com/c/EGKzKv3K/194-traitement-des-multi-pallettes-en-moins-de-1-mpl
 * for more context.
 * @type {{ [key: string]: {
 *   width: number,
 *   length: number,
 *   override?: boolean
 * } }} AUTHORIZED_PALLET_FORMATS
 */
export const AUTHORIZED_PALLET_FORMATS = {
  '120x80': {
    width: 120,
    length: 80
  },
  '120x100': {
    width: 120,
    length: 100
  },
  '60x80': {
    width: 60,
    length: 80
  },
  '60x40': {
    width: 60,
    length: 40,
    override: true
  },
  '120x120': {
    width: 120,
    length: 120
  },
  '114x114': {
    width: 114,
    length: 114
  },
  '75x125': {
    width: 75,
    length: 125,
    override: true
  }
}

export const AUTHORIZED_OVERRIDE_PALLET_FORMATS = fromEntries(
  Object.keys(AUTHORIZED_PALLET_FORMATS)
    .filter(key => AUTHORIZED_PALLET_FORMATS[key].override)
    .map(key => [key, AUTHORIZED_PALLET_FORMATS[key]])
)

export const AUTHORIZED_DEFAULT_PALLET_FORMATS = fromEntries(
  Object.keys(AUTHORIZED_PALLET_FORMATS)
    .filter(key => !AUTHORIZED_PALLET_FORMATS[key].override)
    .map(key => [key, AUTHORIZED_PALLET_FORMATS[key]])
)
