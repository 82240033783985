import Vue from 'vue'

import {
  NavbarPlugin,
  ButtonPlugin,
  ButtonGroupPlugin,
  FormCheckboxPlugin,
  ModalPlugin,
  FormRadioPlugin,
  LayoutPlugin,
  ProgressPlugin,
  TooltipPlugin,
  PopoverPlugin,
  DropdownPlugin,
  CollapsePlugin
} from 'bootstrap-vue'

Vue.use(ButtonPlugin)
Vue.use(NavbarPlugin)
Vue.use(ModalPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormRadioPlugin)
Vue.use(LayoutPlugin)
Vue.use(ProgressPlugin)
Vue.use(PopoverPlugin)
Vue.use(TooltipPlugin)
Vue.use(DropdownPlugin)
Vue.use(CollapsePlugin)
