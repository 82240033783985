export default () => ({
  company: null,
  questions: null,
  answers: {},
  account: {
    firstName: null,
    lastName: null,
    phone: null
  }
})
