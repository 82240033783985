/**
 * TODO: Replace this with moment duration or something else for localization purpose.
 * @function duration
 * @param {string} duration
 * @returns {string}
 */
export default (duration) => {
  if (typeof duration === 'undefined') throw new Error('The `duration` attribute is required.')

  const value = parseInt(duration, 10)
  /** @type {number} */
  const hours = Math.floor(value / 3600)
  /** @type {number} */
  const minutes = Math.floor((value - (hours * 3600)) / 60)

  return `${
    hours < 10
      ? `0${hours}`
      : hours
  }:${
    minutes < 10
      ? `0${minutes}`
      : minutes
  }`
}
