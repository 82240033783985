<template>
  <div class="ctk-item-header tw-flex tw-justify-between tw-text-secondary-text">
    <slot />
  </div>
</template>

<script>
  /**
   * @module component - CtkItemHeader
   */
  export default {
    name: 'CtkItemHeader'
  }
</script>

<style lang="scss" scoped>

  .ctk-item-header {
    background-color: #E9E9EA;
    padding: 0 30px;
    min-height: 30px;
    border-radius: 4px 4px 0 0;
  }

</style>
