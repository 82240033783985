import filesize from 'filesize'

import { i18n } from '@/locales'

/**
 * @function useSize
 * @param {number} value
 * @returns {string}
 */
export default function useSize (value) {
  /**
   * List of size symbols for french users.
   * Otherwise fallback to the SI format.
   * TODO: Was a quickfix, find a better alternative to scale.
   * @const FR_SYMBOLS
   */
  const FR_SYMBOLS = {
    B: 'B',
    KB: 'Ko',
    MB: 'Mo',
    GB: 'Go',
    TB: 'To',
    PB: 'Po',
    EB: 'Eo',
    ZB: 'Zo',
    YB: 'Yo'
  }

  return filesize(value, {
    locale: i18n.locale,
    symbols: i18n.locale === 'fr' ? FR_SYMBOLS : null,
    exponent: 2 // Force to MB, see https://www.npmjs.com/package/filesize for reference
  })
}
