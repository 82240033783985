<template>
  <ctk-dialog
    id="confirmBookingModal"
    v-model="modalState"
    modal-class="dialog-booking-confirmation"
    max-width="875px"
    hide-footer
    hide-header
  >
    <ctk-dialog-close-button
      class="tw-absolute tw-top-0 tw-right-0"
      data-test="close"
      @click.native="modalState = false"
    />

    <div class="tw-flex tw-flex-col tw-mx-auto tw-text-center tw-px-4 md:tw-px-10 tw-pb-8 md:tw-pb-10 tw-pt-8 md:tw-pt-16">
      <img
        src="@/assets/img/icons/confirmation.svg"
        width="80"
        height="80"
        alt=""
        class="tw-mb-8 tw-mx-auto"
        data-test="icon"
      >

      <h1
        v-text="$t('confirm_booking.description')"
        class="dialog-booking-confirmation__title fs-22 tw-font-medium tw-mb-4 md:tw-whitespace-nowrap"
        data-test="title"
      />

      <p
        v-text="$t('confirm_booking.missions')"
        class="tw-text-secondary-lighten md:tw-w-2/3 tw-mx-auto"
        data-test="content"
      />

      <ui-button
        v-if="!getOffersSuggestions.length"
        :to="{
          name: 'Mission',
          params: {
            uuid: offerUuid
          }
        }"
        class="tw-text-white tw-rounded-full tw-px-4 tw-mx-auto tw-mt-6"
        variant="primary"
        data-test="view-mission"
        @click.native="modalState = false"
      >
        {{ $t('show_offer_detail') | capitalize }}
      </ui-button>
    </div>

    <img
      src="@/assets/img/illustrations/shipment_confirmation.svg"
      alt=""
      class="dialog-booking-confirmation__illustration tw-w-full tw-pointer-events-none tw-select-none tw--mt-20 tw-hidden md:tw-block md:tw-p-8 tw-h-64 tw-object-cover tw-object-bottom"
    >

    <transition name="fade">
      <div
        v-if="modalState && getOffersSuggestions.length > 0"
        class="tw-w-full tw-bg-gray-300 tw-rounded-bl tw-rounded-br"
        data-test="suggestions"
      >
        <offers-suggestion-container
          in-modal
          @select="selectOffer"
        />
      </div>
    </transition>
  </ctk-dialog>
</template>

<script>
  import { computed, defineComponent } from '@vue/composition-api'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'
  import OffersSuggestionContainer from '@/views/Carriers/Offers/components/OffersList/_subs/OffersSuggestionContainer/index.vue'
  import CtkInfoContainer from '@/components/CtkInfoContainer/index.vue'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import useStore from '@/composables/useStore'

  /**
   * @module component - DialogBookingConfirmation
   * @param {string} offerUuid
   */
  export default defineComponent({
    name: 'DialogBookingConfirmation',
    components: {
      CtkDialog,
      CtkDialogCloseButton,
      OffersSuggestionContainer,
      CtkInfoContainer
    },
    props: {
      // TODO: Should be "mission" uuid, not offer. Rename.
      offerUuid: {
        type: String,
        required: true
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    model: {
      prop: 'value',
      event: 'input'
    },
    setup (props) {
      const { state: modalState } = useModelGetterSetter(props, 'value')
      const store = useStore()

      const getOffersSuggestions = computed(() => store.value.getters['offers/getOffersSuggestions'])

      function selectOffer () {
        store.value.dispatch('offers/setOffersSuggestionsVisibility', true)
        modalState.value = false
      }

      return {
        getOffersSuggestions,
        modalState,
        selectOffer
      }
    }
  })
</script>
