<template>
  <ul
    v-if="getUserInfos"
    class="ctk-nav-bar-navigation"
  >
    <li
      v-for="button in buttons"
      :key="button.name"
    >
      <router-link
        :to="button.to"
        :class="[
          `ctk-nav-bar-navigation-${button.name}`,
          button.classes
        ]"
        :exact="button.isExact || false"
        class="ctk-nav-bar-navigation__link tw-py-0 tw-px-5 tw-h-full tw-flex tw-items-center tw-truncate hover:tw-no-underline"
        @click.native="$emit('close')"
      >
        {{ button.title | capitalize }}
      </router-link>
    </li>
    <li
      v-if="isUserShipper && !isAccountLocked"
      class="ctk-nav-bar-navigation__button tw-flex tw-items-center"
    >
      <ui-button
        :to="{
          name: 'NewShipment'
        }"
        class="tw-rounded-full tw-px-4 ctk-nav-bar-navigation-new-shipment tw-flex tw-items-center tw-text-white tw-truncate tw-my-auto tw-ml-8 tw-mr-0"
        variant="primary"
        @click.native="newShipment"
      >
        {{ $t('navigation.new-shipment') | capitalize }}
      </ui-button>
    </li>
  </ul>
</template>

<script>
  import { mapGetters } from 'vuex'

  /**
   * The main navigation menu, in the app header.
   * @module CtkNavBarNavigation
   */
  export default {
    name: 'CtkNavBarNavigation',
    computed: {
      ...mapGetters([
        'isUserMono',
        'isUserShipper'
      ]),
      ...mapGetters('auth', [
        'getUserInfos',
        'isAccountLocked',
        'isPreRegistered'
      ]),
      /**
       * Returns true if the offer's view is active, either is the map or searches
       * @function isOffersView
       * @returns {boolean}
       */
      isOffersView () {
        return ['Offers', 'Searches', 'Proposals', 'ProposalStates'].includes(this.$route.name)
      },
      /**
       * Returns true if the shipments's view is active
       * @function isShipmentsView
       * @returns {boolean}
       */
      isShipmentsView () {
        return ['Shipments', 'Shipment'].includes(this.$route.name)
      },
      /**
       * Returns true if we are in the billing view
       * @function isBillingView
       * @returns {boolean}
       */
      isBillingView () {
        return ['Invoices', 'Payments', 'Billing'].includes(this.$route.name)
      },
      /**
       * Returns true if we are in the mission view
       * @function isMissionView
       * @returns {boolean}
       */
      isMissionView () {
        return ['MissionsRouter', 'Missions', 'Mission'].includes(this.$route.name)
      },
      /**
       * Returns a list of the possible buttons
       * @function buttons
       */
      buttons () {
        if (this.isPreRegistered) {
          return []
        }

        return [
          ...this.isUserShipper ? [
            {
              name: 'dashboard',
              title: this.$t('navigation.dashboard'),
              to: { name: 'Dashboard' }
            },
            {
              name: 'shipments',
              title: this.$t('navigation.shipments'),
              to: { name: 'Shipments' },
              classes: {
                active: this.isShipmentsView,
                'router-link-active': this.isShipmentsView
              },
              isExact: true
            }
          ] : [],
          ...!this.isUserShipper ? [
            ...!this.isAccountLocked ? [
              {
                name: 'offers',
                title: this.$t('navigation.available_offers'),
                to: { name: 'Offers' },
                classes: {
                  active: this.isOffersView,
                  'router-link-active': this.isOffersView
                }
              }
            ] : [],
            {
              name: 'missions',
              title: this.$t('navigation.missions'),
              to: { name: 'MissionsRouter' },
              classes: {
                active: this.isMissionView,
                'router-link-active': this.isMissionView
              }
            },
            (!this.isUserMono && !this.isAccountLocked) && {
              name: 'drivers',
              title: this.$t('navigation.drivers'),
              to: { name: 'Drivers' }
            }
          ] : [],
          {
            name: 'billing',
            title: this.$t('navigation.billing'),
            to: { name: 'Billing' },
            classes: {
              active: this.isBillingView,
              'router-link-active': this.isBillingView
            }
          },
          !this.isUserShipper && {
            name: 'statistics',
            title: this.$t('navigation.statistics'),
            to: { name: 'Statistics' }
          }
        ].filter(v => !!v)
      }
    },
    methods: {
      /**
       * Called whenever the user clicks on the new shipment button
       * @function newShipment
       */
      newShipment () {
        this.$emit('close')
        if (this.$matomo) {
          this.$matomo.trackEvent('Quotations', 'New')
        }
      }
    }
  }
</script>

<style lang="scss">

  .ctk-nav-bar-navigation {
    &,
    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
      height: 100%;
    }

    &-new-shipment {
      @media only screen and (max-width: 1280px) {
        margin-left: 0;
      }
    }

    &__link {
      &.router-link-active,
      &.active {
        border-bottom: 3px solid #FFF;
        margin-bottom: -3px;
      }
    }

    &--mobile,
    &--desktop {
      .ctk-nav-bar-navigation__link {
        height: 55px;
        border-radius: 0;
      }
    }

    &--mobile {
      &__link {
        width: 100%;

        &.router-link-active,
        &.active {
          border-left: 3px solid #FFF;
          border-bottom: 0;
        }
      }
    }

    /* stylelint-disable */
    &--mobile {
      padding: 0 10px;

      .ctk-nav-bar-navigation__link {
        margin: 0;
        color: rgba(255, 255, 255, 0.5);
        list-style: none;
        border-bottom: 0 solid white;
        transition: background-color 200ms, border-bottom 200ms;
        height: 30px;
        padding: 0 8px 0 8px;
        margin-bottom: 4px;

        &:hover:not(.router-link-active),
        &:hover:not(.active),
        &:focus {
          outline: none;
          background-color: rgba(#303144, 0.8);
          color: rgba(255, 255, 255, 0.8);
        }

        &.router-link-active,
        &.active {
          background-color: #303144 !important;
          color: white !important;
        }
      }

      .ctk-nav-bar-navigation__button {
        margin-top: 20px;

        .ctk-nav-bar-navigation-new-shipment {
          width: 100%;
          margin: 0;
        }
      }
    }
    /* stylelint-enable */

    &--desktop {
      .ctk-nav-bar-navigation__link.active,
      .ctk-nav-bar-navigation__link.router-link-active {
        background-color: #303144 !important;
        border-bottom: 3px solid #FFF;
        color: white !important;
      }

      /* stylelint-disable */
      .ctk-nav-bar-navigation__link {
        margin: 0;
        color: rgba(255, 255, 255, 0.5);
        list-style: none;
        border-bottom: 0 solid white;
        transition: background-color 200ms, border-bottom 200ms;

        &:hover:not(.router-link-active),
        &:hover:not(.active),
        &:focus {
          outline: none;
          background-color: rgba(#303144, 0.8);
          color: rgba(255, 255, 255, 0.8);
        }

        &.router-link-active,
        &.active {
          background-color: #303144 !important;
          border-bottom: 3px solid #FFF;
          color: white !important;
        }
      }
      /* stylelint-enable */

      .ctk-nav-bar-navigation-new-shipment {
        height: 55px;
        border-radius: 0;
      }

      .dropdown-item {
        outline: 0;
      }
    }
  }

</style>
