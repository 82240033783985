import store from '@/store'
import { i18n } from '@/locales'
import Storage from '@/services/Storage'

export default (/** @type {import('axios').AxiosRequestConfig} */ config) => {
  const impersonate = store.getters['auth/getImpersonate']

  /**
   * Accept-Language:
   * Retrieve the localStorage locale if any; fallback to the store value
   * if not defined.
   */
  let locale = 'en'

  /**
   * Priorize the admin impersonate informations if available;
   * fallback to the user informations otherwise.
   */
  if (i18n.locale) {
    locale = i18n.locale
  } else {
    const infos = impersonate || store.getters['auth/getUserInfos']
    const storageLocale = Storage && Storage.getItem('userLocale')

    if (storageLocale) locale = storageLocale.slice(0, 2)
    if (infos && infos.locale) locale = infos.locale
  }

  if (locale && config.headers) {
    config.headers['Accept-Language'] = locale
  }

  return config
}
