<template functional>
  <hr
    class="ctk-item-tracking-divider"
    :class="{
      active: props.completed
    }"
  >
</template>

<script>
  /**
   * @module component - CtkItemTrackingDivider
   * @param {boolean} completed
   */
  export default {
    name: 'CtkItemTrackingDivider'
  }
</script>

<style lang="scss" scoped>

  .ctk-item-tracking-divider {
    background-color: $light-gray;
    height: 2px;
    border: 0;
    margin: 0;
    flex: 1;

    &.active {
      background-color: $info;
    }
  }

</style>
