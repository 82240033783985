/**
 * Check if the target matches a route.
 * If not, leads to a redirection.
 * Added because we store the route name in the user's localstorage.
 * Because of that, a route name may not match with our routes in the future.
 */
export default (to, from, next) => {
  if (to.matched.length === 0) {
    return next('/')
  }
  next()
}
