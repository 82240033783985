// @ts-nocheck

/**
 * @function getProgressFromSection
 * @returns {{
 *  questions: Array<any>
 *  answers: Array<any>
 * }}
 */
function getProgressFromSection (getters, section) {
  const questions = getters.getCategoriesQuestions.get(section)
  if (!questions) {
    return {
      questions: 0,
      answers: 0
    }
  }

  const answers = Object.keys(getters.getAnswers).filter(questionKey => {
    return questions
      .map(question => question.key)
      .includes(questionKey)
  })

  return {
    questions: questions ? questions.length : 0,
    answers: answers ? answers.length : 0
  }
}

/**
 * @function getSectionQuestionsCount
 * @returns {number}
 */
function getSectionQuestionsCount (getters, section) {
  const questions = getters.getCategoriesQuestions.get(section)
  if (!questions) {
    return 0
  }

  return questions.length
}

export default {
  getCompany: state => state.company,
  getAnswers: state => state.answers,
  getQuestions: state => state.questions,
  getAccount: state => state.account,
  getCategoriesQuestions: (state, getters) => {
    const map = new Map()
    if (!getters.getQuestions) return map

    getters.getQuestions.questions.forEach(question => {
      const { section } = question
      const questions = map.get(section)
      if (!questions) {
        map.set(section, [question])
      } else {
        questions.push(question)
      }
    })

    return map
  },
  isCompanyCompleted: (state, getters) => {
    const company = getters.getCompany
    const { questions, answers } = getProgressFromSection(getters, 1)

    return ((answers + (company ? 1 : 0)) * 100) / (questions + 1) === 100
  },
  isTransportCompleted: (state, getters) => {
    const { questions, answers } = getProgressFromSection(getters, 2)
    return (answers * 100) / questions === 100
  },
  isAccountCompleted: (state, getters) => {
    const account = getters.getAccount
    const { questions, answers } = getProgressFromSection(getters, 3)
    return ((answers + (account.firstName ? 1 : 0)) * 100) / (questions + 1) === 100
  },
  getCompanyProgress: (state, getters, { route }) => {
    const questions = getSectionQuestionsCount(getters, 1)
    const total = questions + 1

    let progress = 0
    if (route.name === 'RegisterQuestions') {
      const index = parseInt(route.params.index, 10)
      progress += index + 1
    }

    const accountRoutes = ['RegisterAccountInfos', 'RegisterAccountCredentials']
    if (accountRoutes.includes(route.name)) {
      return 100
    }

    return (Math.min(progress, total) * 100) / total
  },
  getTransportProgress: (state, getters, { route }) => {
    const previousQuestions = getSectionQuestionsCount(getters, 1)
    const questions = getSectionQuestionsCount(getters, 2)
    const total = questions

    const accountRoutes = ['RegisterAccountInfos', 'RegisterAccountCredentials']
    if (accountRoutes.includes(route.name)) {
      return 100
    }

    let progress = 0
    if (route.name === 'RegisterQuestions') {
      const index = parseInt(route.params.index, 10)
      if (index > previousQuestions) {
        progress += index - previousQuestions
      }
    }

    return (Math.min(progress, total) * 100) / total
  },
  getAccountProgress: (state, getters, { route }) => {
    const previousQuestions = getSectionQuestionsCount(getters, 1) + getSectionQuestionsCount(getters, 2)
    const questions = getSectionQuestionsCount(getters, 3)
    const total = questions + 2

    let progress = 0
    if (route.name === 'RegisterQuestions') {
      const index = parseInt(route.params.index, 10)
      if (index > previousQuestions) {
        progress += index - previousQuestions
      }
    }

    if (route.name === 'RegisterAccountInfos' && questions) {
      progress += questions
    }

    if (route.name === 'RegisterAccountCredentials') {
      progress += questions + 1
    }

    return (Math.min(progress, total) * 100) / total
  }
}
