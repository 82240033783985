var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"ctk-alert-banner-icon tw-w-full tw-flex tw-text-white",class:{
    'tw-bg-red-500': _vm.props.variant === 'danger',
    'tw-bg-yellow-500': _vm.props.variant === 'warning',
    'tw-bg-blue-500': _vm.props.variant === 'info'
  }},[_c('ui-ctk-icon',{staticClass:"tw-text-6xl tw-m-auto",attrs:{"name":_vm.props.variant === 'danger'
      ? 'canceled'
      : _vm.props.variant === 'warning'
        ? 'warning'
        : 'info'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }