import * as Rules from 'vee-validate/dist/rules'

import { i18n } from '@/locales'
import useSize from '@/composables/useSize'

/** @type {import('vee-validate/dist/types/types').ValidationRuleSchema} */
const validator = {
  ...Rules.size,
  /** @type {import('vee-validate/dist/types/types').ValidationMessageGenerator} */
  /** @param {any} args */
  message: (field, args) => {
    return i18n.t('validator.size', {
      size: useSize(args.size * 1024)
    }).toString()
  }
}

/**
 * Override the default VeeValidate "size" rule to change the error message
 * & use a more usefull size format (by default shows only in kb).
 */
export default {
  name: 'size',
  validator
}
