<template>
  <div
    :style="{
      width: `${size}px`,
      height: `${size}px`
    }"
    class="ui-loader-pallet tw-relative tw-flex tw-justify-center tw-items-center"
  >
    <div
      class="ui-loader-pallet__group tw-flex tw-justify-around tw-w-full tw-h-full tw-absolute ui-loader-pallet__group__back"
    >
      <div
        v-for="index in 3"
        :key="index"
        :style="{
          backgroundColor: backgroundBack
        }"
        class="ui-loader-pallet__group__branch"
      />
    </div>
    <div
      class="ui-loader-pallet__group tw-flex tw-justify-between tw-w-full tw-h-full tw-absolute ui-loader-pallet__group__front"
    >
      <div
        v-for="index in 4"
        :key="index"
        :style="{
          backgroundColor: backgroundFront
        }"
        class="ui-loader-pallet__group__branch"
      />
    </div>
  </div>
</template>

<script>
  /**
   * @module component - UiLoaderPallet
   * @param {number} [size=80]
   * @param {string} [backgroundFront='#96bf31']
   * @param {string} [backgroundBack='#FFF']
   */
  export default {
    name: 'UiLoaderPallet',
    props: {
      size: {
        type: [Number, String],
        default: 80
      },
      backgroundFront: {
        type: String,
        default: '#96bf31'
      },
      backgroundBack: {
        type: String,
        default: '#FFF'
      }
    }
  }
</script>

<style lang="scss" scoped>

  /* stylelint-disable */
  .ui-loader-pallet {
    &__group {
      & > div {
        height: 100%;
      }

      &__front .ui-loader-pallet__group__branch {
        animation: front  1.2s ease-in-out infinite;
      }

      &__back .ui-loader-pallet__group__branch {
        animation: back 1.2s ease-in-out infinite;
      }

      &__branch {
        &:nth-child(1) {
          animation-delay: -1.2s;
        }

        &:nth-child(2) {
          animation-delay: -1.1s;
        }

        &:nth-child(3) {
          animation-delay: -1s;
        }

        &:nth-child(4) {
          animation-delay: -0.9s;
        }

        &:nth-child(5) {
          animation-delay: -0.8s;
        }
      }

      &.ui-loader-pallet__group__back {
        transform: rotate(45deg);

        .ui-loader-pallet__group__branch {
          width: 8%;
          height: 96%;
          margin: 2% 0;
        }
      }

      &.ui-loader-pallet__group__front {
        transform: rotate(-45deg);

        .ui-loader-pallet__group__branch {
          width: 19%;

          &:first-child {
            border-top-left-radius: 10rem;
            border-bottom-left-radius: 10rem;
          }

          &:last-child {
            border-top-right-radius: 10rem;
            border-bottom-right-radius: 10rem;
          }
        }
      }
    }
  }
  /* stylelint-enable */

  @keyframes front {
    0%,
    40%,
    100% {
      transform: scaleY(1);
    }

    20% {
      transform: scaleY(0.4);
    }
  }

  @keyframes back {
    0% { opacity: 0; }
    33% { opacity: 1; }
    70% { opacity: 1; }
    100% { opacity: 0; }
  }

</style>
