<template>
  <div
    class="tw-flex ctk-item-carrier"
  >
    <div
      :style="avatarStyle"
      class="ctk-item-carrier__icon mr-2"
    >
      <ui-ctk-icon
        name="drivers"
        data-test="icon"
      />
    </div>
    <div
      v-if="!avatarOnly"
      class="tw-flex dots-text tw-flex-col ctk-item-carrier__content"
    >
      <div class="tw-font-medium dots-text ctk-item-carrier__content__name">
        {{ name }}
      </div>
      <div class="tw-text-gray-700 dots-text fs-12 ctk-item-carrier__content__value">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, toRefs, computed } from '@vue/composition-api'

  import useColorUuid from '@/composables/useColorUuid'

  /**
   * @module component - CtkItemCarrier
   * @param {string} name
   * @param {boolean} avatarOnly
   */
  export default defineComponent({
    name: 'CtkItemCarrier',
    props: {
      user: {
        type: Object,
        default: null
      },
      name: {
        type: String,
        required: true
      },
      avatarOnly: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { user } = toRefs(props)
      const { getColorFromUuid } = useColorUuid()

      const avatarStyle = computed(() => {
        const avatarColor = getColorFromUuid(user.value.uuid)
        const { h, s, l } = avatarColor.background

        return {
          backgroundColor: `hsl(${Math.round(h)}, ${Math.round(s)}%, ${Math.round(l)}%)`,
          color: avatarColor.foreground
        }
      })

      return {
        avatarStyle
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-item-carrier {
    width: 150px;

    &__icon,
    &__content {
      margin: auto 0;
    }

    &__icon {
      width: 25px;
      height: 25px;
      border-radius: 100%;

      .ctk-font {
        position: relative;
        left: -5px;
        top: -5px;
      }
    }

    &__content {
      line-height: 1.4;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      width: 100%;
    }
  }

</style>
