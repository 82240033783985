import Vue from 'vue'

import { Auth } from '@/resources'
import store from '@/store'
import { i18n } from '@/locales'

/**
 * TODO: Refactor "showToaster" methods that are defined in multiple places.
 */
function showToaster (message, error = false) {
  /**
   * Set a timeout of 2 secondes to await for global loader
   * to be over.
   */
  setTimeout(() => {
    Vue.toasted[error ? 'error' : 'success'](i18n.t(message), {
      duration: 10000,
      position: 'bottom-right',
      action: {
        text: i18n.t('close'),
        onClick: (e, toast) => {
          toast.goAway(0)
        }
      }
    })
  }, 2000)
}

export default async (to, from, next) => {
  if (to.name === 'EmailValidation') {
    if (!to.query.token) {
      next({
        name: 'Auth'
      })
    } else {
      store.dispatch('auth/setToken', to.query.token)
      await Vue.nextTick()
      Auth.emailValidation()
        .then(() => {
          showToaster('app.paragraphs.email_validation.confirmed')
        })
        .catch(err => {
          if (!err.response) return

          showToaster('app.paragraphs.email_validation.failed', true)
        })
        .finally(() => {
          /**
           * Clear the token since it's a validation token only. Cannot
           * be used to sign in the user.
           */
          store.dispatch('auth/setToken', null)

          next({
            name: 'Auth'
          })
        })
    }
  } else {
    next()
  }
}
