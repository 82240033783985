// @ts-nocheck
export default {
  getInvoices: state => state.invoices,
  /**
   * Returns true if all the invoices are selected
   * @function isInvoicesSelected
   * @returns {boolean}
   */
  isInvoicesSelected: (state, getters, rootState, rootGetters) => {
    return rootGetters['billing/getInvoicesItems'].length === getters.getInvoices.length
  },
  /**
   * Returns true if the invoices are partially selected
   * @function isInvoicesPartiallySelected
   * @returns {boolean}
   */
  isInvoicesPartiallySelected: (state, getters, rootState, rootGetters) => {
    return getters.getInvoices.length > 0 && rootGetters['billing/getInvoicesItems'].length !== getters.getInvoices.length
  },
  /**
   * Returns the sum of all the selected invoices
   * @function getComputedInvoicesTotal
   * @returns {number}
   */
  getComputedInvoicesTotal: (state, getters) => getters.getInvoices
    .filter(invoice => invoice.uuid && !!invoice.total)
    .reduce((prev, curr) => {
      return prev + curr.total
    }, 0),
  /**
   * Returns the sum of all the selected invoices from the endpoint
   * @function getInvoicesTotal
   * @returns {number}
   */
  getInvoicesTotal: state => state.invoicesTotal,
  /**
   * Returns the fetched payment intent secret id
   * @function getPaymentIntent
   * @returns {string}
   */
  getPaymentIntent: state => state.paymentIntent
}
