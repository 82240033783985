<template>
  <div
    :style="avatarStyle"
    class="ctk-avatar tw-rounded-full tw-text-center tw-select-none tw-text-sm"
    aria-hidden="true"
    v-text="initials"
  />
</template>

<script>
  import { defineComponent, toRefs, computed } from '@vue/composition-api'
  import useColorUuid from '@/composables/useColorUuid'

  /**
   * @module component - ctkAvatar
   * @param {Object} user
   */
  export default defineComponent({
    name: 'CtkAvatar',
    props: {
      user: {
        type: Object,
        required: true
      },
      white: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { user } = toRefs(props)
      const { getColorFromUuid } = useColorUuid()

      const initials = computed(() => {
        const { first_name: firstName, last_name: lastName } = user.value
        return `${firstName.trim().charAt(0)}${lastName.trim().charAt(0)}`.toUpperCase()
      })

      const avatarStyle = computed(() => {
        const avatarColor = getColorFromUuid(user.value.uuid)
        const { h, s, l } = avatarColor.background

        return {
          backgroundColor: `hsl(${Math.round(h)}, ${Math.round(s)}%, ${Math.round(l)}%)`,
          color: avatarColor.foreground
        }
      })

      return {
        initials,
        avatarStyle
      }
    }
  })
</script>

<style lang="scss" scoped>

  $size: 40px;

  .ctk-avatar {
    height: $size;
    width: $size;
    line-height: $size;
  }

</style>
