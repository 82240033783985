/**
 * @function uppercase
 * @param {string} val
 * @returns {string}
 */
export default (val) => {
  return val
    ? val.toString().toUpperCase()
    : ''
}
