// @ts-nocheck

import shajs from 'sha.js'
import moment from 'moment'

import { Company, Me, Auth, PaymentSource } from '@/resources'
import { loadLanguageAsync } from '@/locales'
import { EventBus } from '@/services/EventBus'
import Config from '@/services/Config'
import Hotjar from '@/plugins/VueHotjar'
import Storage from '@/services/Storage'

import Vue from 'vue'

async function setLanguage (locale) {
  const lang = locale || 'en'
  return loadLanguageAsync(lang.substr(0, 2))
}

export default {
  setRedirectUrl ({ commit }, url) {
    commit('SET_URL_REDIRECT', url)
  },
  setUserInfos ({ commit }, infos) {
    commit('SET_USER_INFOS', infos)
  },
  setToken ({ commit }, val) {
    commit('SET_TOKEN', val)
  },
  setImpersonating ({ commit }, val) {
    commit('SET_IMPERSONATING', val)
  },
  setImpersonate ({ commit }, val) {
    commit('SET_IMPERSONATE', val)
  },
  setPaymentSources ({ commit }, paymentSources) {
    commit('SET_PAYMENT_SOURCES', paymentSources)
  },
  getMe ({ commit, getters }, ctx) {
    let ignoreLocale = false
    if (ctx && ctx.ignoreLocale) ignoreLocale = true

    return new Promise((resolve, reject) => {
      Me.get()
        .then(async response => {
          const data = response.data

          commit('SET_USER_INFOS', data)
          if (!ignoreLocale) await setLanguage(data.locale.toLowerCase())

          EventBus.$emit('auth:authentified', data)

          try {
            Vue.hubspot.setUserData(data)
          } catch (e) {
            console.error('Hubspot: An error occured:', e)
          }

          const isImpersonate = !!getters.getImpersonate

          /**
           * Set the Matomo user ID tracking informations
           */
          try {
            if (typeof window._paq === 'undefined') throw new Error('Matomo is not defined.')
            const matomo = Vue.prototype.$matomo
            matomo.setUserId(data.uuid)
            matomo.trackPageView()

            /**
             * Disable Matomo for impersonated users
             */
            matomo.setEnabled(!isImpersonate)

            /**
             * Set a custom variable for Matomo that includes the feature flags
             * enabled for the current user.
             *
             * The variable value follows the format: "name:value|name:value"
             */
            const { settings } = data.companies[0]
            let flags = ''
            if (settings) {
              flags = Object.keys(settings).map(key => `${key}:${settings[key]}`).join('|')
            }

            matomo.setCustomVariable(1, 'FeatureFlags', flags, 'visit')
          } catch (e) {
            console.error('Could not set the Matomo Analytics user tracking code: ', e)
          }

          /**
           * Enable Hotjar for carriers that are registered more than one month; that's not
           * impersonate, and shippers.
           */
          try {
            const isRegisteredMoreMonth = data.registered_at && moment(data.registered_at).diff(moment(), 'months') <= -1
            const isCarrier = data.companies[0].is_carrier

            const canTrackCarrier = isRegisteredMoreMonth && isCarrier
            const canTrackShipper = !isCarrier

            if (isImpersonate) Hotjar.tag('Impersonate')

            if ((canTrackCarrier || canTrackShipper) && !isImpersonate) {
              Hotjar.tag(isCarrier ? 'Carrier' : 'Shipper')

              console.log('Hotjar injected.')
            }
          } catch (e) {
            console.error('Could not enable Hotjar', e)
          }

          /**
           * Enable Google Tag Manager & include default informations
           */
          try {
            if (!Vue.prototype.$gtm) throw new Error('GTM is not loaded.')
            window.dataLayer = window.dataLayer || []
            const hasDataLayer = window.dataLayer.find(layer => layer.userId === data.uuid)
            if (!hasDataLayer) {
              const dataLayer = {
                environment: 'client app',
                userId: data.uuid,
                userType: data.companies[0].is_carrier ? 'carrier' : 'shipper',
                userEmail: data.email,
                userEmailSha256: shajs('sha256').update(data.email).digest('hex')
              }

              /**
               * Check if there is a registered date. This value can be null
               */
              if (data.registered_at) {
                dataLayer.userInscriptionDate = moment(data.registered_at)
                  .format('YYYY-MM-DD HH:ss')
              }

              window.dataLayer.push(dataLayer)
            }

            Vue.prototype.$gtm.enable(true)
          } catch (e) {
            console.error('Could not set the GTM tracking code: ', e)
          }

          resolve(response)
        })
        .catch(err => reject(err))
    })
  },
  passwordForgot (store, { email }) {
    const formData = new URLSearchParams()
    formData.append('type', 'email_link')
    formData.append('client_id', Config.get('api.clientId'))
    formData.append('username', email)

    return Auth.resetPassword({}, formData)
  },
  signIn ({ dispatch, getters }, { email, password }) {
    return new Promise((resolve, reject) => {
      const formData = new URLSearchParams()
      formData.append('grant_type', 'password')
      formData.append('client_id', Config.get('api.clientId'))
      formData.append('username', email)
      formData.append('password', password)

      Auth.signIn({}, formData)
        .then(({ data }) => {
          Storage && Storage.setItem('userToken', data.token)
          dispatch('setToken', data.token)

          dispatch('getMe', {
            ignoreLocale: false
          })
            .then(async (res) => {
              if (!this.isPreRegistered) {
                await dispatch('retrieveCompany')
              }

              /**
               * By doing this, we hide the locale change
               */
              dispatch('setAppReady', false, {
                root: true
              })

              if (getters.isUserShipper) {
                dispatch('notifications/notificationsPooling', null, {
                  root: true
                })
              }

              await dispatch('ui/retrieveAlerts', null, {
                root: true
              })

              await setLanguage(res.data.locale)

              resolve(res)
            })
            .catch((err) => reject(err))
        })
        .catch((err) => reject(err))
    })
  },
  retrieveCompany ({ commit, rootGetters }) {
    return Company.get({
      cid: rootGetters['auth/getCid']
    })
      .then(res => {
        commit('SET_COMPANY_INFOS', res.data)

        return res
      })
  },
  retrievePaymentSources ({ commit, rootGetters }, withExpired = false) {
    return PaymentSource.get({
      cid: rootGetters['auth/getCid']
    }, {
      params: {
        with_expired: withExpired ? 1 : 0
      }
    })
      .then(res => {
        /**
         * We don't want to include the expired cards in the paymentSources list as
         * it's used in every payment view.
         * The cards including expired ones should be handled locally.
         */
        if (!withExpired) {
          commit('SET_PAYMENT_SOURCES', res.data.items)
        }

        return res
      })
  },
  resendValidation ({ rootGetters }) {
    return Company.validateEmail({
      cid: rootGetters['auth/getCid']
    })
  },
  updateEmail ({ getters, dispatch, rootGetters }, email) {
    return Company.updateEmail({
      cid: rootGetters['auth/getCid']
    }, {
      email
    })
      .then(() => {
        dispatch('setUserInfos', {
          ...getters.getUserInfos,
          email
        })
        dispatch('ui/retrieveAlerts', null, { root: true })
      })
  }
}
