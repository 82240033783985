var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ctk-alert-banner',{staticClass:"ctk-email-validation",attrs:{"title":_vm.title,"variant":_vm.variant,"tabindex":"0"}},[_c('div',{staticClass:"tw-mb-0"},[_c('span',{directives:[{name:"markdown",rawName:"v-markdown",value:(_vm.text),expression:"text"}]}),_c('div',{staticClass:"tw-inline-flex"},[(_vm.type === 'user_email_awaiting_validation')?_c('a',{directives:[{name:"matomo",rawName:"v-matomo",value:({
          click: {
            category: 'Global',
            action: 'Alerts',
            name: 'Email Validation Resent'
          }
        }),expression:"{\n          click: {\n            category: 'Global',\n            action: 'Alerts',\n            name: 'Email Validation Resent'\n          }\n        }"}],staticClass:"ctk-email-validation__link tw-font-medium tw-italic tw-underline tw-ml-1 tw-mr-6",attrs:{"disabled":_vm.$wait.is('re-sending email validation'),"href":"#","role":"button"},domProps:{"textContent":_vm._s(_vm.$t('app.buttons.resend_validation_link'))},on:{"click":function($event){$event.preventDefault();return _vm.resend.apply(null, arguments)}}}):_vm._e(),_c('a',{directives:[{name:"matomo",rawName:"v-matomo",value:({
          click: {
            category: 'Global',
            action: 'Alerts',
            name: 'Email Validation Edit'
          }
        }),expression:"{\n          click: {\n            category: 'Global',\n            action: 'Alerts',\n            name: 'Email Validation Edit'\n          }\n        }"}],staticClass:"ctk-email-validation__link tw-font-medium tw-italic tw-underline tw-ml-1",attrs:{"href":"#","role":"button"},domProps:{"textContent":_vm._s(_vm.$t('app.buttons.edit_email'))},on:{"click":function($event){$event.preventDefault();return _vm.edit.apply(null, arguments)}}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }