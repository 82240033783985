// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class MeResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/me'
  }

  locale (...args) {
    return this.action('PUT', '/locale', ...args)
  }

  identity (...args) {
    return this.action('PUT', '/identity', ...args)
  }

  password (...args) {
    return this.action('PUT', '/password', ...args)
  }
}
