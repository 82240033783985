export default class SearchModel {
  constructor () {
    return {
      pickup_iso_codes: [],
      delivery_iso_codes: [],
      max_length: 1320,
      max_height: 270,
      max_weight: 28000,
      max_width: 240,
      without_tail_lift: false,
      send_by_email: false,
      name: '',
      description: '',
      count: null
    }
  }
}
