export default {
  /**
   * Returns the config value from a dotted path.
   * @function get
   * @param {string} configKey
   * @returns {any}
   */
  get (configKey) {
    const configResult = configKey
      .split('.')
      // @ts-ignore
      .reduce((o, i) => o[i], window._env)

    if (typeof configResult === 'undefined' || configResult === null) throw new Error(`Config key (${configKey}) is not present in the configuration file.`)

    return configResult
  }
}
