// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class CompanyMissionResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}/missions{/mid}'
  }

  metrics (...args) {
    const metrics = new AxiosResource()
    metrics.path = '/v2/companies/{cid}/metrics/missions'
    return metrics.get(...args)
  }

  pickupComplete (...args) {
    return this.action('POST', '/pickup/complete', ...args)
  }

  deliveryComplete (...args) {
    return this.action('POST', '/delivery/complete', ...args)
  }

  order (...args) {
    return this.action('POST', '/mission-order', ...args)
  }

  reorder (...args) {
    return this.action('PUT', '/mission-order', ...args)
  }

  travel (...args) {
    return this.action('GET', '/travel', ...args)
  }

  billing (...args) {
    return this.action('GET', '/billing', ...args)
  }

  changeDriver (...args) {
    return this.action('POST', '/change-driver', ...args)
  }
}
