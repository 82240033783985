<template>
  <ctk-input-select
    v-model="locale"
    :items="locales"
    :label="$t('app.labels.locale')"
    :has-label="false"
    :disabled="$wait.is('updating locale')"
    class="ctk-impersonate-nav-bar-locales"
  >
    <template
      #currentItem="{ item }"
    >
      <div
        v-if="item"
        class="tw-flex tw-items-center"
      >
        <ui-flag
          :country="item.value"
          data-test="flag"
        />
        <div
          class="tw-text-base sm:tw-text-sm ctk-impersonate-nav-bar-locales__locale dots-text"
          data-test="value"
        >
          {{ item.text }}
        </div>
      </div>
    </template>

    <template
      #item="{ item }"
    >
      <div class="tw-flex tw-items-center">
        <ui-flag
          :country="item.value"
          data-test="flag"
        />
        <div class="dots-text ctk-impersonate-nav-bar-locales__locale">
          {{ item.text }}
        </div>
      </div>
    </template>
  </ctk-input-select>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import { supportedLocales } from '@/locales/constants'
  import { loadLanguageAsync } from '@/locales'
  import CtkInputSelect from '@/components/CtkInputs/CtkInputSelect/index.vue'

  /**
   * @module component - CtkImpersonateNavBarLocales
   */
  export default defineComponent({
    name: 'CtkImpersonateNavBarLocales',
    components: {
      CtkInputSelect
    },
    computed: {
      locale: {
        get () {
          return this.$i18n.locale === 'en' ? 'gb' : this.$i18n.locale
        },
        async set (value) {
          const locale = value === 'gb' ? 'en' : value
          this.$wait.start('updating locale')
          await loadLanguageAsync(locale)
          this.$wait.end('updating locale')
        }
      },
      locales () {
        return supportedLocales
          .map(({ name, locale }) => ({
            value: locale === 'en' ? 'gb' : locale,
            text: name
          }))
      }
    }
  })
</script>

<style lang="scss">

  @media only screen and (max-width: $breakpoint-tablet) {
    .ctk-impersonate-nav-bar-locales {
      .ctk-input-select__wrapper {
        padding: 0 14px 0 12px;
      }

      &.ctk-input-select .ctk-impersonate-nav-bar-locales__locale {
        display: none;
      }
    }
  }

</style>
