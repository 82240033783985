import component from './component'
import extend from './extend'
import mixin from './mixin'

export default (/** @type {import('vue').VueConstructor} */ Vue) => {
  Vue.component('Icu', component)

  extend(Vue)
  Vue.mixin(mixin)
}
