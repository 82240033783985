// @ts-nocheck
import { Shipment } from '@/resources'
import Storage from '@/services/Storage'

export default {
  /**
   * @function setNotificationsAsRead
   */
  setNotificationsAsRead ({ commit, getters }) {
    let parsedPendingNotifications = []

    try {
      const pendingNotificationsStorage = Storage && Storage.getItem('ctk:read-notifications')
      if (pendingNotificationsStorage) {
        parsedPendingNotifications = JSON.parse(pendingNotificationsStorage)
      }

      if (!Array.isArray(parsedPendingNotifications)) {
        throw new Error('The read notifications storage key is not an array.')
      }
    } catch (e) {
      console.error('Error occured while parsing JSON', e)
    }

    getters.getNotifications.forEach(notification => {
      const { uuid, expires_at: expiresAt } = notification
      commit('SET_NOTIFICATION_AS_READ', notification)

      /**
       * Ensure to check if the saved notification with the exact same args was not already
       * saved. If not, add a new entry.
       */
      const storageIndex = parsedPendingNotifications.findIndex(item => item.uuid === uuid && item.expires_at === expiresAt)
      if (storageIndex === -1) {
        parsedPendingNotifications.push({
          uuid,
          expires_at: expiresAt
        })
      }
    })

    try {
      if (Storage) {
        Storage.setItem('ctk:read-notifications', JSON.stringify(parsedPendingNotifications))
      }
    } catch (e) {
      console.error('Error occured while parsing JSON', e)
    }
  },
  /**
   * @function retrieveNotifications
   * @returns {Promise<any>}
   */
  retrieveNotifications ({ commit, rootGetters }) {
    if (!rootGetters.isUserShipper) {
      return Promise.resolve()
    }

    return Shipment.get({
      cid: rootGetters['auth/getCid']
    }, {
      params: {
        expires_soon: 1
      }
    })
      .then(response => {
        let readNotifications = []
        try {
          const pendingNotificationsStorage = Storage && Storage.getItem('ctk:read-notifications')
          if (pendingNotificationsStorage) {
            readNotifications = JSON.parse(pendingNotificationsStorage)
          }

          if (!Array.isArray(readNotifications)) {
            throw new Error('The read notifications storage key is not an array.')
          }
        } catch (e) {
          console.error('Something occured', e)
        }

        /**
         * Compare the notifications in the storage with the ones fetched from the API.
         * We consider that notifications in the storage that are not in the API with a different
         * expires at should be removed from the storage.
         */
        const notificationsToKeep = readNotifications.filter(notification => {
          const index = response.data.items.findIndex(item => item.uuid === notification.uuid && item.expires_at === notification.expires_at)
          return index !== -1
        })

        try {
          const parsedNotifications = JSON.stringify(notificationsToKeep)
          Storage && Storage.setItem('ctk:read-notifications', parsedNotifications)
        } catch (e) {
          console.error('Something occured', e)
        }

        const items = response.data.items.map(item => {
          const isNotificationRead = readNotifications
            .filter(notification => notification.uuid === item.uuid && notification.expires_at === item.expires_at).length > 0

          return {
            ...item,
            read: isNotificationRead
          }
        })

        commit('SET_NOTIFICATIONS_ITEMS', items)
        commit('SET_NOTIFICATIONS_META', response.data.meta)
      })
      .catch(() => {})
  },
  /**
   * @function notificationsPooling
   */
  notificationsPooling ({ dispatch, getters }) {
    const prevPoolingInterval = getters.getNotificationsPoolingInterval
    if (prevPoolingInterval) {
      clearInterval(prevPoolingInterval)
    }

    dispatch('retrieveNotifications')
    const poolingInterval = setInterval(() => {
      dispatch('retrieveNotifications')
    }, 30000)

    dispatch('setNotificationsPoolingInterval', poolingInterval)
  }
}
