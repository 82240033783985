<template>
  <ctk-dialog
    :value="dialogValue"
    modal-class="ctk-email-validation-dialog"
    hide-header
    hide-footer
    persistent
    @handle-close="close"
  >
    <div class="ctk-email-validation-dialog__wrapper">
      <div class="ctk-email-validation-dialog__header tw-h-12 tw-flex tw-justify-end">
        <ctk-dialog-close-button
          data-test="close"
          @click.native="close"
        />
      </div>

      <div
        :class="{
          'tw-pt-6 md:tw-pt-10': confirmation || emailConfirmation,
          'tw-pt-0': !(confirmation || emailConfirmation)
        }"
        class="ctk-email-validation-dialog__content tw-px-4 tw-pb-6 md:tw-pb-8 md:tw-px-10 tw-flex tw-flex-col"
      >
        <template
          v-if="confirmation || emailConfirmation"
        >
          <img
            src="@/assets/img/icons/email.svg"
            width="60"
            height="63"
            alt=""
            class="tw-mx-auto tw-mb-8"
            data-test="icon"
          >
          <h1
            v-text="$t('app.titles.email_validation.resent')"
            class="ctk-email-validation-dialog__content__title tw-font-normal tw-mb-5 tw-text-2xl tw-text-center"
            data-test="title"
          />
          <p
            v-text="$t('app.paragraphs.email_validation.resent')"
            class="tw-text-center"
            data-test="content"
          />
        </template>
        <template
          v-else
        >
          <h1
            v-text="$t('app.titles.email_validation.update')"
            class="ctk-email-validation-dialog__content__title-form tw-font-normal tw-mb-10 tw-text-2xl"
            data-test="title"
          />
          <ValidationObserver
            ref="observer"
            slim
          >
            <form
              :disabled="$wait.is('updating email')"
              class="tw-flex tw-flex-col"
              @submit.prevent="submitted"
            >
              <ValidationProvider
                ref="email-provider"
                :name="$t('app.fields.email')"
                rules="required|email"
                slim
              >
                <template slot-scope="{ invalid, validated, errors }">
                  <ctk-input-text
                    id="email"
                    v-model="formData.email"
                    :label="$t('app.labels.email')"
                    :disabled="$wait.is('updating email')"
                    :error="invalid && validated"
                    :hint="errors[0]"
                    data-test="email-field"
                    type="email"
                    name="email"
                    class="tw-mb-6"
                    autocomplete="email"
                    required
                  />
                </template>
              </ValidationProvider>

              <ui-button
                :loading="$wait.is('updating email')"
                :disabled="$wait.is('updating email')"
                class="tw-mb-10 tw-mx-auto"
                variant="primary"
                type="submit"
                data-test="submit"
              >
                {{ $t('app.buttons.validate_email') }}
              </ui-button>
            </form>
          </ValidationObserver>
        </template>
      </div>
    </div>
  </ctk-dialog>
</template>

<script>
  import { mapActions } from 'vuex'
  import { defineComponent, ref, watch } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import handlePropertyPathViolations from '@/resources/handlers/violations'
  import { showToaster } from '@/services/Toaster'

  /**
   * @module component - CtkEmailValidationDialog
   * @param {boolean} confirmation
   */
  export default defineComponent({
    name: 'CtkEmailValidationDialog',
    components: {
      CtkDialog,
      CtkInputText,
      CtkDialogCloseButton
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      confirmation: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')
      const emailConfirmation = ref(false)
      const formData = ref({
        email: null
      })

      watch(dialogValue, (v) => {
        if (!v) {
          formData.value.email = null
          emailConfirmation.value = false
        }
      })

      return {
        formData,
        dialogValue,
        emailConfirmation
      }
    },
    methods: {
      ...mapActions('auth', [
        'updateEmail'
      ]),
      /**
       * Close the dialog
       * @function close
       */
      close () {
        this.dialogValue = false
      },
      submitted () {
        // @ts-ignore
        this.$refs.observer.validate()
          .then((/** @type {boolean} */ valid) => {
            if (!valid) return

            if (this.$wait.is('updating email')) return

            this.$wait.start('updating email')
            this.updateEmail(this.formData.email)
              .then(() => {
                this.emailConfirmation = true
              })
              // @ts-ignore
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error && data.error.violations) {
                  handlePropertyPathViolations.call(this, data.error.violations)
                } else {
                  showToaster(
                    this,
                    data.error.title, {
                      type: 'error',
                      position: 'bottom-right'
                    }
                  )
                }
              })
              .finally(() => {
                this.$wait.end('updating email')
              })
          })
      }
    }
  })
</script>

<style lang="scss" scoped>
.ctk-email-validation-dialog__content__title-form {
  position: relative;
}
.ctk-email-validation-dialog__content__title-form::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: -0.5rem;
  width: 50%;
  height: 1px;
  background-color: $divider;
}
.ctk-email-validation-dialog__footer {
  margin-top: 32px;
}
.ctk-email-validation-dialog__wrapper {
  position: relative;
}
@media (min-width: 770px) {
  .ctk-email-validation-dialog__wrapper {
    padding-bottom: 170px;
  }
  .ctk-email-validation-dialog__wrapper::after {
    position: absolute;
    bottom: 16px;
    width: 100%;
    min-height: 170px;
    content: '';
    background-size: cover;
    background-image: url('~@/assets/img/illustrations/shipment_confirmation.svg');
    background-position: center bottom;
    opacity: 0.8;
  }
}
</style>
