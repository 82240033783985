import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate'
import * as Rules from 'vee-validate/dist/rules'

import CustomValidators from '@/validators'

/**
 * List of pre-existing rules that have been overrided for some reason.
 * @const OVERRIDED_RULES
 */
const OVERRIDED_RULES = ['size']

export function init () {
  CustomValidators.init()

  /** @type {any} */
  const importedRules = Rules
  for (const rule in importedRules) {
    if (!OVERRIDED_RULES.includes(rule)) {
      extend(rule, importedRules[rule])
    }
  }
}

setInteractionMode('eager')

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
