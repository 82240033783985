<template>
  <figure
    :class="{
      'tw-flex': !inline,
      'tw-inline-block': inline
    }"
    class="ui-flag tw-relative tw-m-0 tw-mr-2 flex-fixed tw-overflow-hidden"
  >
    <img
      :class="`tw-absolute tw-top-0 tw-left-0 flag-small flag flag-${country.toLowerCase()}`"
      :alt="country.toUpperCase()"
      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      data-test="flag"
    >
  </figure>
</template>

<script>
  /**
   * @module component - UIFlag
   * @param {string} country
   * @param {boolean} inline
   */
  export default {
    name: 'UIFlag',
    props: {
      country: {
        type: String,
        required: true
      },
      inline: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ui-flag {
    width: 22px;
    height: 18px;

    img {
      image-rendering: pixelated;
    }
  }

</style>
