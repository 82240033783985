import Vue from 'vue'
import marked from 'marked'

const ctx = '@@Markdown'

/**
 * Emails inside markdown should not be rendered as links.
 * Convert any token starting with mailto (since it's already parsed)
 * to a plain text token.
 *
 * See: https://github.com/markedjs/marked/issues/882#issuecomment-781628889
 */
marked.use({
  tokenizer: {
    url (src) {
      if (src.startsWith('mailto')) {
        return {
          type: 'text',
          raw: src,
          text: src
        }
      }
    }
  }
})

/**
 * @function applyMarkdown
 * @param {any} el
 * @param {any} binding
 */
function applyMarkdown (el, binding) {
  el[ctx] = {
    el,
    expression: binding.value
  }

  el[ctx].el.innerHTML = marked(binding.value)
  el[ctx].el.classList.add('markdown')
}

export const directive = {
  bind: applyMarkdown,
  update: applyMarkdown
}

Vue.directive('markdown', directive)
