import Vue from 'vue'

import { i18n } from '@/locales'
import AuthService from '@/services/AuthService'

export default {
  response: (/** @type {import('axios').AxiosResponse} */ response) => response,
  error: (/** @type {import('axios').AxiosError} */ error) => {
    /**
     * Whenever we receives a 401 error (unauthorized), we sign out the
     * user as we consider not logged anymore (token expired?).
     *
     * Note that we must ignore specific URLs because in some cases we
     * want to handle the 401 error ourselves, not globally (eg. login).
     */
    const ignoredPaths = [
      '/auth/token',
      '/auth/forgotten_password',
      '/auth/renew_password',
      '/auth/email_validation'
    ]

    const { response } = error
    if (!response) throw error

    // @ts-ignore
    if (ignoredPaths.includes(response.config.url)) {
      throw error
    }

    if (response.status === 401) {
      AuthService.signOut(() => {
        Vue.toasted.error(i18n.t('app.errors.unauthorized').toString(), {
          duration: 10000,
          position: 'bottom-right',
          action: {
            text: i18n.t('close').toString(),
            onClick: (e, toast) => {
              toast.goAway(0)
            }
          }
        })
      })
    } else {
      throw error
    }
  }
}
