<template>
  <transition name="fade">
    <div
      v-if="dialogValue"
      v-click-outside="close"
      :class="{
        'ctk-notifications-dialog--impersonate': isImpersonateVisible
      }"
      class="ctk-notifications-dialog tw-flex tw-flex-col tw-fixed tw-right-0 tw-px-4 md:tw-px-8 tw-pt-2 tw-pb-6 tw-shadow-xl tw-bg-white tw-text-gray-800"
      :aria-label="$t('app.titles.notifications')"
      :aria-live="'polite'"
      role="dialog"
    >
      <ctk-notifications-dialog-header
        data-test="header"
        @close="close"
      />

      <div
        data-test="content"
        class="tw-overflow-auto tw-pt-5"
      >
        <div
          v-if="getNotifications && getNotifications.length === 0"
          class="tw-flex tw-justify-center tw-py-4"
          data-test="empty"
        >
          <p
            data-test="content"
            class="tw-mx-auto tw-text-base tw-font-medium tw-text-gray-700"
            v-text="$t('app.paragraphs.no_notifications')"
          />
        </div>

        <template
          v-else
        >
          <ctk-notifications-dialog-item
            v-for="(shipment, k) in getNotifications"
            :key="k"
            :shipment="shipment"
            class="tw-mb-2"
            @click="close"
          />
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { directive } from 'v-click-outside'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import CtkNotificationsDialogHeader from './_subs/CtkNotificationsDialogHeader/index.vue'
  import CtkNotificationsDialogItem from './_subs/CtkNotificationsDialogItem/index.vue'

  /**
   * @module component - CtkNotificationsDialog
   */
  export default {
    name: 'CtkNotificationsDialog',
    components: {
      CtkNotificationsDialogHeader,
      CtkNotificationsDialogItem
    },
    directives: {
      clickOutside: directive
    },
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        query: null,
        searchedQuery: null,
        results: null,
        previousRequest: null
      }
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        dialogValue
      }
    },
    computed: {
      ...mapGetters('ui', [
        'isImpersonateVisible'
      ]),
      ...mapGetters([
        'getNotifications'
      ])
    },
    methods: {
      /**
       * @function close
       */
      close () {
        this.dialogValue = false
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-notifications-dialog {
    max-width: 620px;
    max-height: 100vh;
    min-height: 200px;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 110;

    @media only screen and (min-width: $breakpoint-tablet) {
      height: auto;
      max-height: 600px;
      top: 55px;
      left: auto;

      &--impersonate {
        top: 110px;
      }
    }
  }

</style>
