// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class ProposalResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}/proposals{/pid}'
  }

  cancel (...args) {
    return this.action('POST', '/cancellation', ...args)
  }
}
