export default class MetaOffersModel {
  constructor () {
    return {
      item_count: null,
      pagination: {
        current_page: null,
        page_count: null,
        page_size: null
      },
      filters_description: ''
    }
  }
}
