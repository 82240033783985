/* eslint-disable */

/**
 * Polyfill added since it's causing issues with bootstrap-vue, that depends of
 * popper. Issue occurs in IE11.
 * TODO: Remove this once bootstrap-vue is removed.
 */
if (!String.prototype.contains) {
  String.prototype.contains = function(search, start) {
    'use strict'

    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp')
    } 
    if (start === undefined) { start = 0 }
    return this.indexOf(search, start) !== -1
  }
}

if (!Array.prototype.contains) {
  Object.defineProperty(Array.prototype, "contains", {
    enumerable: false,
    value: function(obj) {
        var newArr = this.filter(function(el) {
          return el == obj
        })
        return newArr.length > 0
      }
  })
}

if (!Object.prototype.contains) {
  Object.defineProperty(Object.prototype, "contains", {
    enumerable: false,
    value: function() {
      // do nothing
    }
  })
}

/**
 * Polyfill for .closest() that's not available in IE 11.
 */
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    let el = this

    do {
      if (Element.prototype.matches.call(el, s)) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
}
