// @ts-nocheck
import Vue from 'vue'

export default {
  SET_COMPANY (state, company) {
    state.company = company
  },
  SET_QUESTIONS (state, questions) {
    state.questions = questions
  },
  SET_ACCOUNT (state, infos) {
    state.account = {
      ...state.account,
      ...infos
    }
  },
  SET_ANSWER (state, { questionKey, answers }) {
    Vue.set(state.answers, questionKey, answers)
  },
  RESET_FORM (state) {
    state.company = null
    state.answers = {}
    state.account = {
      firstName: null,
      lastName: null,
      phone: null
    }
  }
}
