<template>
  <div
    class="ctk-input-select"
    v-bind="attrs"
  >
    <button
      :aria-label="hasLabel ? null : label"
      :aria-labelledby="hasLabel ? `${id} ${id}-value` : null"
      :aria-expanded="isOpen"
      :class="{
        'is-disabled': isDisabled,
        'has-value': !!value,
        'has-error': error,
        'has-hint': hint && !value,
        'has-label': hasLabel
      }"
      ref="button"
      type="button"
      aria-haspopup="listbox"
      class="ctk-input-select__wrapper tw-truncate"
      @click="show"
      @keyup="checkShow"
    >
      <span
        v-if="hasLabel"
        :id="id"
        :class="{
          'tw-text-red-500' : error
        }"
        :title="hintValue || labelValue"
        v-text="hintValue || labelValue"
        class="ctk-input-select__wrapper__label tw-truncate tw-pr-4"
        data-test="label"
      />
      <slot
        name="currentItem"
        :item="activeItem"
        :id="id"
      >
        <span
          :id="`${id}-value`"
          :class="{
            'tw-text-red-500' : error
          }"
          :title="activeItem ? activeItem.text : labelValue"
          v-text="activeItem ? activeItem.text : labelValue"
          class="ctk-input-select__wrapper__value tw-truncate"
        />
      </slot>
      <ui-material-icon
        class="ctk-input-select__wrapper__arrow"
        name="arrow_drop_down"
      />
    </button>

    <transition name="slide">
      <ctk-input-select-list
        ref="select-list"
        v-show="isOpen"
        v-model="selectValue"
        :id="id"
        :open.sync="isOpen"
        :items="formattedItems"
        :class="{
          'full-width': fullWidth
        }"
        @re-focus="refocus"
      >
        <template
          #item="{ item }"
        >
          <slot
            :item="item"
            v-text="item.text"
            name="item"
          />
        </template>
      </ctk-input-select-list>
    </transition>
  </div>
</template>

<script>
  import { KEYCODES } from '@/composables/constants'
  import CtkInputSelectList from './_subs/CtkInputSelectList/index.vue'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * Custom <select> component
   * @module component - CtkInputSelect
   * @param {Array<import('./_subs/CtkInputSelectList/index.vue').ListItem>} items
   * @param {string} label
   * @param {string} valueToShow
   * @param {string} hint
   * @param {boolean} [error=false]
   * @param {boolean} [hasLabel=true]
   * @param {boolean} [fullWidth=true]
   */
  export default {
    name: 'CtkInputSelect',
    inheritAttrs: false,
    components: {
      CtkInputSelectList
    },
    props: {
      label: {
        type: String,
        default: 'Label'
      },
      value: {
        type: [Array, String, Number],
        default: null
      },
      hint: {
        type: [String, Boolean],
        default: null
      },
      error: {
        type: Boolean,
        default: false
      },
      hasLabel: {
        type: Boolean,
        default: true
      },
      fullWidth: {
        type: Boolean,
        default: true
      },
      items: {
        type: Array,
        required: true
      },
      valueToShow: {
        type: [String, Number],
        default: null
      },
      ignoreOptional: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { state: selectValue } = useModelGetterSetter(props, 'value')

      return {
        selectValue
      }
    },
    data () {
      return {
        isOpen: false,
        keysSoFar: '',
        keyClear: null
      }
    },
    watch: {
      isOpen (v) {
        this.$emit('toggle', v)
      }
    },
    computed: {
      /**
       * @function formattedItems
       * @returns {Array<import('./_subs/CtkInputSelectList/index.vue').ListItem>}
       */
      formattedItems () {
        return this.items
          // @ts-ignore
          .map((/** @type {import('./_subs/CtkInputSelectList/index.vue').ListItem} */ v) => ({
            ...v,
            value: `${v.value}`
          }))
      },
      attrs () {
        const attrs = this.$attrs
        delete attrs.id
        return attrs
      },
      id () {
        return this.$attrs.id || 'CtkInputSelect'
      },
      /**
       * @function activeItem
       * @returns {import('./_subs/CtkInputSelectList/index.vue').ListItem}
       */
      activeItem () {
        // @ts-ignore
        return this.formattedItems
          .find((/** @type {import('./_subs/CtkInputSelectList/index.vue').ListItem} */ item) => `${item.value}` === `${this.value}`)
      },
      labelValue () {
        if (this.label && !this.isRequired && !this.ignoreOptional) {
          // @ts-ignore
          return `${this.label} (${this.$options.filters.capitalize(this.$t('optional'))})`
        }

        return this.label
      },
      hintValue () {
        if (this.hint && !this.isRequired && !this.ignoreOptional) {
          // @ts-ignore
          return `${this.hint} (${this.$options.filters.capitalize(this.$t('optional'))})`
        }

        return this.hint
      },
      /**
       * Returns true if the field is disabled
       * @function isDisabled
       * @returns {boolean}
       */
      isDisabled () {
        // @ts-ignore
        return typeof this.$attrs.disabled !== 'undefined' && this.$attrs.disabled !== false
      },
      /**
       * Returns true if the field is required
       * @function isRequired
       * @returns {boolean}
       */
      isRequired () {
        // @ts-ignore
        return typeof this.$attrs.required !== 'undefined' && this.$attrs.required !== false
      }
    },
    methods: {
      /**
       * @function checkShow
       * @param {KeyboardEvent} e
       */
      checkShow (e) {
        const key = e.which || e.keyCode
        switch (key) {
        case KEYCODES.UP:
        case KEYCODES.DOWN:
          e.preventDefault()
          this.show()
          /**
           * Enable this if we need to select the next value when the user clicks on the arrow
           * after the field is focus
           */
          // this.keyPress(e)
          break
        }
      },
      /**
       * Focus the input element when the user clicks on the select parent container
       * @function focus
       */
      focus () {
        /** @type {HTMLElement} */
        // @ts-ignore
        const input = this.$refs.input
        if (input) input.focus()
      },
      refocus () {
        /** @type {HTMLElement} */
        // @ts-ignore
        const button = this.$refs.button
        if (button) button.focus()
      },
      /**
       * @function show
       * @returns {Promise<any>}
       */
      async show () {
        if (this.isDisabled) return

        this.isOpen = true
        await this.$nextTick()

        /** @type {?any} */
        const selectList = this.$refs['select-list']
        if (selectList) {
          selectList.setupFocus()
          selectList.focus()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-input-select {
    &,
    &__wrapper {
      position: relative;
    }

    &__wrapper {
      transition-duration: 0.3s;
      width: 100%;
      height: 42px;
      min-height: 42px;
      padding: 0 32px 0 12px;
      font-weight: 400;
      appearance: none;
      outline: none;
      background-color: white;
      border: 1px solid #D2D2D2;
      border-radius: 4px;
      text-align: left;

      &__arrow {
        position: absolute;
        right: 4px;
        top: 10px;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.54);
      }

      &__label {
        position: absolute;
        top: 3px;
        left: 13px;
        transform: translateY(25%);
        opacity: 0;
        transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
        font-size: 11px;
        color: rgba(0, 0, 0, 0.54);
        z-index: 1;
        width: 100%;
        width: calc(100% - 13px);
        pointer-events: none;

        &.has-clear {
          width: calc(100% - 13px - 24px);
        }
      }

      &__value {
        @media only screen and (max-width: $breakpoint-mobile-l) {
          font-size: 1rem;
        }
      }

      &:not(.has-value) .ctk-input-select__wrapper__value {
        color: $secondary-text;
      }

      &.has-hint {
        padding-top: 14px;

        .ctk-input-select__wrapper__label {
          opacity: 1;
          transform: translateY(0);
          font-size: 11px;
        }
      }

      &.has-value.has-label {
        padding-top: 14px;

        .ctk-input-select__wrapper__label {
          opacity: 1;
          transform: translateY(0);
          font-size: 11px;
        }
      }

      &:focus:not(.is-disabled) {
        border-color: $info;

        .ctk-input-select__wrapper__label {
          color: $info;
        }
      }

      &.has-error {
        border-color: $danger !important;
      }

      &.is-disabled {
        cursor: not-allowed;
        background-color: rgba(black, 0.05);

        .ctk-input-select__wrapper__value {
          color: rgba(0, 0, 0, 0.54);
        }
      }

      & .ctk-input-select__wrapper__input:invalid,
      &.has-error {
        box-shadow: none !important;
      }
    }

    &-list {
      top: 42px;

      &.full-width {
        max-width: 100%;
        width: 100%;
      }
    }
  }

</style>
