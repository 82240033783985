import { Offer, Shipment } from '@/resources'

export default (/** @type {import('../AxiosResource').AxiosResourceConfig} */ config) => {
  if (config.method === 'get' && config.uriTemplate && config.headers) {
    // @ts-ignore
    const isOffer = config.uriTemplate.expression === Offer.path.expression
    // @ts-ignore
    const isShipment = config.uriTemplate.expression === Shipment.path.expression
    if (isOffer || isShipment) {
      config.headers['Content-Type'] = isOffer
        ? 'application/vnd.ctk.carrier_offer.v2+json'
        : 'application/vnd.ctk.company_shipment.v2+json'

      /**
       * Adding this because the contentType is not set if there is no data passed
       * to the request.
       * See https://github.com/axios/axios/issues/86
       */
      config.data = {}
    }
  }

  return config
}
