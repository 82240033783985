import Vue from 'vue'

import { i18n } from '@/locales'

export default {
  response: (/** @type {import('axios').AxiosResponse} */ response) => response,
  error: (/** @type {import('axios').AxiosError} */ error) => {
    if (error.response && error.response.status >= 500 && error.response.status <= 511) {
      Vue.toasted.error(i18n.t('an_error_has_occurred').toString(), {
        duration: 10000,
        position: 'bottom-right',
        action: {
          text: i18n.t('close').toString(),
          onClick: (e, toast) => {
            toast.goAway(0)
          }
        }
      })

      throw error
    }

    throw error
  }
}
