<template>
  <ctk-item-header
    class="shipment-item-header"
  >
    <shipment-item-header-references
      :shipment="shipment"
    />

    <shipment-item-header-expiration
      v-if="hasExpiration"
      :shipment="shipment"
    />

    <shipment-item-header-infos
      v-if="hasInfos"
      :shipment="shipment"
    />
  </ctk-item-header>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkItemHeader from '@/components/CtkItem/_subs/CtkItemHeader/index.vue'

  import ShipmentItemHeaderExpiration from './_subs/ShipmentItemHeaderExpiration/index.vue'
  import ShipmentItemHeaderInfos from './_subs/ShipmentItemHeaderInfos/index.vue'
  import ShipmentItemHeaderReferences from './_subs/ShipmentItemHeaderReferences/index.vue'

  /**
   * @module component - ShipmentItemHeader
   * @param {object} shipment
   * @param {boolean} hasExpiration
   * @param {boolean} hasInfos
   * @param {boolean} hasInvoices
   */
  export default defineComponent({
    name: 'ShipmentItemHeader',
    components: {
      CtkItemHeader,
      ShipmentItemHeaderExpiration,
      ShipmentItemHeaderInfos,
      ShipmentItemHeaderReferences
    },
    props: {
      shipment: {
        type: Object,
        required: true
      },
      hasExpiration: {
        type: Boolean,
        default: true
      },
      hasInfos: {
        type: Boolean,
        default: false
      },
      hasInvoices: {
        type: Boolean,
        default: false
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-item-header {
    &-references {
      max-width: 50%;
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      padding: 0 16px;
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      &-references {
        max-width: 100%;
      }
    }
  }

</style>
