<script>
  import Icon from '@/components/UI/Icon/index.vue'

  /**
   * @module component - CtkIcon
   * @param {string} name - Name of the icon that's concat with the class name
   */
  export default {
    components: {
      Icon
    },
    props: {
      name: {
        type: String,
        required: true
      }
    },
    render (h) {
      return h(Icon, {
        class: [
          'ctk-font',
          `icon-ctk-${this.name}`
        ]
      })
    }
  }
</script>
