import Vue from 'vue'

import store from '@/store'
import Storage from '@/services/Storage'

export default {
  /**
   * @function signOut
   * @param {Function} cb - Callback called before clearing token & user infos.
   */
  signOut (cb) {
    /**
     * Logout through Matomo
     */
    try {
      if (typeof window._paq === 'undefined') throw new Error('Matomo is not defined.')
      Vue.prototype.$matomo.resetUserId()
      Vue.prototype.$matomo.trackPageView()
    } catch (e) {
      console.error('Could not logout from Matomo: ', e)
    }

    /**
     * Remove specific storage keys between two logins.
     */
    const storageKeys = ['userToken', 'userRoles', 'ctk:ui:welcome-dialog']
    storageKeys.forEach(key => Storage && Storage.removeItem(key))

    store.dispatch('setAppReady', false)
    store.dispatch('auth/setToken', null)

    // @ts-ignore
    if (store.state.isImpersonating) {
      // @ts-ignore
      window.location = store.state.getRedirectUrl
    } else {
      Vue.prototype.router.push({
        name: 'SignIn'
      })
        .catch(() => {})
        .finally(() => {
          store.dispatch('auth/setImpersonate', null)
          store.dispatch('auth/setImpersonating', false)
          store.dispatch('auth/setUserInfos', null)
          store.dispatch('setAppReady', true)
        })

      if (cb) return cb()
    }
  }
}
