<template>
  <div class="ctk-infinite-loader tw-flex">
    <ui-button
      v-if="itemsCount && canLoadMore && !isLoading"
      class="ctk-infinite-loader__load-more tw-mx-auto tw-mt-0 tw-mb-2"
      variant="info"
      data-test="load-more"
      @click="$emit('load-more')"
    >
      {{ loadMoreText }}
    </ui-button>

    <ui-loader
      v-else-if="isLoading"
      :size="30"
      data-test="loader"
      class="ctk-infinite-loader__loader tw-mx-auto tw-my-0 tw-p-2"
      background-back="#000"
      type="pallet"
    />

    <div
      v-else-if="itemsCount"
      class="ctk-infinite-loader__loaded tw-text-center tw-text-gray-700 tw-break-words tw-mx-4 tw-mb-4 tw-text-xs tw-flex-1 tw-whitespace-normal"
      data-test="loaded"
    >
      {{ loadedText }}
    </div>
  </div>
</template>

<script>
  /**
   * Generic component used in the infinite lists, to show either a load more
   * button, the a generic loader or the loaded text.
   * @module component - ctkInfiniteLoader
   * @param {string} loadMoreText - Textual value for the load more button
   * @param {string} loadedText - Loaded message when there is nothing more to load
   * @param {boolean} [isLoading=false] - Specify if we are loading something
   * @param {boolean} [canLoadMore=false] - Specify if we are loading something
   * @param {number} [itemsCount=1] - The number of items currently available in the list
   * @emits load-more
   */
  export default {
    name: 'CtkInfiniteLoader',
    props: {
      loadMoreText: {
        type: String,
        required: true
      },
      loadedText: {
        type: String,
        required: true
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      canLoadMore: {
        type: Boolean,
        default: false
      },
      itemsCount: {
        type: Number,
        default: 1
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-infinite-loader {
    &__loaded {
      min-height: 24px;
      line-height: 24px;
    }
  }

</style>
