import store from '@/store'

/**
 * Check if the authenticated user is a pre-registed user.
 * If it's the case, allow only certain routes to be authorized.
 */
export default (to, from, next) => {
  const isPreRegistered = !!store.getters['auth/isPreRegistered']
  const allowedRoutes = ['Onboarding', 'OnboardingConfirmation']

  if (!isPreRegistered || to.name === 'SignIn') {
    return next()
  }

  if (allowedRoutes.includes(to.name)) {
    next()
  } else {
    next({
      name: 'Onboarding'
    })
  }
}
