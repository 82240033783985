<template>
  <div
    ref="element"
    class="tabs-bar tw-relative tw-flex tw-flex-col tw-justify-center tw-overflow-x-hidden"
  >
    <div class="tw-flex tw-flex-1 tw-w-full tw-border-0 tw-border-b-gray-300 tw-border-b tw-border-solid tw-truncate">
      <tabs-bar-item
        v-for="(tab, index) in tabs"
        :key="index"
        :active="value === index"
        :disabled="tab.active === false"
        :title="tab.label"
        class="tabs-bar__item tw-truncate"
        @click.native="$emit('input', index)"
      />
    </div>

    <div
      :style="indicatorStyle"
      class="tabs-bar__indicator tw-absolute tw-bottom-0 tw-text-center"
    />
  </div>
</template>

<script>
  import { defineComponent, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch } from '@vue/composition-api'
  import ResizeObserver from 'resize-observer-polyfill'

  import TabsBarItem from './_subs/TabsBarItem/index.vue'

  /**
   * @typedef TabItem
   * @type {object}
   * @property {string} label
   * @property {boolean} active
   *
   * @module components - TabsBar
   * @param {Array<TabItem>} tabs
   */
  export default defineComponent({
    name: 'TabsBar',
    components: {
      TabsBarItem
    },
    props: {
      tabs: {
        type: Array,
        required: true
      },
      value: {
        type: Number,
        required: true
      }
    },
    setup (props) {
      const { value } = toRefs(props)

      /** @type {import('@vue/composition-api').Ref<Element|null>} */
      const element = ref(null)
      /** @type {import('@vue/composition-api').Ref<ResizeObserver|null>} */
      const observer = ref(null)
      const indicatorStyle = reactive({
        transform: 'translateX(0px)',
        width: '0px'
      })

      /**
       * @function updateIndicatorStyle
       */
      function updateIndicatorStyle () {
        const tabBar = element.value
        const targetTab = element.value?.querySelectorAll('.tabs-bar__item')[value.value]

        if (targetTab && tabBar) {
          const { left: leftBar } = tabBar.getBoundingClientRect()
          const { left, width } = targetTab.getBoundingClientRect()

          indicatorStyle.transform = `translateX(${left - leftBar}px)`
          indicatorStyle.width = `${width}px`
        }
      }

      onMounted(() => {
        observer.value = new ResizeObserver(() => {
          updateIndicatorStyle()
        })

        Array.from(document.querySelectorAll('.tabs-bar__item')).forEach(item => {
          if (observer.value) observer.value.observe(item)
        })

        setTimeout(updateIndicatorStyle, 200)
      })

      onBeforeUnmount(() => {
        if (observer.value) observer.value.disconnect()
      })

      watch(value, updateIndicatorStyle)

      return {
        element,
        indicatorStyle
      }
    }
  })
</script>

<style lang="scss" scoped>
.tabs-bar {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
  height: 40px;
  flex: 0 0 40px;
}
.tabs-bar__indicator, .tabs-bar__indicator::before {
  height: 3px;
}
.tabs-bar__indicator {
  transition: transform 200ms ease-in-out, width 200ms ease-in-out;
}
.tabs-bar__indicator::before {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 118, 150, var(--tw-bg-opacity));
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  content: '';
}
</style>
