var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[(_vm.value)?_c('aside',{staticClass:"ctk-search-dialog",attrs:{"role":"dialog","aria-describedby":"modal-title"}},[_c('div',{staticClass:"ctk-search-dialog__content tw-flex"},[_c('div',{staticClass:"tw-flex tw-flex-col ctk-search-dialog__content__hero"},[_c('div',{staticClass:"ctk-search-dialog__content__hero__content px-3 pt-3"},[_c('picture',{staticClass:"ctk-search-dialog__content__hero__content__logo"},[_c('source',{attrs:{"srcset":require("@/assets/img/logo-icon-white.webp"),"type":"image/webp"}}),_c('img',{staticClass:"ctk-search-dialog__content__hero__content__logo",attrs:{"src":require("@/assets/img/logo-icon-white.png"),"width":"50","height":"50","alt":""}})]),_c('h1',{staticClass:"tw-font-light",attrs:{"id":"modal-title"}},[_vm._v(" "+_vm._s(_vm.$t('app.titles.search'))+" ")]),_c('p',{staticClass:"fs-14"},[_vm._v(" "+_vm._s(_vm.$t('app.paragraphs.search'))+" ")])])]),_c('div',{staticClass:"ctk-search-dialog__content__form tw-flex tw-flex-col",class:{
          'has-illustration': !_vm.getSearchMeta || _vm.getSearchMeta.item_count === 0,
          'no-results': _vm.getSearchMeta && _vm.getSearchMeta.item_count === 0
        }},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var invalid = ref.invalid;
return [_c('form',{attrs:{"disabled":invalid},on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"rules":"required|min:3","name":_vm.$t('app.fields.search'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var fieldInvalid = ref.invalid;
        var validated = ref.validated;
return [_c('ctk-input-text',{ref:"search",attrs:{"id":"search","label":_vm.$t('app.labels.search'),"loader":_vm.$wait.is('fetching shipments search*'),"error":fieldInvalid && validated,"hint":fieldInvalid ? errors[0] : null,"type":"search","name":"search","clearable":"","required":""},on:{"input":_vm.lazySearch,"clear":_vm.reset},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})]}}],null,true)})],1)]}}],null,false,2507984771)}),(_vm.getSearchMeta)?_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"ctk-search-dialog__content__form__results tw-flex tw-flex-1 tw-flex-col"},[(_vm.getSearchMeta.item_count)?[_c('h2',{staticClass:"ctk-search-dialog__content__form__results__title tw-font-normal"},[_vm._v(" "+_vm._s(_vm.$tc('app.titles.search_results', _vm.getSearchMeta.item_count, { count: _vm.getSearchMeta.item_count }))+" ")]),_vm._l((_vm.getSearchItems),function(shipment){return _c('shipment-search-item',{key:shipment.uuid,staticClass:"mb-3",attrs:{"shipment":shipment},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}})}),_c('ctk-infinite-loader',{attrs:{"load-more-text":_vm.$t('app.buttons.load_more_searches'),"loaded-text":_vm.$t('app.paragraphs.searches_loaded', {
                query: this.searchedQuery
              }),"is-loading":_vm.$wait.is('fetching more shipments search'),"can-load-more":_vm.canLoadMore,"items-count":_vm.getSearchMeta.item_count},on:{"load-more":_vm.loadMore}})]:[_c('p',{staticClass:"ctk-search-dialog__content__form__results__not-found tw-text-center"},[_vm._v(" "+_vm._s(_vm.$t('app.paragraphs.search_not_found', { query: this.searchedQuery }))+" ")])]],2):_vm._e()],1)]),_c('button',{staticClass:"tw-flex btn ctk-search-dialog__close",attrs:{"type":"button","title":_vm._f("capitalize")(_vm.$t('close'))},on:{"click":_vm.close}},[_c('ui-ctk-icon',{attrs:{"name":"close"}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }