import actions from './actions'
import mutations from './mutations'
import state from './state'
import getters from './getters'

import register from './modules/register'

export default {
  getters,
  state,
  mutations,
  actions,
  modules: {
    register,
    namespaced: true
  },
  namespaced: true
}
