var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"summary-offer tw-flex tw-flex-col tw-h-full"},[_c('div',{staticClass:"tw-mb-6"},[_c('h1',{staticClass:"tw-text-xl tw-font-normal tw-mb-1",domProps:{"textContent":_vm._s(_vm.$t('offer.booking_dialog.summary_offer.title'))}}),_c('p',{staticClass:"tw-mb-0 tw-text-secondary-text",domProps:{"textContent":_vm._s(_vm.content)}})]),_c('div',{staticClass:"md:tw-overflow-y-auto md:tw-max-h-96 tw-flex-1"},[(_vm.offer.pickup.address.accessibility && _vm.offer.pickup.address.accessibility.vehicle_type.key !== 'standard_trailer')?_c('accessibility-item',{staticClass:"tw-mb-4",attrs:{"title":_vm.$t('offers.titles.accessibility.pickup'),"content":_vm.offer.pickup.address.accessibility.vehicle_type.key === 'lightweight_vehicle'
        ? _vm.$t('offers.values.accessibility.lightweight_vehicle')
        : _vm.$t('offers.values.accessibility.carrier_truck')}}):_vm._e(),(_vm.offer.delivery.address.accessibility && _vm.offer.delivery.address.accessibility.vehicle_type.key !== 'standard_trailer')?_c('accessibility-item',{staticClass:"tw-mb-4",attrs:{"title":_vm.$t('offers.titles.accessibility.delivery'),"content":_vm.offer.delivery.address.accessibility.vehicle_type.key === 'lightweight_vehicle'
        ? _vm.$t('offers.values.accessibility.lightweight_vehicle')
        : _vm.$t('offers.values.accessibility.carrier_truck')}}):_vm._e(),(_vm.pickupAccessibility && !(_vm.offer.pickup.address.accessibility && _vm.offer.pickup.address.accessibility.vehicle_type.key !== 'standard_trailer'))?_c('accessibility-item',{staticClass:"tw-mb-4",attrs:{"title":_vm.$t('offers.titles.accessibility.pickup'),"content":_vm.pickupAccessibility.comment}}):_vm._e(),(_vm.deliveryAccessibility && !(_vm.offer.delivery.address.accessibility && _vm.offer.delivery.address.accessibility.vehicle_type.key !== 'standard_trailer'))?_c('accessibility-item',{staticClass:"tw-mb-4",attrs:{"title":_vm.$t('offers.titles.accessibility.delivery'),"content":_vm.deliveryAccessibility.comment}}):_vm._e(),(_vm.offer.pickup.comment)?_c('specificity-item',{attrs:{"icon":'comment',"title":_vm.$t('app.labels.comment'),"direction":'pickup',"content":_vm.offer.pickup.comment}}):_vm._e(),(_vm.offer.delivery.comment)?_c('specificity-item',{attrs:{"icon":'comment',"title":_vm.$t('app.labels.comment'),"direction":'delivery',"content":_vm.offer.delivery.comment}}):_vm._e(),(_vm.offer.pickup.handling.tail_lift || _vm.offer.delivery.handling.tail_lift)?_c('specificity-item',{attrs:{"icon":'hatch',"title":_vm.$t('app.labels.vehicle_options'),"direction":_vm.offer.pickup.handling.tail_lift && _vm.offer.delivery.handling.tail_lift
        ? 'both'
        : _vm.offer.pickup.handling.tail_lift
          ? 'pickup' : 'delivery',"content":_vm.$t('app.values.vehicle_tail_lift')}}):_vm._e(),(_vm.offer.pickup.handling.driver || _vm.offer.delivery.handling.driver)?_c('specificity-item',{attrs:{"icon":'logistic',"title":_vm.$t('app.labels.handling'),"direction":_vm.offer.pickup.handling.driver && _vm.offer.delivery.handling.driver
        ? 'both'
        : _vm.offer.pickup.handling.driver
          ? 'pickup' : 'delivery',"content":_vm.$t('handling_by_the_driver')}}):_vm._e(),(_vm.offer.load.description)?_c('specificity-item',{attrs:{"icon":'comment',"title":_vm.$t('app.labels.comment'),"direction":'load',"content":_vm.offer.load.description}}):_vm._e(),(_vm.adr)?_c('specificity-item',{attrs:{"icon":'skull',"title":_vm.$t('app.labels.adr_load'),"direction":null,"content":_vm.adr.comment}}):_vm._e(),(_vm.adjustment)?_c('specificity-item',{attrs:{"icon":'adjust',"title":_vm.$t('app.labels.adjustment'),"direction":null,"content":_vm.adjustment.comment}}):_vm._e(),(_vm.hasDates)?_c('specificity-item',{attrs:{"icon":'scheduled',"title":_vm.$t('app.labels.date_hour'),"direction":_vm.hasPickupDates && _vm.hasDeliveryDates
        ? 'both'
        : _vm.hasPickupDates
          ? 'pickup'
          : 'delivery'},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ul',{staticClass:"tw-flex tw-flex-col tw-list-none tw-m-0 tw-p-0"},[(_vm.hasPickupDates)?_c('li',{domProps:{"textContent":_vm._s(_vm.pickupDate)}}):_vm._e(),(_vm.hasDeliveryDates)?_c('li',{domProps:{"textContent":_vm._s(_vm.deliveryDate)}}):_vm._e()])]},proxy:true}],null,false,1656941539)}):_vm._e(),(!!_vm.pickupRdv)?_c('specificity-item',{attrs:{"icon":'scheduled',"title":_vm.$t('app.labels.rdv_option.pickup'),"direction":'pickup',"content":_vm.pickupRdv}}):_vm._e(),(!!_vm.deliveryRdv)?_c('specificity-item',{attrs:{"icon":'scheduled',"title":_vm.$t('app.labels.rdv_option.delivery'),"direction":'delivery',"content":_vm.deliveryRdv}}):_vm._e(),(_vm.pickupAdditionalStep)?_c('specificity-item',{attrs:{"icon":'destination',"title":_vm.$t('app.labels.additional_step.pickup'),"direction":'pickup',"content":_vm.pickupAdditionalStep.comment}}):_vm._e(),(_vm.deliveryAdditionalStep)?_c('specificity-item',{attrs:{"icon":'destination',"title":_vm.$t('app.labels.additional_step.delivery'),"direction":'delivery',"content":_vm.deliveryAdditionalStep.comment}}):_vm._e()],1),_c('div',{staticClass:"summary-offer__footer tw-relative tw-w-full tw-flex tw-flex-col tw-items-center tw-py-4 md:tw-pb-0 tw-mt-4 md:tw-mt-0"},[_c('b-form-checkbox',{staticClass:"tw-mb-6",attrs:{"name":"terms-of-service","required":""},model:{value:(_vm.formData.tos),callback:function ($$v) {_vm.$set(_vm.formData, "tos", $$v)},expression:"formData.tos"}},[_vm._v(" "+_vm._s(_vm.$t('app.paragraphs.tos'))+" ")]),_c('div',{staticClass:"tw-flex tw-flex-col-reverse md:tw-flex-row tw-items-center tw-w-full",class:{
        'tw-justify-center': !_vm.hasBackButton,
        'tw-justify-between': _vm.hasBackButton
      }},[(_vm.hasBackButton)?_c('ui-button',{staticClass:"tw-w-full md:tw-w-auto tw-mt-4 md:tw-mt-0 tw-w-full md:tw-w-auto",attrs:{"variant":"link"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('back')))+" ")]):_vm._e(),_c('ui-button',{staticClass:"tw-px-4 tw-w-full md:tw-w-auto",attrs:{"disabled":!_vm.formData.tos,"variant":"primary"},on:{"click":function($event){return _vm.$emit('next')}}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }