import Vue from 'vue'

const ctx = '@@Matomo'

function trackEvent (el, expression) {
  if (el[ctx]) {
    const { category, action, name, value } = expression
    if (!category || !action) {
      throw new Error('Either the "category" or the "action" was not specified in the Matomo tracking.')
    }

    const matomo = Vue.prototype.$matomo
    if (matomo) {
      matomo.trackEvent(category, action, name, value)
    }
  }
}

function clickEvent (e, expression) {
  trackEvent(e.target, expression)
}

function handleClick (eventBindings, el, vNode) {
  if (!vNode) {
    el[ctx].el.addEventListener('click', () => trackEvent(el[ctx].el, eventBindings))
    return
  }

  /**
   * Check if the "native" property is specified to false.
   * If it does, that means we're not expecting a native click event
   * but instead a Vue event from the componentInstance. Ignore the other listeners.
   */
  if (eventBindings.native === false) {
    vNode.componentInstance.$on('click', () => trackEvent(el, eventBindings))
    return
  }

  if (!vNode.elm) {
    if (vNode.componentInstance.$el) {
      vNode.componentInstance.$el.addEventListener('click', () => trackEvent(vNode.componentInstance.$el, eventBindings), {
        capture: true
      })
    } else {
      vNode.componentInstance.$on('click', () => trackEvent(el, eventBindings))
    }

    return
  }

  if (vNode.elm) {
    vNode.elm.addEventListener('click', () => {
      trackEvent(vNode.elm, eventBindings)
    }, {
      capture: true
    })
  }
}

function handleInsert (eventBindings, el) {
  trackEvent(el, eventBindings)
}

Vue.directive('matomo', {
  bind (el, bindings) {
    el[ctx] = {
      el,
      expression: bindings.value
    }
  },
  inserted (el, bindings, vNode) {
    const { click, insert } = el[ctx].expression

    if (click) handleClick(click, el, vNode)
    if (insert) handleInsert(insert, el, vNode)
  },
  unbind (el, bindings, vNode) {
    if (vNode && vNode.componentInstance) {
      vNode.componentInstance.$off('click')
    }

    if (el[ctx]) {
      el[ctx].el.removeEventListener('click', clickEvent)

      delete el[ctx]
    }
  }
})
