<template>
  <ctk-alert-banner
    :title="$t('app.paragraphs.unpaid_invoices')"
    class="ctk-unpaid-invoices"
    variant="warning"
    @click.native="pay"
  >
    <p class="tw-mb-0">
      <span
        class="ctk-unpaid-invoices__markdown"
        v-markdown="$tc('app.paragraphs.unpaid_invoices.content', getInvoicesMetrics.counts.payment_late, {
          invoices_count: $n(getInvoicesMetrics.counts.payment_late),
          amount: $t('price_incl_tax', {
            price: $options.filters.currency(getInvoicesMetrics.amounts.payment_late, 'EUR', $i18n.locale)
          })
        })"
        data-test="inner-content"
      />
      <span
        v-text="$t('app.paragraphs.unpaid_invoices.pay')"
        class="tw-font-medium tw-italic tw-underline tw-ml-1"
        data-test="inner-link"
      />
    </p>
  </ctk-alert-banner>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Hotjar from '@/plugins/VueHotjar'

  import CtkAlertBanner from '@/components/CtkAlertBanners/_subs/CtkAlertBanner/index.vue'

  /**
   * @module component - CtkUnpaidInvoices
   */
  export default {
    name: 'CtkUnpaidInvoices',
    components: {
      CtkAlertBanner
    },
    computed: {
      ...mapGetters('auth', [
        'isAccountLocked'
      ]),
      ...mapGetters('billing', [
        'getInvoicesMetrics'
      ])
    },
    mounted () {
      Hotjar.tag('Defaulting Shipper')
    },
    methods: {
      pay () {
        this.$router.push({
          name: 'Invoices',
          query: {
            selectAll: 1
          }
        })
          .catch(() => {})
      }
    }
  }
</script>

<style lang="scss">

  .ctk-unpaid-invoices {
    &__markdown p {
      display: inline;
      margin: 0;
    }
  }

</style>
