// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class EstimationRequestResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}/estimation-requests'
  }
}
