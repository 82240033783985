// @ts-nocheck
import moment from 'moment'

import {
  parsePhoneNumberFromString
} from 'libphonenumber-js'
import { i18n } from '@/locales'
import { Company, Address, Quotation, Shipment } from '@/resources'
import { AUTHORIZED_DEFAULT_PALLET_FORMATS } from '@/composables/constants'

export default {
  setAddress ({ commit }, payload) {
    commit('SET_ADDRESS', payload)
  },
  setContact ({ commit }, payload) {
    commit('SET_CONTACT', payload)
  },
  setComment ({ commit }, payload) {
    commit('SET_COMMENT', payload)
  },
  setMeeting ({ commit }, payload) {
    commit('SET_MEETING', payload)
  },
  setDate ({ commit }, payload) {
    commit('SET_DATE', payload)
  },
  setDateFlexible ({ commit }, payload) {
    commit('SET_DATE_FLEXIBLE', payload)
  },
  setSelectedDateType ({ commit }, type) {
    commit('SET_SELECTED_DATE_TYPE', type)
  },
  setTimeslot ({ commit }, payload) {
    commit('SET_TIME_SLOT', payload)
  },
  setGoods ({ commit }, payload) {
    commit('SET_GOODS', payload)
  },
  setQuotation ({ commit }, payload) {
    commit('SET_QUOTATION', payload)
  },
  setQuotationSelectedPrice ({ commit }, price) {
    commit('SET_QUOTATION_SELECTED_PRICE', price)
  },
  setQuotationValidated ({ commit }, v = true) {
    commit('SET_QUOTATION_VALIDATED', v)
  },
  setHandlingDriver ({ commit }, payload) {
    commit('SET_HANDLING_DRIVER', payload)
  },
  setHandlingTailLift ({ commit }, payload) {
    commit('SET_HANDLING_TAIL_LIFT', payload)
  },
  setGuard ({ commit }, payload) {
    commit('SET_GUARD', payload)
  },
  setExpirationDate ({ commit }, date) {
    commit('SET_EXPIRATION_DATE', date)
  },
  setShipperReference ({ commit }, reference) {
    commit('SET_SHIPPER_REFERENCE', reference)
  },
  setConfirmationStep ({ commit }, value) {
    commit('SET_CONFIRMATION_STEP', value)
  },
  setPhoneNumber ({ commit }, phone) {
    commit('SET_PHONE_NUMBER', phone)
  },
  retrieveShipments ({ rootGetters, commit }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      let shipments = []
      let page = 1
      function reduceShipmentsList () {
        return shipments
          .reduce((unique, item) => {
            const hasShipment = unique
              .findIndex(shipment => {
                const { pickup, delivery, load } = shipment
                const { pickup: itemPickup, delivery: itemDelivery, load: itemLoad } = item

                /**
                 * This is dirty and resource consuming but do the job for the moment.
                 * TODO: Find a better way to compare those informations
                 */
                const samePickupAddress = JSON.stringify(pickup.address) === JSON.stringify(itemPickup.address)
                const samePickupHandling = JSON.stringify(pickup.handling) === JSON.stringify(itemPickup.handling)
                const sameDeliveryAddress = JSON.stringify(delivery.address) === JSON.stringify(itemDelivery.address)
                const sameDeliveryHandling = JSON.stringify(delivery.handling) === JSON.stringify(itemDelivery.handling)
                const sameLoad = JSON.stringify(load) === JSON.stringify(itemLoad)
                return samePickupAddress && samePickupHandling && sameDeliveryAddress && sameDeliveryHandling && sameLoad
              })

            return hasShipment !== -1 ? unique : [...unique, item]
          }, [])
          .slice(0, 20)
      }

      function fetchShipment () {
        return Shipment.get({
          cid: rootGetters['auth/getCid']
        }, {
          params: {
            limit: 20,
            page
          }
        })
          .then(async ({ data }) => {
            shipments = [
              ...shipments,
              ...data.items
            ]

            shipments = reduceShipmentsList()

            const pagination = data.meta.pagination
            if (shipments.length < 20 && pagination.current_page < pagination.page_count) {
              page += 1
              await fetchShipment()
            }
          })
      }

      await fetchShipment()
      commit('SET_FETCHED_SHIPMENTS', shipments)
      resolve(shipments)
    })
  },
  requestQuotation ({ rootGetters, getters, dispatch }) {
    /**
     * Build the quotation body
     *
     * TODO: Trim values globally in every axios request.
     * This is a quickfix.
     */
    const pickup = JSON.parse(JSON.stringify({
      address: getters.getPickupAddress,
      comment: (getters.getPickupComment && getters.getPickupComment.trim()) || null,
      meeting: (getters.getPickupMeeting && getters.getPickupMeeting.trim()) || null,
      contact: getters.getPickupContact,
      time_slot: {
        date: getters.getPickupDate,
        start_time: moment()
          .startOf('day')
          .minutes(getters.getPickupTimeslot[0])
          .format('HH:mm'),
        end_time: moment()
          .startOf('day')
          .minutes(getters.getPickupTimeslot[1])
          .format('HH:mm'),
        flexible: getters.isPickupFlexible
      },
      handling: {
        driver: getters.getPickupHandlingDriver === 'carrier',
        tail_lift: getters.getPickupHandlingTailLift === 'yes'
      }
    }))

    const delivery = JSON.parse(JSON.stringify({
      address: getters.getDeliveryAddress,
      comment: (getters.getDeliveryComment && getters.getDeliveryComment.trim()) || null,
      meeting: (getters.getDeliveryMeeting && getters.getDeliveryMeeting.trim()) || null,
      contact: getters.getDeliveryContact,
      time_slot: {
        date: getters.getDeliveryDate,
        start_time: moment()
          .startOf('day')
          .minutes(getters.getDeliveryTimeslot[0])
          .format('HH:mm'),
        end_time: moment()
          .startOf('day')
          .minutes(getters.getDeliveryTimeslot[1])
          .format('HH:mm'),
        flexible: getters.isDeliveryFlexible
      },
      handling: {
        driver: getters.getDeliveryHandlingDriver === 'carrier',
        tail_lift: getters.getDeliveryHandlingTailLift === 'yes'
      }
    }))

    /**
     * Re-parse the pickup/delivery contact infos
     */
    for (const direction of ['pickup', 'delivery']) {
      const dir = direction === 'pickup' ? pickup : delivery
      const { contact } = dir

      if (contact) {
        if (contact.phone && contact.phone.number) {
          const parsedPhone = parsePhoneNumberFromString(contact.phone.number, contact.phone.country)
          contact.phone = parsedPhone
            ? parsedPhone.number
            : contact.phone.number
        } else if (typeof contact.phone !== 'string') {
          contact.phone = null
        }

        contact.name = (contact.name && contact.name.trim()) || null
      }
    }

    const {
      type: loadType,
      height,
      weight,
      description,
      pallets
    } = getters.getLoad

    let { quantity, width, length } = getters.getLoad
    let type = loadType
    let format = null
    let formattedDescription = description

    if (loadType === 'pallets') {
      /**
       * Handle the case where all the pallets specified are the same and have the "standard"
       * format.
       */
      const haveSameFormat = pallets
        .every(pallet => pallet.format === pallets[0].format)
      const haveStandardFormat = pallets
        .every(pallet => Object.keys(AUTHORIZED_DEFAULT_PALLET_FORMATS).includes(pallet.format))
      const palletsQuantity = pallets
        .map(pallet => parseInt(pallet.quantity, 10))
        .reduce((a, b) => a + b, 0)

      if (haveSameFormat && haveStandardFormat) {
        type = 'pallets'
        format = pallets[0].format
        quantity = palletsQuantity

        const tempFormat = AUTHORIZED_DEFAULT_PALLET_FORMATS[pallets[0].format]
        width = tempFormat.width
        length = tempFormat.length
      } else {
        type = 'custom'

        /**
         * Handle special case if there is only one pallet (60x40)
         * because the length & width sent to the API is incorrect.
         * If there are more than one pallet of those formats, fallback to the
         * default calculus since it's not handled.
         *
         * See https://trello.com/c/EGKzKv3K/194-traitement-des-multi-pallettes-en-moins-de-1-mpl
         * for more context.
         */
        length = getters.getPalletsMpl * 100
        width = 240
        quantity = 1

        if (palletsQuantity === 1) {
          /**
           * If there is only a single pallet, use the width and length of that pallet
           * to avoid possibly wrong calculus, since we consider that the user wants
           * that exact pallet format.
           */
          length = pallets[0].length
          width = pallets[0].width
        }

        /**
         * Format the description to include the details of each pallet
         */
        const formattedPallets = pallets
          .map(({ quantity, length, width }) => `- ${i18n.tc('app.values.pallet', quantity, {
            count: quantity
          })} ${width}x${length}`)

        const newLineChar = String.fromCharCode(10)
        const descriptionComponents = [
          i18n.t('new-shipment.labels.pallet_details'),
          ...formattedPallets
        ]

        formattedDescription = descriptionComponents
          .join(newLineChar)

        /**
         * TODO: Trim values globally in every axios request.
         * This is a quickfix.
         */
        formattedDescription = [
          formattedDescription,
          newLineChar,
          newLineChar,
          description
        ].join('')
      }
    } else {
      // Case for "bulk" type
      width = getters.getLoad.width
      length = getters.getLoad.length
      quantity = 1
    }

    const load = {
      type,
      format,
      quantity: parseInt(quantity, 10),
      length: parseInt(length, 10),
      height: parseInt(height, 10),
      width: parseInt(width, 10),
      weight: parseInt(weight, 10),
      description: (formattedDescription && formattedDescription.trim()) || null
    }

    return Quotation.save(
      {
        cid: rootGetters['auth/getCid']
      },
      {
        pickup,
        delivery,
        load
      }
    ).then(res => {
      dispatch('setQuotation', res.data)
      dispatch('setQuotationSelectedPrice', null)
      dispatch('setQuotationValidated', false)
      return res
    })
  },
  retrieveAddresses ({ rootGetters, commit }, { direction }) {
    return Address.get({
      cid: rootGetters['auth/getCid']
    }, {
      params: {
        type: direction,
        limit: 30
      }
    }).then(res => {
      commit('SET_FETCHED_ADDRESSES', {
        direction,
        addresses: res.data.items
      })
      return res
    })
  },
  retrieveAddressesDistance ({ rootGetters, getters, commit }) {
    return Address.distance({
      cid: rootGetters['auth/getCid']
    }, {
      pickup_address: getters.getPickupAddress,
      delivery_address: getters.getDeliveryAddress
    })
      .then(({ data }) => {
        commit('SET_ADDRESSES_DISTANCE', Math.round(data.distance / 1000))
      })
  },
  retrievePhoneNumber ({ dispatch, rootGetters }) {
    return Company.phones({
      cid: rootGetters['auth/getCid']
    })
      .then(res => {
        const { items } = res.data
        const phoneNumber = items.length > 0
          ? items[0].phone
          : null

        dispatch('setPhoneNumber', phoneNumber)

        return res
      })
  },
  resetForm ({ commit }) {
    commit('RESET_FORM')
  },
  setPallets ({ commit }, pallets) {
    commit('SET_PALLETS', pallets)
  },
  addPallet ({ commit }) {
    commit('ADD_PALLET')
  },
  removePallet ({ commit, getters }, index) {
    if (getters.getPallets.length === 1) return false

    commit('REMOVE_PALLET', index)
  },
  setPalletValue ({ commit }, pallet) {
    commit('SET_PALLET_VALUE', pallet)
  }
}
