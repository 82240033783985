<script>
  import { defineComponent } from '@vue/composition-api'

  const ALLOWED_SIZES = ['sm', 'md']
  const ALLOWED_COLORS = ['green', 'blue', 'orange', 'cyan', 'purple', 'teal', 'red', 'pink']

  /**
   * @module component - UIBadge
   */
  export default defineComponent({
    name: 'UiBadge',
    props: {
      color: {
        type: String,
        default: 'green',
        validator: (/** @type {string} */ value) => ALLOWED_COLORS.includes(value)
      },
      size: {
        type: String,
        default: 'md',
        validator: (/** @type {string} */ value) => ALLOWED_SIZES.includes(value)
      },
      neutral: {
        type: Boolean,
        default: false
      }
    },
    render (createElement) {
      const _this = this

      /**
       * @function hasSlot
       * @param {string} slotName
       * @returns {boolean}
       */
      function hasSlot (slotName) {
        // @ts-ignore
        return _this.$slots[slotName] && !!_this.$slots[slotName]
      }

      const hasContent = hasSlot('default')
      const hasLeftIcon = hasSlot('left-icon')

      const classes = []

      if (this.size) classes.push(`ui-badge--${this.size}`)
      if (this.neutral) classes.push('ui-badge--neutral')
      if (this.color) classes.push(`ui-badge--${this.color}`)
      if (hasLeftIcon) classes.push('ui-badge--left-icon')
      if (hasContent) classes.push('ui-badge--content')

      const content = createElement('div', {
        staticClass: 'ui-badge__content'
      }, [
        ...(this.$slots.default ? this.$slots.default : [])
      ])

      return createElement('div', {
        class: classes,
        staticClass: 'ui-badge',
        attrs: {
          ...this.$attrs
        }
      }, [
        hasLeftIcon && this.$slots['left-icon'],
        content
      ])
    }
  })
</script>

<style lang="scss" scoped>
.ui-badge {
  border-radius: 9999px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-weight: 500;
  line-height: 1.5;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ui-badge__content {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ui-badge--md {
  font-size: 0.875rem;
  height: 1.375rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.ui-badge--sm {
  font-size: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  height: 1.125rem;
}
.ui-badge--neutral {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
}
.ui-badge--neutral.ui-badge--green {
  --tw-text-opacity: 1;
  color: rgba(150, 191, 49, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--blue {
  --tw-text-opacity: 1;
  color: rgba(40, 118, 150, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--orange {
  --tw-text-opacity: 1;
  color: rgba(255, 163, 0, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--cyan {
  --tw-text-opacity: 1;
  color: rgba(46, 188, 232, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--teal {
  --tw-text-opacity: 1;
  color: rgba(106, 207, 181, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--purple {
  --tw-text-opacity: 1;
  color: rgba(132, 67, 185, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--red {
  --tw-text-opacity: 1;
  color: rgba(215, 40, 63, var(--tw-text-opacity));
}
.ui-badge--neutral.ui-badge--pink {
  --tw-text-opacity: 1;
  color: rgba(239, 204, 209, var(--tw-text-opacity));
}
.ui-badge:not(.ui-badge--neutral).ui-badge {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.ui-badge:not(.ui-badge--neutral).ui-badge--green {
  --tw-bg-opacity: 1;
  background-color: rgba(150, 191, 49, var(--tw-bg-opacity));
}
.ui-badge:not(.ui-badge--neutral).ui-badge--blue {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 118, 150, var(--tw-bg-opacity));
}
.ui-badge:not(.ui-badge--neutral).ui-badge--orange {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 163, 0, var(--tw-bg-opacity));
}
.ui-badge:not(.ui-badge--neutral).ui-badge--cyan {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 188, 232, var(--tw-bg-opacity));
}
.ui-badge:not(.ui-badge--neutral).ui-badge--teal {
  --tw-bg-opacity: 1;
  background-color: rgba(106, 207, 181, var(--tw-bg-opacity));
}
.ui-badge:not(.ui-badge--neutral).ui-badge--purple {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 67, 185, var(--tw-bg-opacity));
}
.ui-badge:not(.ui-badge--neutral).ui-badge--red {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 40, 63, var(--tw-bg-opacity));
}
.ui-badge:not(.ui-badge--neutral).ui-badge--pink {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 204, 209, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
}
.ui-badge .ctk-font::before {
  position: relative;
}
.ui-badge.ui-badge--sm .ctk-font, .ui-badge.ui-badge--sm .material-icons {
  display: none;
}
.ui-badge.ui-badge--md .ctk-font {
  width: 12px;
  height: 12px;
}
.ui-badge.ui-badge--md .material-icons {
  font-size: 12px;
}
.ui-badge.ui-badge--md .ctk-font::before {
  top: -12px;
  margin-left: -6px;
  font-size: 24px;
}
.ui-badge--left-icon.ui-badge--content .ui-icon:first-child {
  margin-right: 0.5rem;
}
</style>
