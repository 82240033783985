<template>
  <header class="app-header tw-w-full">
    <ctk-impersonate-nav-bar
      v-if="isImpersonateVisible"
      data-test="impersonate"
    />
    <ctk-nav-bar
      v-if="hasNavbar"
      data-test="navbar"
    />
    <ctk-alert-banners
      v-if="hasNavbar"
      data-test="alert-banners"
    />
  </header>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CtkAlertBanners from '@/components/CtkAlertBanners/index.vue'
  import CtkImpersonateNavBar from '@/components/CtkImpersonateNavBar/index.vue'
  import CtkNavBar from '@/components/CtkNavBar/index.vue'

  /**
   * @module component - AppHeader
   */
  export default {
    name: 'AppHeader',
    components: {
      CtkAlertBanners,
      CtkImpersonateNavBar,
      CtkNavBar
    },
    props: {
      hasNavbar: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      ...mapGetters('ui', [
        'isImpersonateVisible'
      ])
    }
  }
</script>

<style lang="scss" scoped>

  .app-header {
    z-index: 100;
  }

</style>
