var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui-loader-pallet tw-relative tw-flex tw-justify-center tw-items-center",style:({
    width: (_vm.size + "px"),
    height: (_vm.size + "px")
  })},[_c('div',{staticClass:"ui-loader-pallet__group tw-flex tw-justify-around tw-w-full tw-h-full tw-absolute ui-loader-pallet__group__back"},_vm._l((3),function(index){return _c('div',{key:index,staticClass:"ui-loader-pallet__group__branch",style:({
        backgroundColor: _vm.backgroundBack
      })})}),0),_c('div',{staticClass:"ui-loader-pallet__group tw-flex tw-justify-between tw-w-full tw-h-full tw-absolute ui-loader-pallet__group__front"},_vm._l((4),function(index){return _c('div',{key:index,staticClass:"ui-loader-pallet__group__branch",style:({
        backgroundColor: _vm.backgroundFront
      })})}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }