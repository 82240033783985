import actions from './actions'
import mutations from './mutations'
import state from './state'
import getters from './getters'

import newShipment from './modules/new-shipment'

export default {
  getters,
  state,
  mutations,
  actions,
  modules: {
    'new-shipment': newShipment,
    namespaced: true
  },
  namespaced: true
}
