<template>
  <ctk-alert-banner
    :title="$t('app.paragraphs.account_locked')"
    class="ctk-locked-account"
    variant="danger"
    @click.native="showDialog"
  >
    <p class="tw-mb-0">
      <span
        v-text="$t('app.paragraphs.account_locked.locked')"
        data-test="inner-content"
      />
      <span
        v-text="$t('app.paragraphs.account_locked.contact_us')"
        class="tw-font-medium tw-italic tw-underline tw-ml-1"
        data-test="inner-link"
      />
    </p>
  </ctk-alert-banner>
</template>

<script>
  import { EventBus } from '@/services/EventBus'

  import CtkAlertBanner from '@/components/CtkAlertBanners/_subs/CtkAlertBanner/index.vue'

  /**
   * @module component - CtkLockedAccount
   */
  export default {
    name: 'CtkLockedAccount',
    components: {
      CtkAlertBanner
    },
    methods: {
      showDialog () {
        EventBus.$emit('dialogs:account-locked')
      }
    }
  }
</script>
