// @ts-nocheck
import { defaultDirection, defaultLoad } from './state'

export default {
  /**
   * Returns true if the user has made any change on the form compared to the
   * original data.
   * @function hasChanged
   */
  hasChanged (state) {
    const hasPickupChanged = JSON.stringify(state.pickup) !== JSON.stringify(defaultDirection)
    const hasDeliveryChanged = JSON.stringify(state.delivery) !== JSON.stringify(defaultDirection)
    const hasLoadChanged = JSON.stringify(state.load) !== JSON.stringify(defaultLoad)

    return hasPickupChanged || hasDeliveryChanged || hasLoadChanged
  },
  getSelectedDateType: state => state.selectedDateType,
  getAddressesDistance: state => state.addressesDistance,
  isConfirmationStep: state => state.isConfirmation,
  getQuotation: state => state.quotation,
  getQuotationSelectedPrice: state => state.selectedPrice,
  /**
   * @function isSelectedPriceNetwork
   * @returns {boolean}
   */
  isSelectedPriceNetwork: (state, getters) => getters.getQuotationSelectedPrice && getters.getQuotationSelectedPrice.type === 'pallet_network',
  isSelectedPriceCreditCard: (state, getters) => getters.getQuotationSelectedPrice && getters.getQuotationSelectedPrice.payment_plans.includes('immediate_cc'),
  isSelectedPriceCCAuthorization: (state, getters) => getters.getQuotationSelectedPrice && getters.getQuotationSelectedPrice.payment_plans.includes('immediate_cc_authorization'),
  isSelectedPriceThirtyDaysInvoice: (state, getters) => getters.getQuotationSelectedPrice && getters.getQuotationSelectedPrice.payment_plans.includes('thirty_days_invoice'),
  getPickupAddress (state) {
    return state.pickup.address
  },
  getPickupComment (state) {
    return state.pickup.comment
  },
  getPickupContact (state) {
    return state.pickup.contact
  },
  getDeliveryAddress (state) {
    return state.delivery.address
  },
  getDeliveryComment (state) {
    return state.delivery.comment
  },
  getPickupMeeting: state => state.pickup.meeting,
  getDeliveryMeeting: state => state.delivery.meeting,
  getDeliveryContact (state) {
    return state.delivery.contact
  },
  getPickupDate (state) {
    return state.pickup.timeslot.date
  },
  getDeliveryDate (state) {
    return state.delivery.timeslot.date
  },
  isPickupFlexible (state) {
    return state.pickup.timeslot.flexible
  },
  isDeliveryFlexible (state) {
    return state.delivery.timeslot.flexible
  },
  getPickupHandlingDriver (state) {
    return state.pickup.handling.driver
  },
  getDeliveryHandlingDriver (state) {
    return state.delivery.handling.driver
  },
  getPickupHandlingTailLift (state) {
    return state.pickup.handling.tail_lift
  },
  getDeliveryHandlingTailLift (state) {
    return state.delivery.handling.tail_lift
  },
  getLoad (state) {
    const load = Object.assign({}, state.load)

    /**
     * If the load does not have a quantity because of the multipallet,
     * makes the sum of all the pallets's quantities and returns it
     */
    let quantity
    if (load.quantity !== null && load.pallets && load.pallets.length > 0) {
      quantity = load.pallets
        .map(pallet => parseInt(pallet.quantity, 10))
        .reduce((a, b) => a + b, 0)
    }

    load.quantity = quantity
    return load
  },
  getPickupTimeslot (state) {
    return [state.pickup.timeslot.start_time, state.pickup.timeslot.end_time]
  },
  getDeliveryTimeslot (state) {
    return [
      state.delivery.timeslot.start_time,
      state.delivery.timeslot.end_time
    ]
  },
  getFetchedAddresses: state => state.fetchedAddresses,
  getFetchedShipments: state => state.fetchedShipments,
  isAddressesCompleted (state, getters) {
    return !!getters.getPickupAddress &&
      !!getters.getDeliveryAddress &&
      state.guards.pickup_address &&
      state.guards.delivery_address
  },
  isDatesCompleted (state, getters) {
    return (
      getters.isAddressesCompleted &&
      !!getters.getPickupDate &&
      !!getters.getDeliveryDate &&
      state.guards.dates
    )
  },
  isHandlingsCompleted (state, getters) {
    const driver =
      !!getters.getPickupHandlingDriver && !!getters.getDeliveryHandlingDriver

    return driver &&
      state.guards.handling
  },
  isGoodsCompleted (state, getters) {
    const { type, height, weight } = getters.getLoad
    return type && height && weight && state.guards.goods
  },
  isQuotationCompleted (state, getters) {
    return !!getters.getQuotation && state.quotationValidated && state.guards.dates
  },
  getGuard (state) {
    return state.guards
  },
  getPhoneNumber: state => state.phoneNumber,
  getExpirationDate: state => state.expirationDate,
  getShipperReference: state => state.shipperReference,
  getPallets (state) {
    return state.pallets
      .map(v => {
        /**
         * Since we're using the data from the shipment load (from the API),
         * a pallet may not have a "width" / "length" keys but a "format" instead.
         * Split the "format" key to have both width/length values
         */
        if (v.format && v.format !== 'other' && !v.length && !v.width) {
          const [width, length] = v.format.split('x')

          return { ...v, width, length }
        } else {
          return v
        }
      })
  },
  /**
   * Returns the sum of mpl from the pallets formats, null otherwise if cannot
   * be calculated.
   * @function getPalletsMpl
   * @returns {Number|null}
   */
  getPalletsMpl (state, getters) {
    if (getters.getPallets.length === 0) return null

    const mpl = getters.getPallets
      .filter(v => !!v.length && !!v.width && !!v.quantity)
      .reduce((a, b) => {
        const mpl = (parseInt(b.length, 10) === 114 && parseInt(b.width, 10) === 114) ? 0.6 : (parseInt(b.length, 10) * parseInt(b.width, 10)) / 24000
        const roundedMpl = Math.ceil(mpl * parseInt(b.quantity, 10) * 100) / 100
        return a + roundedMpl
      }, null)

    return mpl
      ? mpl.toFixed(1)
      : null
  }
}
