<template>
  <b-modal
    v-model="open"
    :title="$t('hide_offer')"
    class="dialog-hide-offer"
    modal-class="dialog-hide-offer"
    hide-header-close
    centered
  >
    <p class="hide-offer-content">
      {{ $t('offer_list.offer_item.sure_for_hide_offer') }}
    </p>

    <template
      #modal-footer
    >
      <div
        class="tw-w-full tw-flex"
      >
        <div class="tw-flex-1" />
        <ui-button
          class="cancel-button mr-2"
          variant="danger"
          outline
          @click="open = false"
        >
          {{ $t('cancel') | capitalize }}
        </ui-button>
        <ui-button
          class="confirm-button float-right"
          variant="primary"
          @click="confirm"
        >
          {{ $t('confirm') | capitalize }}
        </ui-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import useStore from '@/composables/useStore'
  import useRouter from '@/composables/useRouter'
  import useRoute from '@/composables/useRoute'

  /**
   * @module component - dialogHideOffer
   */
  export default defineComponent({
    name: 'DialogHideOffer',
    props: {
      value: {
        type: null,
        default: null
      }
    },
    setup (props) {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()

      const { state: open } = useModelGetterSetter(props, 'value')

      function confirm () {
        /**
         * This will hide the offer and reset the current offer.
         * In the list, this will cause the detail to be closed, but we must handle the case where
         * the user is in the offer full page view; and therefore must be redirected to the list.
         */
        return store.value.dispatch('offers/hideOffer')
          .finally(() => {
            open.value = false

            if (route.value.name === 'Offer') {
              router.value.push({
                name: 'Offers'
              })
                .catch(() => { /** noop */ })
            }
          })
      }

      return {
        open,
        confirm
      }
    }
  })
</script>
