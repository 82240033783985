<template>
  <div
    class="ui-fat-radio-group"
    role="radiogroup"
  >
    <template
      v-for="item in items"
    >
      <slot
        :item="item"
        :active="Array.isArray(value)
          ? value.includes(item.value)
          : value === item.value"
        :keydown="keydown"
      />
    </template>
  </div>
</template>

<script>
  import { KEYCODES } from '@/composables/constants'

  /**
   * @module component - UiFatRadioGroup
   * @param {Array<any>} items
   */
  export default {
    name: 'UiFatRadioGroup',
    props: {
      value: {
        type: [String, Array, Boolean],
        default: null
      },
      items: {
        type: [Array, Object],
        required: true
      }
    },
    methods: {
      /**
       * @function nextRadio
       * @param {any} node
       */
      nextRadio (node) {
        let next = node.nextSibling

        if (next) {
          if (next.nodeType === Node.ELEMENT_NODE) {
            // @ts-ignore
            if (next.getAttribute('role') === 'radio') return next
          }
          next = next.nextSibling
        }

        return null
      },
      /**
       * @function previousRadio
       * @param {any} node
       */
      previousRadio (node) {
        let previous = node.previousSibling

        if (previous) {
          if (previous.nodeType === Node.ELEMENT_NODE) {
            // @ts-ignore
            if (previous.getAttribute('role') === 'radio') return previous
          }
          previous = previous.previousSibling
        }

        return null
      },
      /**
       * @function lastRadio
       * @param {any} node
       */
      lastRadio (node) {
        if (node.parentNode) {
          let last = node.parentNode.lastChild

          if (last) {
            if (last.nodeType === Node.ELEMENT_NODE) {
              // @ts-ignore
              if (last.getAttribute('role') === 'radio') return last
            }

            last = last.previousSibling
          }

          return last
        }

        return false
      },
      /**
       * @function firstRadio
       * @param {any} node
       */
      firstRadio (node) {
        if (node.parentNode) {
          let first = node.parentNode.firstChild

          if (first) {
            if (first.nodeType === Node.ELEMENT_NODE) {
              // @ts-ignore
              if (first.getAttribute('role') === 'radio') return first
            }

            first = first.nextSibling
          }

          return first
        }

        return false
      },
      /**
       * @function keydown
       * @param {KeyboardEvent} event
       */
      keydown (event) {
        const node = event.currentTarget
        /** @type {any} */
        let next = false

        switch (event.keyCode) {
        case KEYCODES.DOWN:
        case KEYCODES.RIGHT:
          next = this.nextRadio(node)
          if (!next) next = this.firstRadio(node)
          break
        case KEYCODES.UP:
        case KEYCODES.LEFT:
          next = this.previousRadio(node)
          if (!next) next = this.lastRadio(node)
          break
        case KEYCODES.SPACE:
          next = node
          break
        }

        if (next) {
          next.focus()

          event.preventDefault()
          event.stopPropagation()
        }
      }
    }
  }
</script>
