import MetaModel from './models/MetaModel'

export default () => ({
  currentMission: {},
  missions: {
    items: [],
    meta: new MetaModel(),
    filters: {
      tracking_to_pickup: false,
      tracking_to_deliver: false,
      tracking_to_upload_pod: false,
      your_missions: false
    }
  },
  metrics: {
    me: {
      planned: null,
      in_progress: null,
      completed: null,
      cancelled: null,
      total: null
    },
    company: {
      planned: null,
      in_progress: null,
      completed: null,
      cancelled: null,
      total: null
    }
  },
  billing: null,
  currentMissionStatus: 'planned',
  missionUuidToShow: null,
  itineraries: [],
  fetchMissionsActive: false
})
