<template>
  <ctk-alert-banner-dialog
    v-model="dialogValue"
    :title="$t('app.paragraphs.account_locked')"
    variant="danger"
  >
    <contact-infos-animation-user
      :default-content="'app.paragraphs.account_locked.more_informations'"
      :animation-content="'app.paragraphs.account_locked.more_informations.animation'"
      :default-phone="chronoinfos.phones.locked.text"
      :default-email="chronoinfos.emails.support"
      data-test="infos"
    />
  </ctk-alert-banner-dialog>
</template>

<script>
  import chronoinfos from '@/../config/chronoinfos'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import CtkAlertBannerDialog from '@/components/CtkAlertBanners/_subs/CtkAlertBannerDialog/index.vue'
  import ContactInfosAnimationUser from '@/components/CtkLockedAccount/_subs/ContactInfosAnimationUser/index.vue'

  /**
   * @module component - CtkLockedAccountDialog
   */
  export default {
    name: 'CtkLockedAccountDialog',
    components: {
      CtkAlertBannerDialog,
      ContactInfosAnimationUser
    },
    props: {
      value: {
        type: Boolean,
        required: true
      }
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        dialogValue
      }
    },
    data () {
      return {
        chronoinfos
      }
    }
  }
</script>
