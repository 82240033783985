<script>
  import { defineComponent } from '@vue/composition-api'

  import UiIcon from '@/components/UI/Icon/index.vue'

  /**
   * @module component - MaterialIcon
   * @param {string} name - Name of the icon
   */
  export default defineComponent({
    components: {
      UiIcon
    },
    props: {
      name: {
        type: String,
        required: true
      }
    },
    render (h) {
      return h(UiIcon, {
        class: [
          'material-icons'
        ]
      }, [
        this.name
      ])
    }
  })
</script>
