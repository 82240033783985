<template>
  <div
    class="ctk-input-rate"
    :class="{
      'has-rate': !!this.over
    }"
  >
    <!-- eslint-disable -->
    <svg style="position: absolute; width: 0; height: 0;" width="0" height="0" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="icon-star-full" viewBox="0 0 32 32">
          <path d="M32 12.408l-11.056-1.607-4.944-10.018-4.944 10.018-11.056 1.607 8 7.798-1.889 11.011 9.889-5.199 9.889 5.199-1.889-11.011 8-7.798z"></path>
        </symbol>
      </defs>
    </svg>
    <!-- eslint-enable -->

    <div class="ctk-input-rate__stars tw-flex tw-justify-center">
      <button
        v-for="n in 5"
        :key="n"
        :class="{
          hover: n <= over,
          'filled-over': !(n <= value && n <= over),
          filled: (n <= value) && !(n <= over),
          active: n === value
        }"
        :title="$t('app.labels.rates.' + rates[n - 1])"
        :style="[buttonSizeCss]"
        :disabled="readOnly"
        class="tw-flex ctk-input-rate__button tw-rounded"
        data-test="rate-button"
        type="button"
        @click="setValue(n)"
        @mouseover="mouseOver(n)"
        @mouseout="mouseOut"
      >
        <svg
          class="icon"
          :style="[starSizeCss]"
        >
          <use xlink:href="#icon-star-full" />
        </svg>
      </button>
    </div>
    <div
      v-if="(value || over) && description"
      class="ctk-input-rate__description tw-text-center"
    >
      {{ $t('app.labels.rates.' + rate) }}
    </div>
  </div>
</template>

<script>
  /**
   * @module component - ctkInputRate
   * @param {boolean} [readOnly=false] - Set the input read-only, to disable the click & hover
   * @param {boolean} [description=false] - Specify if we should show the associated state label
   * @param {number} starSize - Change the size
   * @param {boolean} [keepFocus=true] - Specify if once the user clicked on the star, the star is active
   */
  export default {
    name: 'CtkInputRate',
    props: {
      value: {
        type: Number,
        default: null
      },
      readOnly: {
        type: Boolean,
        default: false
      },
      description: {
        type: Boolean,
        default: false
      },
      starSize: {
        type: Number,
        default: 20
      },
      keepFocus: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        over: null
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.over = this.value
      })
    },
    watch: {
      value: function (v) {
        this.over = v
      }
    },
    computed: {
      /**
       * @function rates
       * @returns {Array}
       */
      rates: () => ['very_bad', 'bad', 'average', 'good', 'very_good'],
      /**
       * Returns a rate state associated with the current value
       * @function rate
       * @returns {string}
       */
      rate () {
        const over = this.over - 1
        return this.rates[this.over !== null ? over : this.value - 1]
      },
      starSizeCss () {
        return {
          width: `${this.starSize}px`,
          height: `${this.starSize}px`
        }
      },
      buttonSizeCss () {
        const buttonSize = this.starSize + 5
        return {
          width: `${buttonSize}px`,
          height: `${buttonSize}px`
        }
      }
    },
    methods: {
      setValue (n) {
        if (!this.readOnly) {
          const isAlreadySelected = this.value === n

          this.$emit('input', isAlreadySelected ? null : n)
          if (this.keepFocus) {
            this.over = isAlreadySelected ? null : n
          } else {
            this.over = null
          }
        }
      },
      mouseOver (n) {
        if (!this.readOnly) this.over = n
      },
      mouseOut () {
        if (!this.readOnly) this.over = this.value
      }
    }
  }
</script>

<style lang="scss" scoped>

  $rating-primary: #F5A623;

  .ctk-input-rate {
    &__button {
      appearance: none;
      border: none;
      background: transparent;
      padding: 0;

      &:focus {
        @include focusShadow($rating-primary);

        outline: none;
      }

      .icon {
        stroke: $info;
        fill: white;
        transition: transform 200ms ease-in-out, stroke 80ms ease-in-out, fill 80ms ease-in-out;
        margin: auto;
      }

      &.hover,
      &.filled:not(.filled-over) {
        color: $rating-primary;

        .icon {
          stroke: $rating-primary;
          fill: $rating-primary;
          transform: scale(1.1);
        }
      }
    }

    &__description {
      min-height: 21px;
    }

    &__stars:hover .ctk-input-rate__button .icon,
    &.has-rate .ctk-input-rate__button .icon {
      stroke: $rating-primary;
    }
  }

</style>
