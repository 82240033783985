// @ts-nocheck
import { Driver } from '@/resources'

export default {
  retrieveDrivers ({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      let page = 1
      const items = []
      function retrieveMissions () {
        return Driver.get({
          cid: rootGetters['auth/getCid']
        }, {
          params: {
            limit: 30,
            page
          }
        })
          .then(response => {
            const pagination = response.data.meta.pagination
            if (pagination.current_page < pagination.page_count) {
              page += 1
              response.data.items.forEach(item => {
                items.push(item)
              })
              retrieveMissions()
            } else {
              response.data.items.forEach(item => {
                items.push(item)
              })
              resolve(items)
              commit('SET_DRIVERS', items)
            }
          }, error => reject(error))
      }
      retrieveMissions()
    })
  },
  setCurrentDriver ({ commit }, driver) {
    commit('SET_CURRENT_DRIVER', driver)
  },
  resetCurrentDriver ({ commit }) {
    commit('RESET_CURRENT_DRIVER')
  },
  saveDriver ({ commit, rootGetters }, params) {
    return Driver.save({ cid: rootGetters['auth/getCid'] }, params)
      .then((response) => {
        const { data } = response
        commit('UNSHIFT_DRIVER', {
          ...params,
          ...data
        })

        return response
      })
  },
  deleteDriver ({ commit, rootGetters }, uuid) {
    return Driver.delete({ cid: rootGetters['auth/getCid'], did: uuid })
      .then(() => {
        commit('DELETE_DRIVER', uuid)
        commit('RESET_CURRENT_DRIVER')
      })
  },
  getDriverMetrics ({ rootGetters }, uuid) {
    return Driver.metrics({
      cid: rootGetters['auth/getCid'],
      did: uuid
    }).then(({ data }) => {
      return data
    })
  },
  setPhoneNumber ({ commit, rootGetters }, { driver, phone }) {
    const { phone: driverPhone, uuid } = driver
    const data = { phone }
    const params = {
      cid: rootGetters['auth/getCid'],
      did: uuid
    }

    const request = driverPhone
      ? Driver.updatePhone(params, data)
      : Driver.phone(params, data)

    return request
      .then(() => {
        commit('ADD_PHONE_TO_DRIVER', { uuid, phone })
      })
  }
}
