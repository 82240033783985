// @ts-nocheck
import { CancelToken } from 'axios'

import { Shipment } from '@/resources'

export default {
  setSearchItems ({ commit }, items) {
    commit('SET_SEARCH_ITEMS', items)
  },
  setSearchMeta ({ commit }, meta) {
    commit('SET_SEARCH_META', meta)
  },
  pushSearchItems ({ commit }, items) {
    commit('PUSH_SEARCH_ITEMS', items)
  },
  /**
   * Do a XHR request, by returning the xhr object
   * @function retrieveSearches
   * @returns {object} xhr
   */
  async retrieveSearches ({ dispatch, rootGetters }, { query }) {
    const source = CancelToken.source()
    const req = Shipment.get({
      cid: rootGetters['auth/getCid']
    }, {
      params: {
        q: query,
        limit: 30
      },
      cancelToken: source.token
    })

    return {
      cancelTokenSource: source,
      request: req.then(res => {
        dispatch('setSearchItems', res.data.items)
        dispatch('setSearchMeta', res.data.meta)
        return res
      })
    }
  },
  async retrieveMoreSearches ({ getters, dispatch, rootGetters }, { query }) {
    const { pagination } = getters.getSearchMeta

    const source = CancelToken.source()
    const req = Shipment.get({
      cid: rootGetters['auth/getCid']
    }, {
      params: {
        q: query,
        limit: 30,
        page: pagination.current_page + 1
      },
      cancelToken: source.token
    })

    return {
      cancelTokenSource: source,
      request: req.then(res => {
        dispatch('pushSearchItems', res.data.items)
        dispatch('setSearchMeta', res.data.meta)
        return res
      })
    }
  }
}
