/**
 * @function currency
 * @param {number} number
 * @param {string} currency
 * @param {string} locale
 * @param {boolean} [round=false]
 * @returns {string}
 */
export default (number, currency, locale, round = false) => {
  if (typeof number === 'undefined') throw new Error('The `number` attribute is required.')
  if (typeof currency === 'undefined') throw new Error('The `currency` attribute is required.')
  if (typeof locale === 'undefined') throw new Error('The `locale` attribute is required.')

  /** @type {number} */
  let numberToFormat = number / 100
  /** @type {number} */
  let numberOfDecimal = 2

  if (round) {
    numberToFormat = Math.floor(numberToFormat)
    numberOfDecimal = 0
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || 'EUR',
    minimumFractionDigits: numberOfDecimal
  }).format(numberToFormat)
}
