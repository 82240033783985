// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class OfferResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}/offers{/oid}'
  }

  tracking (...args) {
    return this.action('HEAD', '', ...args)
  }

  travel (...args) {
    return this.action('GET', '/travel', ...args)
  }
}
