import { Search } from '@/resources'

export default (/** @type {import('../AxiosResource').AxiosResourceConfig} */ config) => {
  /**
   * Change the Content-Type for PUT search requests for retrocompatibility issues.
   * TODO: Remove this once all the apps are done migrating with the new "min_length"
   * parameter
   */
  if (config.method === 'put' &&
    config.uriTemplate &&
    // @ts-ignore
    config.uriTemplate.expression === Search.path.expression &&
    config.headers
  ) {
    config.headers['Content-Type'] = 'application/vnd.ctk.carrier_offer_search.v2+json'
  }

  return config
}
