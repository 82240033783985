// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class AuthResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/auth'
  }

  signIn (...args) {
    return this.action('POST', '/token', ...args)
  }

  resetPassword (...args) {
    return this.action('POST', '/forgotten_password', ...args)
  }

  renewPassword (...args) {
    return this.action('POST', '/renew_password', ...args)
  }

  emailValidation (...args) {
    return this.action('POST', '/email_validation', ...args)
  }
}
