import Vue from 'vue'
import VueI18n from 'vue-i18n'
import moment from 'moment'
import { localize } from 'vee-validate'

import CustomValidators from '@/validators'
import { init } from '@/plugins/VeeValidate'
import VueICU from '@/plugins/VueICU'
import Storage from '@/services/Storage'

import {
  supportedLocales,
  supportedFiles,
  preloadedFiles
} from './constants'

Vue.use(VueI18n)
Vue.use(VueICU)

/**
 * Import all the moment & validator locales for all the supported
 * locales. Easier to import them at the startup than lazy-load.
 * @type {any}
 */
const veeLocales = {}
supportedLocales
  .forEach(({ locale }) => {
    const momentLocale = require(`./moment/${locale}`)
    moment.updateLocale(locale, momentLocale.default)

    const { messages } = require(`vee-validate/dist/locale/${locale}.json`)
    veeLocales[locale] = {
      messages
    }
  })

/**
 * Import the vee-validate rules, before importing the locales for those rules.
 */
init()
localize(veeLocales)

/**
 * Keep all the translation keys, with it's locale.
 * @const messagesDictionnary
 * @type {any}
 */
const messagesDictionnary = {}

/**
 * Keep all the ICU translation keys, with it's locale.
 * @const icuMessagesDictionnary
 * @type {any}
 */
const icuMessagesDictionnary = {}

/** @type {Array<string>} */
let filesToLoad = []

/** @type {any} */
const loadedFiles = {}

/**
 * A temporarily constant used to migrate progressively the translations
 * from the JSON files to the ICU format.
 * @const ICU_FILES_MIGRATION
 */
const ICU_FILES_MIGRATION = {
  drivers: 'drivers-icu',
  faq: 'faq-icu'
}

supportedFiles.forEach((file) => {
  if (!Object.values(ICU_FILES_MIGRATION).includes(file)) {
    if (!loadedFiles[file]) {
      loadedFiles[file] = {}
    }
    loadedFiles[file].en = true

    const translations = require(`./translations/${file}/en.json`)
    messagesDictionnary.en = {
      ...messagesDictionnary.en,
      ...translations
    }
  }
})

/**
 * Import manually the ICU files
 */
Object.values(ICU_FILES_MIGRATION).forEach((file) => {
  const icuTranslactions = require(`./translations/${file}/en.yml`)
  icuMessagesDictionnary.en = {
    ...icuMessagesDictionnary.en,
    ...icuTranslactions
  }
})

/**
 * Returns the default browser language. We trim the value to get the locale.
 * @function defaultLocale
 * @returns {string} locale
 */
export const defaultLocale = () => {
  /** @type {string} */
  const browserLocale = window.navigator.language
  /** @type {string} */
  const locale = browserLocale.slice(0, 2)

  return locale !== ''
    ? locale
    : 'en'
}

/** @type {Array<string>} */
const supportedLocaleList = supportedLocales.map(({ locale }) => locale)

export const icu = new VueICU({
  locale: supportedLocaleList.includes(defaultLocale())
    ? defaultLocale()
    : 'en',
  fallbackLocale: 'en',
  messages: icuMessagesDictionnary
})

export const i18n = new VueI18n({
  locale: supportedLocaleList.includes(defaultLocale())
    ? defaultLocale()
    : 'en',
  fallbackLocale: 'en',
  messages: messagesDictionnary
})

/**
 * @function setI18nLanguage
 * @param {string} lang
 */
function setI18nLanguage (lang) {
  i18n.locale = lang
  icu.locale = lang

  const htmlNode = document.querySelector('html')
  if (htmlNode) htmlNode.setAttribute('lang', lang)

  moment.locale(lang)
  localize(lang)

  /**
   * Init the custom VeeValidators after the locale has been
   * provided. That way, we can now pick the appropriate locale
   * in our validators according to the VeeValidate locale.
   */
  CustomValidators.init()

  return lang
}

/**
 * Returns a promise that calls a promise after a certain time
 * @function promiseDelay
 * @param {Function} cb
 * @param {number} delay
 * @param {any} args
 * @returns {Promise<any>}
 */
const promiseDelay = (cb, delay, ...args) => new Promise(resolve => {
  setTimeout(() => {
    return resolve(cb(args[0], args[1]))
  }, delay)
})

/**
 * @function loadFiles
 * @param {string} locale
 * @param {?string} [additionalFile]
 */
function loadFiles (locale, additionalFile) {
  const files = [
    // @ts-ignore
    ...new Set([
      ...preloadedFiles,
      ...filesToLoad
    ])
  ]

  if (additionalFile) files.push(additionalFile)

  /**
   * Check if all the files for this specific locale
   * are already loaded.
   */
  const hasEveryFilesLoaded = files.every(file => loadedFiles && loadedFiles[file] && loadedFiles[file][locale])
  if (hasEveryFilesLoaded) {
    setI18nLanguage(locale)
    return Promise.resolve()
  }

  /** @type {Array<Promise<any>>} */
  const filePromises = []
  for (const file of files) {
    /**
     * @function importPromise
     * @param {string} innerFile
     * @param {string} innerLocale
     * @returns {Promise<any>}
     */
    const importPromise = (innerFile, innerLocale) => {
      /**
       * If the file we're loading is the driver or faq file, then load
       * the ICU version at the same time.
       */
      let filePromise
      const isICU = Object.values(ICU_FILES_MIGRATION).includes(innerFile)
      if (isICU) {
        filePromise = import(/* webpackChunkName: "lang-[request]" */ `./translations/${innerFile}/${innerLocale}.yml`)
      } else {
        filePromise = import(/* webpackChunkName: "lang-[request]" */ `./translations/${innerFile}/${innerLocale}.json`)
      }

      return filePromise
        .then((res) => {
          return {
            messages: res,
            file: innerFile,
            locale: innerLocale
          }
        })
    }

    const fulfilledPromise = importPromise(file, locale)
      .then((res) => {
        if (!loadedFiles[file]) {
          loadedFiles[file] = {}
        }
        loadedFiles[file][locale] = true
        return res
      })
      .catch(() => promiseDelay(importPromise, 200, file, locale))
      .catch(() => promiseDelay(importPromise, 200, file, locale))
      .catch(() => promiseDelay(importPromise, 200, file, locale))
      .catch(err => {
        console.error(`Could not load a translation file: ${err}`)
        return err
      })

    filePromises.push(fulfilledPromise)
  }

  return new Promise(resolve => {
    Promise.all(filePromises)
      .then(async (results) => {
        if (results.length > 0) {
          results.forEach(result => {
            const isICU = Object.values(ICU_FILES_MIGRATION).includes(result.file)
            if (isICU) {
              icuMessagesDictionnary[result.locale] = {
                ...icuMessagesDictionnary[result.locale],
                ...result.messages.default
              }
            } else {
              messagesDictionnary[result.locale] = Object.assign({}, messagesDictionnary[result.locale], result.messages.default)
            }
          })
        }

        i18n.setLocaleMessage(locale, messagesDictionnary[locale])
        icu.setLocaleMessage(locale, icuMessagesDictionnary[locale])
        setI18nLanguage(locale)

        resolve(null)
      })
  })
}

/**
 * @function loadLanguageAsync
 * @param {string} requestedLocale
 * @param {string|Array<string>|null} [routes=null] - The file(s) (aka route) to load specifically,
 * otherwise loads all the preloaded files for that locale.
 * @returns {Promise<any>}
 */
export function loadLanguageAsync (requestedLocale, routes) {
  /** @type {Array<string>} */
  let files = []
  if (routes) {
    if (!Array.isArray(routes)) {
      files = [routes]
    } else {
      files = routes
    }
  }

  /** @type {string} */
  let lang = requestedLocale

  /**
   * Check if the locale we're trying to load is allowed.
   * If not, fallback to the english
   * @type {Array<string>}
   */
  const mappedSupportedLocales = supportedLocales.map(v => v.locale)
  if (!mappedSupportedLocales.includes(lang)) {
    /**
     * Check if there is a storage locale, and it's supported
     * @type {?string}
     */
    const storageLocale = Storage ? Storage.getItem('userLocale') : null

    /** @type {boolean} */
    const isStorageLocaleSupported = !!storageLocale && !!mappedSupportedLocales.includes(storageLocale.slice(0, 2))

    /** @type {string} */
    const previousLang = lang
    lang = isStorageLocaleSupported && !!storageLocale
      ? storageLocale.slice(0, 2)
      : 'en'
    console.error(`The locale ${previousLang} is not supported. Fallback to ${lang}...`)
  }

  /**
   * Check if the route belongs to the preloaded files
   */
  if (files.length > 0) {
    /**
     * A list of files that are not already loaded
     * @const unloadedFiles
     */
    const unloadedFiles = files.filter(file => {
      return !(loadedFiles[file] && loadedFiles[file][lang])
    })

    if (unloadedFiles.length === 0) {
      filesToLoad = [
        // @ts-ignore
        ...new Set([
          ...filesToLoad,
          ...files
        ])
      ]

      Object.keys(ICU_FILES_MIGRATION).forEach(fileName => {
        if (filesToLoad.includes(fileName)) {
          // @ts-ignore
          filesToLoad.push(ICU_FILES_MIGRATION[fileName])
        }
      })

      /**
       * Since we don't have any unloaded files, that mean all of them
       * are already loaded. Skip.
       */
      return Promise.resolve()
    } else {
      /**
       * We have some unloaded files, include them in the list to preload.
       *
       * List of files filtered to only have the supported ones
       * @const unloadedFilesFiltered
       */
      const unloadedFilesFiltered = unloadedFiles.filter(file => supportedFiles.includes(file))
      unloadedFilesFiltered.forEach(file => {
        filesToLoad.push(file)

        Object.keys(ICU_FILES_MIGRATION).forEach(fileName => {
          if (file === fileName) {
            // @ts-ignore
            filesToLoad.push(ICU_FILES_MIGRATION[fileName])
          }
        })
      })

      return loadFiles(lang)
        .catch(err => {
          console.error('Could not fetch files', err)
          return err
        })
    }
  } else {
    return loadFiles(lang)
      .catch((/** @type {any} */ err) => {
        console.error('Could not fetch files', err)
        return err
      })
  }
}
