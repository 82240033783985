// @ts-nocheck
import Vue from 'vue'

export default {
  SET_SHIPMENT (state, { shipment }) {
    state.shipment = shipment
  },
  SET_SHIPMENTS_ITEMS (state, { items }) {
    state.shipments.items = items
  },
  PUSH_SHIPMENTS_ITEMS (state, { items }) {
    items.forEach(item => {
      const shipmentIndex = state.shipments.items.findIndex(shipment => shipment.uuid === item.uuid)
      if (shipmentIndex !== -1) {
        /**
         * Update the previous shipment value with the new one
         */
        Vue.set(state.shipments.items, shipmentIndex, Object.assign({}, state.shipments.items[shipmentIndex], item))
      } else {
        /**
         * Push the new item to the list if it does not already exists
         */
        state.shipments.items.push(item)
      }
    })
  },
  SET_SHIPMENTS_META (state, { meta }) {
    state.shipments.meta = meta
  },
  SET_SHIPMENT_DATA (state, { uuid, data }) {
    const shipmentIndex = state.shipments.items.findIndex(shipment => shipment.uuid === uuid)
    if (shipmentIndex !== -1) {
      Vue.set(state.shipments.items, shipmentIndex, Object.assign({}, state.shipments.items[shipmentIndex], data))
    }

    /**
     * Whenever we set a shipment data, check if the shipment we're updating
     * is the current shipment. If that's the case, update it as well.
     */
    if (state.shipment && state.shipment.uuid === uuid) {
      Vue.set(state, 'shipment', Object.assign({}, state.shipment, data))
    }
  },
  SET_SHIPMENTS_METRICS (state, { metrics }) {
    state.shipments.metrics = metrics
  },
  SET_DASHBOARD_METRICS (state, data) {
    state.dashboard.metrics = data
  },
  SET_SHIPMENTS_FILTER (state, { filter, value }) {
    if (filter === 'expires_in') {
      state.shipments.filters[filter] = value ? 3600 : null
    } else {
      state.shipments.filters[filter] = value ? 1 : 0
    }
  },
  SET_ESTIMATION (state, estimation) {
    state.estimation = estimation
  },
  SET_EXPRESS_QUOTE_ADDRESS (state, { direction, address }) {
    state.expressQuote.address[direction] = address
  },
  SET_EXPRESS_QUOTE_LOAD (state, load) {
    state.expressQuote.load = load
  },
  TOGGLE_SHIPMENTS_FILTER (state, { filter }) {
    if (filter === 'expires_in') {
      state.shipments.filters[filter] = state.shipments.filters[filter] ? null : 3600
    } else {
      state.shipments.filters[filter] = state.shipments.filters[filter] ? 0 : 1
    }
  }
}
