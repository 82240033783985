<template>
  <header
    class="ctk-notifications-dialog-header tw-border-0 tw-border-b tw-border-b-gray-400 tw-border-solid tw-flex tw-items-center tw-justify-between tw-py-1"
  >
    <div
      v-text="$t('app.titles.notifications')"
      class="tw-text-xl tw-font-medium tw-text-gray-700 tw-mt-2"
      data-test="title"
    />

    <ui-button
      :title="$t('close') | capitalize"
      variant="link"
      type="button"
      data-test="close"
      @click="$emit('close')"
    >
      <template #left-icon>
        <ui-ctk-icon
          name="close"
          data-test="icon"
        />
      </template>
    </ui-button>
  </header>
</template>

<script>
  /**
   * @module component - CtkNotificationsDialogHeader
   * @emits close
   */
  export default {
    name: 'CtkNotificationsDialogHeader'
  }
</script>
