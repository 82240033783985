import { getCurrentInstance } from '@vue/composition-api'

export default function useCurrentInstance () {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('Must be called in setup')

  return {
    vm
  }
}
