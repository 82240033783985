<template>
  <div class="tw-flex shipment-item-header-references tw-truncate 2sm:tw-items-center">
    <ul
      class="tw-flex tw-flex-col 2sm:tw-flex-row tw-truncate tw-mx-0 tw-p-0 tw-list-none tw-my-auto"
    >
      <li class="tw-p-0 tw-pr-2 tw-m-0">
        {{ shipment.reference }}
      </li>
      <li
        v-if="shipment.shipper_reference"
        class="tw-p-0 tw-px-2 tw-truncate tw-m-0"
      >
        {{ shipment.shipper_reference }}
      </li>
    </ul>
  </div>
</template>

<script>
  /**
   * @module component - shipmentItemHeaderReferences
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentItemHeaderReferences',
    props: {
      shipment: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-item-header-references {
    li {
      max-width: 200px;
    }

    li:not(:last-child) {
      border-right: 1px solid white;
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      li {
        border: none !important;

        &:last-child {
          padding-left: 0 !important;
        }
      }
    }
  }

</style>
