<template>
  <div class="ctk-nav-bar-mobile-user-menu">
    <ctk-user-info
      data-test="user-info"
    />

    <ul>
      <template
        v-if="!isPreRegistered"
      >
        <li
          v-if="!isImpersonating"
        >
          <router-link
            :to="{
              name: 'AccountProfile'
            }"
            data-test="account-link"
            class="ctk-nav-bar-mobile-user-menu__link tw-flex tw-items-center"
            v-text="$options.filters.capitalize($t('navigation.account'))"
            @click.native="close"
          />
        </li>
      </template>
      <li>
        <a
          v-if="isImpersonating"
          :href="getRedirectUrl"
          data-test="redirectUrl-link"
          class="ctk-nav-bar-mobile-user-menu__link tw-flex tw-items-center"
          v-text="$options.filters.capitalize($t('navigation.sign_out.isImpersonating'))"
        />
        <a
          v-else
          href="#"
          @click.prevent="logOut"
          data-test="logout-link"
          class="ctk-nav-bar-mobile-user-menu__link tw-flex tw-items-center"
          v-text="$options.filters.capitalize($t('navigation.sign_out'))"
        />
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AuthService from '@/services/AuthService'

  import CtkUserInfo from '@/components/CtkUser/CtkUserInfo'

  /**
   * @module component - CtkNavBarMobileUserMenu
   */
  export default {
    name: 'CtkNavBarMobileUserMenu',
    components: {
      CtkUserInfo
    },
    computed: {
      ...mapGetters('auth', [
        'isImpersonating',
        'isPreRegistered',
        'getRedirectUrl'
      ])
    },
    methods: {
      /**
       * Closes the dropdown menu
       * @function close
       */
      close () {
        this.$emit('close')
      },
      /**
       * Request auth logout
       * @function logOut
       */
      logOut (e) {
        AuthService.signOut(() => {
          this.close()
        })
        e.preventDefault()
      }
    }
  }
</script>

<style lang="scss">

  .ctk-nav-bar-mobile-user-menu {
    padding: 0 10px;

    .ctk-user-info {
      margin-bottom: 30px;
    }

    ul,
    li {
      margin: 0;
      padding: 0;
    }

    &__link {
      margin: 0;
      color: rgba(255, 255, 255, 0.5);
      list-style: none;
      border-bottom: 0 solid white;
      transition: background-color 200ms, border-bottom 200ms;
      height: 30px;
      padding: 0 8px 0 8px;
      margin-bottom: 4px;
      width: 100%;

      &:hover {
        text-decoration: none;
      }

      &:hover:not(.router-link-active),
      &:hover:not(.active) {
        background-color: rgba(#303144, 0.8);
        color: rgba(255, 255, 255, 0.8);
      }

      &.router-link-active,
      &.active {
        background-color: #303144 !important;
        color: white !important;
      }
    }
  }

  .ctk-nav-bar-mobile-user-menu .ctk-user-info .header__infos {
    .header__firstname {
      color: white;
    }

    .header__email {
      color: rgba(white, 0.7);
    }
  }

</style>
