// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class DriverResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}/drivers{/did}'
  }

  metrics (...args) {
    return this.action('GET', '/metrics/missions', ...args)
  }

  phone (...args) {
    return this.action('POST', '/phone', ...args)
  }

  updatePhone (...args) {
    return this.action('PUT', '/phone', ...args)
  }
}
