import mitt from 'mitt'

const emitter = mitt()

/**
 * Creating a wrap around the previous implementation of the EventBus
 * to use the Vue-instance like syntax, but use mitt instead.
 * Once everything works, we can remove this old syntax and use plain mitt.
 */
export const EventBus = {
  /**
   * @function $on
   * @param {string} event
   * @param {import('mitt').Handler<any>} callback
   */
  $on (event, callback) {
    emitter.on(event, callback)
  },

  /**
   * @function $off
   * @param {string} event
   * @param {import('mitt').Handler<any>?} callback
   */
  $off (event, callback = null) {
    // @ts-ignore
    emitter.off(event, callback)
  },

  /**
   * @function $emit
   * @param {string} event
   * @param {any} parameters
   */
  $emit (event, parameters = null) {
    emitter.emit(event, parameters)
  }
}
