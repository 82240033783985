<template>
  <div
    class="tw-flex shipment-item-header-infos"
    :class="{ 'tw-text-red-500': hasDeliveryLate && hasPickupCompleted }"
  >
    <template
      v-if="shipment.billing && shipment.billing.need_payment"
    >
      <div class="tw-flex tw-items-center">
        <ui-ctk-icon
          class="tw-text-blue-500"
          name="credit-card"
          data-test="icon"
        />
        <span
          class="tw-text-blue-500 tw-font-medium"
          v-text="$t('shipments.paragraphs.need_payment_expiration', {
            date: $options.filters.moment(shipment.billing.max_payment_date, 'LLL')
          })"
          data-test="content"
        />
      </div>
    </template>
    <template
      v-else
    >
      <ui-ctk-icon
        name="info"
        data-test="icon"
      />

      <i18n
        v-if="shipment.state === 'planned'"
        :path="'shipments.labels.starting'"
        tag="span"
        data-test="content"
      >
        <template #time>
          <span
            v-text="pickupTimeLeft"
            class="tw-font-medium tw-text-blue-500"
          />
        </template>
      </i18n>
      <i18n
        v-else-if="shipment.state === 'transit' && !hasPickupCompleted"
        :path="'shipments.labels.your_carrier_will_come'"
        tag="span"
        data-test="second-content"
      >
        <template #date>
          <span
            class="tw-font-medium"
          >{{ date | moment('ll') }}</span>
        </template>
        <template #start_time>
          <span
            class="tw-font-medium"
          >{{ startTime | moment('LT') }}</span>
        </template>
        <template #end_time>
          <span
            class="tw-font-medium"
          >{{ endTime | moment('LT') }}</span>
        </template>
      </i18n>
      <span
        v-else
      >
        {{ $t( hasDeliveryLate ? 'shipments.labels.delivery_late' : 'shipments.labels.delivery_pending') }}
      </span>
    </template>
  </div>
</template>

<script>
  /**
   * @module component - ShipmentItemHeaderInfos
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentItemHeaderInfos',
    props: {
      shipment: {
        type: Object,
        required: true
      }
    },
    computed: {
      /**
       * @function date
       */
      date () {
        const { time_slot: timeSlot } = this.shipment.pickup
        return this.$moment(`${timeSlot.date}`, 'YYYY-MM-DD')
      },
      /**
       * @function startTime
       */
      startTime () {
        const { time_slot: timeSlot } = this.shipment.pickup
        return this.$moment(`${timeSlot.date} ${timeSlot.start_time}`, 'YYYY-MM-DD HH:mm')
      },
      /**
       * @function endTime
       */
      endTime () {
        const { time_slot: timeSlot } = this.shipment.pickup
        return this.$moment(`${timeSlot.date} ${timeSlot.end_time}`, 'YYYY-MM-DD HH:mm')
      },
      /**
       * Returns true if the pickup has completed state
       * @function hasPickupCompleted
       * @returns {boolean}
       */
      hasPickupCompleted () {
        return this.shipment.pickup.state === 'completed'
      },
      /**
       * Returns true if the delivery date is in the past
       * @function hasDeliveryLate
       * @returns {boolean}
       */
      hasDeliveryLate () {
        const { time_slot: timeSlot } = this.shipment.delivery
        return this.$moment(`${timeSlot.date} ${timeSlot.start_time}`, 'YYYY-MM-DD HH:mm').isBefore(this.$moment())
      },
      /**
       * Returns a formatted string for the time left to the pickup
       * @function pickupTimeLeft
       * @returns {string}
      */
      pickupTimeLeft () {
        const { time_slot: timeSlot } = this.shipment.pickup
        return this.$moment(`${timeSlot.date} ${timeSlot.start_time}`, 'YYYY-MM-DD HH:mm').fromNow(true)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-item-header-infos {
    .ctk-font,
    span {
      margin: auto 0;
    }

    .ctk-font {
      font-size: 30px;
    }

    span {
      @media only screen and (max-width: $breakpoint-tablet) {
        display: none;
      }
    }
  }

</style>
