// @ts-nocheck
import { Payment, Invoice, PreInvoice, CompanyMission, Shipment } from '@/resources'

export default {
  /**
   * Makes a GET request to retrieve the payments.
   * @function retrievePaymentsRequest
   * @param {object} params
   */
  retrievePaymentsRequest ({ commit, rootGetters }, params) {
    return Payment.get({
      cid: rootGetters['auth/getCid']
    }, {
      params
    })
      .then((res) => {
        commit('SET_PAYMENTS_META', {
          meta: res.data.meta
        })
        return Promise.resolve(res)
      })
  },
  /**
   * Makes a GET request to retrieve the invoices.
   * @function retrieveInvoice
   * @param {object} params
   */
  retrieveInvoice ({ commit, rootGetters }, params) {
    const resource = rootGetters.isUserShipper
      ? Invoice
      : PreInvoice

    return resource.get({
      cid: rootGetters['auth/getCid'],
      uid: params.uuid
    })
      .then((res) => {
        commit('UPDATE_INVOICE', {
          invoice: res.data
        })
        return Promise.resolve(res)
      })
  },
  /**
   * Makes a GET request to retrieve the invoices.
   * @function retrieveInvoiceMission
   * @param {object} params
   */
  retrieveInvoiceMission ({ commit, rootGetters }, params) {
    return CompanyMission.get({
      cid: rootGetters['auth/getCid'],
      mid: params.uuid
    })
      .then((res) => {
        commit('UPDATE_INVOICE_MISSION', {
          uuid: params.invoiceUuid,
          mission: res.data
        })

        return res
      })
  },
  /**
   * Makes a GET request to retrieve the invoice shipment.
   * @function retrieveInvoiceShipment
   * @param {object} params
   */
  retrieveInvoiceShipment ({ commit, rootGetters }, { invoiceUuid, sid }) {
    return Shipment.get({
      cid: rootGetters['auth/getCid'],
      sid
    })
      .then((res) => {
        commit('UPDATE_INVOICE_SHIPMENT', {
          uuid: invoiceUuid,
          shipment: res.data
        })
        return res
      })
  },
  /**
   * Makes a GET request to retrieve the invoices.
   * @function retrieveInvoiceMissionBilling
   * @param {object} params
   */
  retrieveInvoiceMissionBilling ({ commit, rootGetters }, params) {
    return CompanyMission.billing({
      cid: rootGetters['auth/getCid'],
      mid: params.uuid
    })
      .then((res) => {
        commit('UPDATE_INVOICE_MISSION_BILLING', {
          uuid: params.invoiceUuid,
          billing: res.data
        })
        return res
      })
  },
  /**
   * Makes a GET request to retrieve the billing from a shipment.
   * @function retrieveInvoiceShipmentBilling
   * @param {object} params
   */
  retrieveInvoiceShipmentBilling ({ commit, rootGetters }, { sid, invoiceUuid }) {
    return Shipment.billing({
      cid: rootGetters['auth/getCid'],
      sid
    })
      .then((res) => {
        commit('UPDATE_INVOICE_SHIPMENT_BILLING', {
          uuid: invoiceUuid,
          billing: res.data
        })
        return res
      })
  },
  /**
   * Makes a GET request to retrieve the invoices.
   * @function retrieveInvoicesRequest
   * @param {object} params
   */
  retrieveInvoicesRequest ({ commit, rootGetters }, params) {
    const resource = rootGetters.isUserShipper
      ? Invoice
      : PreInvoice

    return resource.get({
      cid: rootGetters['auth/getCid']
    }, {
      params
    })
      .then((res) => {
        commit('SET_INVOICES_META', {
          meta: res.data.meta
        })
        return Promise.resolve(res)
      })
  },
  retrieveMorePayments ({ getters, commit, dispatch }) {
    return new Promise((resolve) => {
      const meta = getters.getPaymentsMeta
      const page = getters.getPaymentsCurrentPage
      if (page < meta.pagination.page_count) {
        commit('SET_PAYMENTS_CURRENT_PAGE', {
          page: page + 1
        })

        dispatch('retrievePaymentsRequest', {
          page: getters.getPaymentsCurrentPage,
          limit: 30
        })
          .then((res) => {
            commit('PUSH_ITEMS', {
              key: 'payments',
              items: res.data.items
            })
            commit('SET_PAYMENTS_META', {
              meta: res.data.meta
            })
          })
          .finally(() => resolve())
      } else {
        resolve()
      }
    })
  },
  retrieveMoreInvoices ({ getters, commit, dispatch }, state) {
    return new Promise((resolve) => {
      const meta = getters.getInvoicesMeta
      const page = getters.getInvoicesCurrentPage
      if (page < meta.pagination.page_count) {
        commit('SET_INVOICES_CURRENT_PAGE', {
          page: page + 1
        })

        dispatch('retrieveInvoicesRequest', {
          page: getters.getInvoicesCurrentPage,
          state,
          limit: 30
        })
          .then((res) => {
            commit('PUSH_ITEMS', {
              key: 'invoices',
              items: res.data.items
            })
            commit('SET_INVOICES_META', {
              meta: res.data.meta
            })
          })
          .catch(() => {})
          .finally(() => resolve())
      } else {
        resolve()
      }
    })
  },
  /**
   * @function retrievePayments
   * @param {object} query
   * @param {object} query.page
   * @returns {Promise} request
   */
  retrievePayments ({ commit, dispatch }, { page }) {
    commit('SET_PAYMENTS_CURRENT_PAGE', {
      page: 1
    })

    return dispatch('retrievePaymentsRequest', {
      page,
      limit: 30
    })
      .then((res) => {
        commit('SET_PAYMENT_ITEMS', {
          key: 'payments',
          items: res.data.items
        })
      })
  },
  /**
   * Request the list of invoices
   * @function retrieveInvoices
   * @param {object} query
   * @param {object} query.page
   * @param {object} query.state
   * @returns {Promise} request
   */
  retrieveInvoices ({ commit, dispatch }, { page, state, options }) {
    commit('SET_INVOICES_CURRENT_PAGE', {
      page: 1
    })

    return dispatch('retrieveInvoicesRequest', {
      page,
      state,
      limit: 30
    })
      .then((res) => {
        commit('SET_INVOICE_ITEMS', {
          key: 'invoices',
          items: res.data.items,
          options
        })
      })
      .catch(() => {})
  },
  /**
   * @function retrieveAllInvoices
   * @param {object} query
   * @param {string} query.state
   * @returns {Promise} request
   */
  retrieveAllInvoices ({ commit, dispatch }, { state }) {
    let page = 1
    let invoices = []

    return new Promise((resolve, reject) => {
      function retrieveInvoices () {
        return dispatch('retrieveInvoicesRequest', {
          page,
          state,
          limit: 30
        })
          .then(response => {
            invoices = invoices.concat(response.data.items)

            const pagination = response.data.meta.pagination
            if (pagination.current_page < pagination.page_count) {
              page += 1
              retrieveInvoices()
            } else {
              commit('SET_INVOICE_ITEMS', {
                key: 'invoices',
                items: invoices,
                options: {}
              })
              resolve(invoices)
            }
          })
          .catch(err => reject(err))
      }

      retrieveInvoices()
    })
  },
  /**
   * @function retrievePaymentsMetrics
   * @returns {Promise}
   */
  retrievePaymentsMetrics ({ commit, rootGetters }) {
    return Payment.metrics({
      cid: rootGetters['auth/getCid']
    })
      .then(({ data }) => {
        commit('SET_PAYMENTS_METRICS', {
          metrics: data
        })
      })
      .catch(() => {})
  },
  /**
   * @function retrieveInvoicesMetrics
   * @returns {Promise}
   */
  retrieveInvoicesMetrics ({ commit, rootGetters }) {
    const resource = rootGetters.isUserShipper
      ? Invoice
      : PreInvoice

    return resource.metrics({
      cid: rootGetters['auth/getCid']
    })
      .then(({ data }) => {
        commit('SET_INVOICES_META', {
          meta: {
            item_count: data.counts.total,
            amounts: data.amounts
          }
        })
        commit('SET_INVOICES_METRICS', {
          metrics: data
        })
      })
      .catch(() => {})
  },
  setCurrentPaymentInvoices (store, invoices) {
    const currentPayment = store.getters.getCurrentPayment
    if (currentPayment) {
      store.commit('SET_CURRENT_PAYMENT_INVOICES', {
        uuid: currentPayment.uuid,
        invoices
      })
    }
  }
}
