<template>
  <ctk-dialog
    :value="dialogValue"
    modal-class="ctk-tooltip-dialog"
    hide-header
    hide-footer
    @handle-close="close"
  >
    <div class="ctk-tooltip-dialog__wrapper">
      <div class="ctk-tooltip-dialog__header tw-flex tw-justify-end">
        <button
          :title="$t('close') | capitalize"
          type="button"
          class="ctk-tooltip-dialog__header__close"
          data-test="close"
          @click="close"
        >
          <ui-ctk-icon
            name="close"
            data-test="icon"
          />
        </button>
      </div>
      <div class="ctk-tooltip-dialog__content">
        <h1
          v-if="title"
          data-test="title"
          class="ctk-tooltip-dialog__content__title fs-22"
        >
          {{ title }}
        </h1>
        <p data-test="content">
          <slot />
        </p>
      </div>
    </div>
  </ctk-dialog>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * Component used to show the tooltip content inside a dialog, in mobile devices
   * because a tooltip is not "clickable".
   * @module component - CtkTooltipDialog
   */
  export default defineComponent({
    name: 'CtkTooltipDialog',
    props: {
      title: {
        type: String,
        default: null
      },
      value: {
        type: Boolean,
        default: null
      }
    },
    components: {
      CtkDialog
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        dialogValue
      }
    },
    methods: {
      close () {
        this.dialogValue = false
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-tooltip-dialog {
    &__header__close {
      appearance: none;
      border: none;
      background: transparent;
      height: 50px;
      width: 50px;
      padding: 0;
      color: $secondary-text;
    }

    &__content {
      padding: 32px 40px;

      &__title {
        padding-bottom: 32px;
        margin-bottom: 0;
      }
    }

    &__footer {
      margin-top: 32px;
    }

    &__wrapper {
      position: relative;
      padding-bottom: 170px;

      &::after {
        position: absolute;
        bottom: 16px;
        width: 100%;
        min-height: 170px;
        content: '';
        background-size: cover;
        background-image: url('~@/assets/img/illustrations/welcome.svg');
        background-position: center bottom;
        opacity: 0.8;
      }
    }
  }

</style>
