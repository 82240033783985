import * as Sentry from '@sentry/browser'

import store from '@/store'

/**
 * Handle chunk loading error during deployment.
 * Here we want to check if the chunk error was caused by a deployment
 * if not, report the error.
 */
export default function (error) {
  const isUpdateRequired = store.getters['update/isUpdateRequired']

  if (/loading chunk \d* failed./i.test(error.message) && isUpdateRequired) {
    /**
     * Force the reload since the user shouldn't have gotten there.
     */
    window.location.reload()
  } else {
    Sentry.captureException(error)
  }
}
