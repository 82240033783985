// @ts-nocheck
import { PaymentIntent } from '@/resources'

export default {
  selectAllInvoices ({ dispatch, rootGetters }) {
    const invoices = rootGetters['billing/getInvoicesItems'].map(invoice => ({
      uuid: invoice.uuid,
      total: invoice.vat_included_amount
    }))

    dispatch('setInvoices', invoices)
  },
  setInvoices ({ commit }, invoices) {
    commit('SET_INVOICES', invoices)
  },
  unselectAll ({ dispatch }) {
    dispatch('setInvoices', [])
  },
  toggleInvoice ({ commit, getters }, invoice) {
    const hasInvoice = getters.getInvoices.findIndex(v => v.uuid === invoice.uuid)
    if (hasInvoice !== -1) {
      commit('REMOVE_INVOICE', invoice)
    } else {
      commit('ADD_INVOICE', invoice)
    }
  },
  requestPaymentIntent ({ rootGetters, getters, commit }) {
    const invoices = getters.getInvoices.map(invoice => invoice.uuid)

    return PaymentIntent.save({
      cid: rootGetters['auth/getCid']
    }, {
      invoices
    })
      .then(res => {
        const { client_secret: clientSecret, amount, currency } = res.data
        commit('SET_PAYMENT_INTENT', clientSecret)
        commit('SET_INVOICES_TOTAL', {
          amount,
          currency
        })

        return res
      })
  }
}
