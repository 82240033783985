// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class ShipmentProposalResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/companies/{cid}/shipments/{sid}/proposals{/pid}'
  }

  accept (...args) {
    return this.action('POST', '/accept', ...args)
  }

  refuse (...args) {
    return this.action('POST', '/refuse', ...args)
  }
}
