// @ts-nocheck
export default {
  PUSH_SEARCH_ITEMS (state, items) {
    state.search.items = [
      ...state.search.items,
      ...items
    ]
  },
  SET_SEARCH_ITEMS (state, items) {
    state.search.items = items
  },
  SET_SEARCH_META (state, meta) {
    state.search.meta = meta
  }
}
