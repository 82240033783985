<template>
  <div
    :class="{
      'new-shipment-acceptation-dialog-reassurance--highlight': highlight
    }"
    class="new-shipment-acceptation-dialog-reassurance tw-px-3 tw-py-2 tw-rounded"
  >
    <div class="new-shipment-acceptation-dialog-reassurance__header tw-flex tw-items-end tw-pb-1 tw-mb-3">
      <img
        :src="icon"
        alt=""
        class="tw-mr-2 tw-select-none"
        width="20"
        data-test="icon"
      >
      <div
        v-text="title"
        class="tw-font-bold tw-text-sm tw-leading-tight"
        data-test="title"
      />
    </div>
    <div
      v-markdown="content"
      class="new-shipment-acceptation-dialog-reassurance__content tw-m-0"
      data-test="content"
    />
  </div>
</template>

<script>
  /**
   * @module component - NewShipmentAcceptationDialogReassurance
   * @param {string} icon
   * @param {string} title
   * @param {string} content
   * @param {boolean} [highlight=false]
   */
  export default {
    name: 'NewShipmentAcceptationDialogReassurance',
    props: {
      icon: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      content: {
        type: String,
        required: true
      },
      highlight: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss">

  .new-shipment-acceptation-dialog-reassurance {
    &__header {
      border-bottom: 1px solid $info;
    }

    &--highlight {
      background-color: $light-gray;
    }
  }

  .new-shipment-acceptation-dialog-reassurance__content {
    p {
      margin: 0;
    }

    strong {
      font-weight: 500;
    }
  }

</style>
