/**
 * TODO: Remove this useless file & inject directly to the store.
 */
export default class MetaModel {
  constructor () {
    return {
      item_count: null,
      pagination: {
        current_page: null,
        page_count: null,
        page_size: null
      }
    }
  }
}
