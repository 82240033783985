<template>
  <div class="specificity-item tw-flex tw-border tw-border-solid tw-border-gray-500 tw-rounded tw-py-3">
    <div class="tw-flex-shrink-0">
      <ui-ctk-icon
        :name="icon"
        class="tw-text-secondary-text tw-w-4 tw-ml-2 tw-mr-1"
        data-test="icon"
      />
    </div>
    <div class="tw-flex-1 tw-pr-4">
      <div class="tw-inline-flex tw-flex-wrap tw-items-center">
        <div
          v-text="title"
          class="tw-uppercase tw-text-secondary-text tw-text-xs tw-mr-1"
          data-test="title"
        />
        <div
          v-if="direction"
          class="tw-flex"
          data-test="badges"
        >
          <UiBadge
            v-if="direction === 'pickup' || direction === 'both'"
            color="blue"
            size="sm"
            neutral
            class="tw-mr-1"
            data-test="pickup-badge"
          >
            {{ $t('pickup') | capitalize }}
          </UiBadge>
          <UiBadge
            v-if="direction === 'delivery' || direction === 'both'"
            color="green"
            size="sm"
            neutral
            data-test="delivery-badge"
          >
            {{ $t('delivery') | capitalize }}
          </UiBadge>
          <UiBadge
            v-if="direction === 'load'"
            color="blue"
            size="sm"
            neutral
            data-test="load-badge"
          >
            {{ $tc('good', 1) | capitalize }}
          </UiBadge>
        </div>
      </div>
      <div
        class="tw-font-medium tw-break-words tw-whitespace-pre-wrap tw-text-sm tw-mb-0"
      >
        <slot name="content">
          <p
            v-text="content"
            class="tw-mb-0"
            data-test="body"
          />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'SpecificityItem',
    props: {
      icon: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      content: {
        type: String,
        default: null
      },
      direction: {
        type: String,
        default: null
      }
    }
  })
</script>
