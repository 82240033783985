<template>
  <div class="tw-flex shipment-item-options">
    <div class="tw-flex tw-items-center shipment-item-options__pallets">
      <ui-ctk-icon
        name="dimension"
        data-test="icon"
      />
      <span
        v-if="shipment.load.type === 'pallets'"
        v-text="$t('shipments.labels.pallets', {
          count: $n(shipment.load.quantity)
        })"
      />
      <span
        v-else
        v-text="$t('shipments.labels.linear_meters', {
          linear_meters: $n(shipment.load.linear_meters)
        })"
      />
    </div>
  </div>
</template>

<script>
  /**
   * @module component - shipmentItemOptions
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentItemOptions',
    props: {
      shipment: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-item-options {
    &__pallets {
      min-width: 100px;

      &,
      .ctk-font,
      span {
        margin: auto 0;
      }

      .ctk-font {
        font-size: 30px;
      }
    }
  }

</style>
