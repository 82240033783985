<template functional>
  <div
    class="ctk-item-tracking-dot tw-flex tw-items-center tw-justify-center"
    :class="{
      active: props.active,
      completed: props.completed
    }"
  >
    <ui-material-icon
      v-if="props.completed"
      class="tw-text-white fs-10"
      name="check"
      data-test="icon"
    />
  </div>
</template>

<script>
  /**
   * @module component - CtkItemTrackingDot
   * @param {boolean} active
   * @param {boolean} completed
   */
  export default {
    name: 'CtkItemTrackingDot'
  }
</script>

<style lang="scss" scoped>

  .ctk-item-tracking-dot {
    min-width: 10px;
    width: 10px;
    height: 10px;
    min-height: 10px;
    border-radius: 50%;
    background-color: $light-gray;

    &.active,
    &.completed {
      background-color: $info;
    }

    &.completed {
      min-width: 14px;
      width: 14px;
      height: 14px;
      min-height: 14px;
      border: 1px solid white;
    }
  }

</style>
