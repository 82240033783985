import SmartBanner from 'smart-app-banner'
import 'smart-app-banner/dist/smart-app-banner.css'
import '@/assets/scss/ctk-smartbanner-override.scss'

export default (/** @type {any} */ t) => {
  new SmartBanner({ // eslint-disable-line no-new
    title: t('smartbanner.title'),
    author: 'Chronotruck',
    button: t('smartbanner.button.view'),
    store: {
      ios: t('smartbanner.store.ios'),
      android: t('smartbanner.store.android')
    },
    price: {
      ios: t('smartbanner.price.ios'),
      android: t('smartbanner.price.android')
    },
    // @ts-ignore
    icon: require('@/assets/img/icon_app.png')
  })
}
