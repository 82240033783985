import Vue from 'vue'
import Router from 'vue-router'
import AuthService from '@/services/AuthService'
import store from '@/store'

import EmailValidationMiddleware from './middleware/EmailValidationMiddleware'
import ImpersonateMiddleware from './middleware/ImpersonateMiddleware'
import NotFoundMiddleware from './middleware/NotFoundMiddleware'
import TranslationsMiddleware from './middleware/TranslationsMiddleware'
import AuthMiddleware from './middleware/AuthMiddleware'
import PreRegisteredMiddleware from './middleware/PreRegisteredMiddleware'

import ChunkErrorHandler from './error/ChunkErrorHandler'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior: () => ({
    x: 0,
    y: 0
  }),
  routes: [
    {
      path: '/',
      name: 'Auth',
      redirect: {
        name: 'Dashboard'
      }
    },
    {
      path: '/auth',
      name: 'AuthView',
      meta: {
        hasNavbar: false,
        requiresAuth: false
      },
      redirect: {
        name: 'SignIn'
      },
      component: () => import(/* webpackChunkName: "auth" */ '@/views/Common/Auth'),
      children: [
        {
          path: 'sign-in',
          name: 'SignIn',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          component: () =>
            import(/* webpackChunkName: "auth" */ '@/views/Common/Auth/_subs/SignIn')
        },
        {
          path: 'password-forgot',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          name: 'PasswordForgot',
          component: () =>
            import(/* webpackChunkName: "auth" */ '@/views/Common/Auth/_subs/PasswordForgot')
        },
        {
          path: 'renew-password',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          name: 'PasswordReset',
          component: () =>
            import(/* webpackChunkName: "auth" */ '@/views/Common/Auth/_subs/PasswordReset')
        },
        {
          path: 'register',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          name: 'Register',
          component: () =>
            import(/* webpackChunkName: "auth" */ '@/views/Common/Auth/_subs/Register')
        },
        {
          path: 'invite',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          name: 'Invite',
          component: () =>
            import(/* webpackChunkName: "auth" */ '@/views/Common/Auth/_subs/Invite')
        },
        {
          path: 'invite/confirmation',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          name: 'InviteConfirmation',
          component: () =>
            import(/* webpackChunkName: "auth" */ '@/views/Common/Auth/_subs/Invite/_subs/Confirmation')
        },
        {
          path: 'email-validation',
          name: 'EmailValidation',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          }
        },
        {
          path: 'impersonate',
          name: 'Impersonate',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          }
        }
      ]
    },
    {
      path: '/auth/register/:userType(shipper|carrier)',
      name: 'RegisterFlow',
      meta: {
        hasNavbar: false,
        requiresAuth: false,
        translations: ['auth']
      },
      component: () => import(/* webpackChunkName: "register" */ '@/views/Common/RegisterFlow'),
      children: [
        {
          path: 'company',
          name: 'RegisterCompany',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          component: () =>
            import(/* webpackChunkName: "register" */ '@/views/Common/RegisterFlow/_subs/RegisterCompany')
        },
        {
          path: 'questions/:index?',
          name: 'RegisterQuestions',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          component: () =>
            import(/* webpackChunkName: "register" */ '@/views/Common/RegisterFlow/_subs/RegisterQuestions')
        },
        {
          path: 'account/infos',
          name: 'RegisterAccountInfos',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          component: () =>
            import(/* webpackChunkName: "register" */ '@/views/Common/RegisterFlow/_subs/RegisterAccount/_subs/RegisterAccountInfos')
        },
        {
          path: 'account/credentials',
          name: 'RegisterAccountCredentials',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          component: () =>
            import(/* webpackChunkName: "register" */ '@/views/Common/RegisterFlow/_subs/RegisterAccount/_subs/RegisterAccountCredentials')
        },
        {
          path: 'confirmation',
          name: 'RegisterConfirmation',
          meta: {
            hasNavbar: false,
            requiresAuth: false
          },
          component: () =>
            import(/* webpackChunkName: "register" */ '@/views/Common/RegisterFlow/_subs/RegisterConfirmation')
        }
      ]
    },
    {
      path: '/onboarding',
      name: 'Onboarding',
      component: () =>
        import(/* webpackChunkName: "onboarding" */ '@/views/Carriers/Onboarding')
    },
    {
      path: '/onboarding/confirmation',
      name: 'OnboardingConfirmation',
      component: () =>
        import(/* webpackChunkName: "onboarding" */ '@/views/Carriers/Onboarding/_subs/Confirmation')
    },
    {
      path: '/faq',
      name: 'Faq',
      component: () =>
        import(/* webpackChunkName: "faq" */ '@/views/Common/Faq'),
      meta: {
        translations: ['faq-icu']
      }
    },
    {
      path: '/drivers',
      name: 'Drivers',
      component: () =>
        import(/* webpackChunkName: "drivers" */ '@/views/Carriers/Drivers'),
      meta: {
        translations: ['drivers-icu']
      }
    },
    {
      path: '/statistics',
      name: 'Statistics',
      meta: {
        translations: ['statistics']
      },
      component: () =>
        import(/* webpackChunkName: "statistics" */ '@/views/Carriers/Statistics')
    },
    {
      path: '/missions',
      name: 'MissionsRouter',
      component: () =>
        import(/* webpackChunkName: "missions" */ '@/views/Carriers/Missions'),
      redirect: () => ({
        name: 'Missions',
        params: {
          state: 'planned'
        }
      }),
      children: [
        {
          path:
            ':state(planned|in_progress|completed|cancelled)',
          name: 'Missions',
          component: () =>
            import(/* webpackChunkName: "missions" */ '@/views/Carriers/Missions/views/Missions')
        },
        {
          path: ':uuid',
          name: 'Mission',
          component: () =>
            import(/* webpackChunkName: "missions" */ '@/views/Carriers/Missions/views/Missions')
        }
      ]
    },
    {
      path: '/billing',
      redirect: {
        name: 'Invoices'
      },
      name: 'Billing',
      component: () =>
        import(/* webpackChunkName: "billing" */ '@/views/Common/Billing'),
      children: [
        {
          path: 'invoices/:stateUuid?',
          name: 'Invoices',
          components: {
            default: () =>
              import(/* webpackChunkName: "billing" */ '@/views/Common/Billing/_subs/Invoices'),
            sidebar: () =>
              import(/* webpackChunkName: "billing" */ '@/views/Common/Billing/_subs/Invoices/_subs/InvoiceDetail')
          }
        },
        {
          path: 'payments/:uuid?',
          name: 'Payments',
          components: {
            default: () =>
              import(/* webpackChunkName: "billing" */ '@/views/Carriers/Billing/_subs/Payments'),
            sidebar: () =>
              import(/* webpackChunkName: "billing" */ '@/views/Carriers/Billing/_subs/Payments/components/CarrierPaymentDetail')
          }
        },
        {
          path: 'credit-card-payments/:uuid?',
          name: 'CreditCardPayments',
          components: {
            default: () =>
              import(/* webpackChunkName: "billing" */ '@/views/Shippers/Billing/_subs/CreditCardPayments'),
            sidebar: () =>
              import(/* webpackChunkName: "billing" */ '@/views/Shippers/Billing/_subs/CreditCardPayments/components/CreditCardPaymentDetail')
          }
        }
      ]
    },
    {
      path: '/offers',
      component: () =>
        import(/* webpackChunkName: "offers" */ '@/views/Carriers/Offers'),
      children: [
        {
          path: '/',
          name: 'Offers',
          component: () =>
            import(/* webpackChunkName: "offers" */ '@/views/Carriers/Offers/views/Offers')
        },
        /**
         * TODO: Remove this route when we're sure no one is using it anymore.
         */
        {
          path: 'map',
          name: 'OffersMap',
          redirect: {
            name: 'Offers'
          }
        },
        {
          path: 'proposals',
          name: 'Proposals',
          component: () =>
            import(/* webpackChunkName: "proposals" */ '@/views/Carriers/Offers/views/Proposals')
        },
        {
          path: 'proposals/:state(pending)',
          name: 'ProposalStates',
          component: () =>
            import(/* webpackChunkName: "proposals" */ '@/views/Carriers/Offers/views/Proposals')
        },
        {
          path: 'searches/:uuid?',
          name: 'Searches',
          component: () =>
            import(/* webpackChunkName: "offers" */ '@/views/Carriers/Offers/views/Offers')
        },
        /**
         * TODO: Remove this route when we're sure no one is using it anymore.
         */
        {
          path: 'searches/:uuid?/map',
          name: 'SearchesMap',
          redirect: {
            name: 'Searches'
          }
        }
      ]
    },
    {
      path: '/account',
      name: 'Account',
      redirect: {
        name: 'AccountProfile'
      },
      component: () =>
        import(/* webpackChunkName: "account" */ '@/views/Common/Account'),
      children: [
        {
          path: 'profile',
          name: 'AccountProfile',
          component: () =>
            import(/* webpackChunkName: "profile" */ '@/views/Common/Account/Profile')
        },
        {
          path: 'password',
          name: 'AccountPassword',
          component: () =>
            import(/* webpackChunkName: "password" */ '@/views/Common/Account/Password')
        },
        {
          path: 'company',
          name: 'AccountCompany',
          component: () =>
            import(/* webpackChunkName: "company" */ '@/views/Common/Account/Company')
        },
        {
          path: 'billing',
          name: 'AccountBilling',
          component: () =>
            import(/* webpackChunkName: "billing" */ '@/views/Common/Account/Billing')
        },
        {
          path: 'payment-sources',
          name: 'AccountPaymentSources',
          component: () =>
            import(/* webpackChunkName: "payment-sources" */ '@/views/Common/Account/PaymentSources')
        },
        {
          path: 'locale',
          name: 'AccountLocale',
          component: () =>
            import(/* webpackChunkName: "locale" */ '@/views/Common/Account/Locale')
        },
        {
          path: 'members',
          name: 'AccountMembers',
          component: () =>
            import(/* webpackChunkName: "members" */ '@/views/Common/Account/Members')
        }
      ]
    },
    {
      path: '/offers/:uuid',
      name: 'Offer',
      component: () =>
        import(/* webpackChunkName: "offer" */ '@/views/Carriers/Offer')
    },
    {
      path:
        '/shipments/:state(available|planned|in_progress|completed|cancelled)?',
      name: 'Shipments',
      component: () =>
        import(/* webpackChunkName: "shipments" */ '@/views/Shippers/Shipments')
    },
    {
      path: '/shipments/new',
      name: 'NewShipment',
      component: () =>
        import(/* webpackChunkName: "new-shipment" */ '@/views/Shippers/NewShipment'),
      meta: {
        translations: ['new-shipment']
      },
      children: [
        {
          path: 'address/:direction(pickup|delivery)',
          name: 'NewShipmentAddress',
          component: () =>
            import(/* webpackChunkName: "new-shipment" */ '@/views/Shippers/NewShipment/_subs/NewShipmentAddress'),
          meta: {
            translations: ['new-shipment']
          }
        },
        {
          path: 'dates',
          name: 'NewShipmentDates',
          component: () =>
            import(/* webpackChunkName: "new-shipment" */ '@/views/Shippers/NewShipment/_subs/NewShipmentDates'),
          meta: {
            translations: ['new-shipment']
          }
        },
        {
          path: 'handling',
          name: 'NewShipmentHandling',
          component: () =>
            import(/* webpackChunkName: "new-shipment" */ '@/views/Shippers/NewShipment/_subs/NewShipmentHandling'),
          meta: {
            translations: ['new-shipment']
          }
        },
        {
          path: 'goods',
          name: 'NewShipmentGoods',
          component: () =>
            import(/* webpackChunkName: "new-shipment" */ '@/views/Shippers/NewShipment/_subs/NewShipmentGoods'),
          meta: {
            translations: ['new-shipment']
          }
        },
        {
          path: 'quotation',
          name: 'NewShipmentQuotation',
          component: () =>
            import(/* webpackChunkName: "new-shipment" */ '@/views/Shippers/NewShipment/_subs/NewShipmentQuotation'),
          meta: {
            translations: ['new-shipment']
          }
        },
        {
          path: 'informations',
          name: 'NewShipmentInformations',
          component: () =>
            import(/* webpackChunkName: "new-shipment" */ '@/views/Shippers/NewShipment/_subs/NewShipmentInformations'),
          meta: {
            translations: ['new-shipment']
          }
        }
      ]
    },
    {
      path: '/shipments/new/template',
      name: 'NewShipmentTemplate',
      component: () =>
        import(/* webpackChunkName: "new-shipment" */ '@/views/Shippers/NewShipment/_subs/NewShipmentTemplate'),
      meta: {
        translations: ['new-shipment']
      }
    },
    {
      path: '/shipments/new/template/list',
      name: 'NewShipmentTemplateList',
      component: () =>
        import(/* webpackChunkName: "new-shipment" */ '@/views/Shippers/NewShipment/_subs/NewShipmentTemplate/_subs/NewShipmentTemplateList'),
      meta: {
        translations: ['new-shipment']
      }
    },
    {
      path: '/shipments/:uuid',
      name: 'Shipment',
      component: () =>
        import(/* webpackChunkName: "shipments" */ '@/views/Shippers/Shipment')
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () =>
        import(/* webpackChunkName: "dashboard" */ '@/views/Common/Dashboard'),
      meta: {
        translations: ['billing']
      }
    },
    {
      path: '/logout',
      name: 'Logout',
      beforeEnter () {
        AuthService.signOut()
      }
    },
    {
      path: '*',
      redirect: { name: 'Dashboard' }
    }
  ]
})

router.beforeEach(EmailValidationMiddleware)
router.beforeEach(ImpersonateMiddleware)
router.beforeEach(NotFoundMiddleware)
router.beforeEach(TranslationsMiddleware)
router.beforeEach(AuthMiddleware)
router.beforeEach(PreRegisteredMiddleware)

router.onError(ChunkErrorHandler)

/**
 * If a "unexpected token" error occurs, that means VueRouter could not
 * load a chunk for some reason. If that's the case, force a page refresh.
 * As seen in: https://stackoverflow.com/questions/59385650/vuejs-browser-caching-and-loading-chunk-failed-issue
 */
window.onerror = function (errorMessage) {
  const isUpdateRequired = store.getters['update/isUpdateRequired']

  if (errorMessage.toLowerCase().indexOf('unexpected token \'<\'') > -1 && isUpdateRequired) {
    if (navigator.onLine) {
      window.location.reload()
    }
  }
}

Vue.prototype.router = router

export default router
