export default (/** @type {import('vue').VueConstructor} */ Vue) => {
  // eslint-disable-next-line
  if (!Vue.prototype.hasOwnProperty('$icu')) {
    Object.defineProperty(Vue.prototype, '$icu', {
      get () {
        return this._icu
      }
    })
  }

  /**
   * @function $trans
   * @param {string} key
   * @param {object} variables
   * @returns {string}
   */
  Vue.prototype.$trans = function (key, variables) {
    const icu = this.$icu
    return icu.trans(key, variables)
  }
}
