<template>
  <ctk-alert-banner
    :title="title"
    class="ctk-email-validation"
    :variant="variant"
    tabindex="0"
  >
    <div class="tw-mb-0">
      <span
        v-markdown="text"
        data-test="inner-content"
      />
      <div
        class="tw-inline-flex"
      >
        <a
          v-if="type === 'user_email_awaiting_validation'"
          :disabled="$wait.is('re-sending email validation')"
          v-text="$t('app.buttons.resend_validation_link')"
          v-matomo="{
            click: {
              category: 'Global',
              action: 'Alerts',
              name: 'Email Validation Resent'
            }
          }"
          href="#"
          role="button"
          class="ctk-email-validation__link tw-font-medium tw-italic tw-underline tw-ml-1 tw-mr-6"
          @click.prevent="resend"
          data-test="resend"
        />
        <a
          v-text="$t('app.buttons.edit_email')"
          v-matomo="{
            click: {
              category: 'Global',
              action: 'Alerts',
              name: 'Email Validation Edit'
            }
          }"
          href="#"
          role="button"
          class="ctk-email-validation__link tw-font-medium tw-italic tw-underline tw-ml-1"
          @click.prevent="edit"
          data-test="edit"
        />
      </div>
    </div>
  </ctk-alert-banner>
</template>

<script>
  import { mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import Hotjar from '@/plugins/VueHotjar'
  import { EventBus } from '@/services/EventBus'

  import CtkAlertBanner from '@/components/CtkAlertBanners/_subs/CtkAlertBanner/index.vue'

  /**
   * @module component - CtkEmailValidation
   * @param {string} title
   * @param {string} text
   * @param {string} variant
   * @param {string} type - The key from the alert (can be either "user_email_awaiting_validation" or "user_email_hard_bounce")
   */
  export default defineComponent({
    name: 'CtkEmailValidation',
    components: {
      CtkAlertBanner
    },
    props: {
      title: {
        type: String,
        required: true
      },
      text: {
        type: String,
        required: true
      },
      variant: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: true
      }
    },
    mounted () {
      Hotjar.tag('Email Validation')

      if (this.$matomo) this.$matomo.trackEvent('Global', 'Alerts', 'Email Validation Displayed')
    },
    methods: {
      ...mapActions('auth', [
        'resendValidation'
      ]),
      /**
       * @function edit
       */
      edit () {
        EventBus.$emit('dialogs:email-validation', false)
      },
      /**
       * @function resend
       */
      resend () {
        if (this.$wait.is('re-sending email validation')) return

        this.$wait.start('re-sending email validation')
        this.resendValidation()
          .then(() => {
            EventBus.$emit('dialogs:email-validation', true)
          })
          .finally(() => {
            this.$wait.end('re-sending email validation')
          })
      }
    }
  })
</script>

<style lang="scss">
.ctk-email-validation {
  cursor: auto !important;
}
.ctk-email-validation .markdown strong {
  font-weight: 500;
}
.ctk-email-validation__link:not(:last-child) {
  position: relative;
}
.ctk-email-validation__link:not(:last-child)::after {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 86, 110, var(--tw-bg-opacity));
  height: 1rem;
  position: absolute;
  content: '';
  width: 1px;
  right: -0.85rem;
}
.ctk-email-validation__link[disabled=disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
