// @ts-nocheck
import Vue from 'vue'

export default {
  SET_DRIVERS (state, val) {
    state.drivers = val
  },
  UNSHIFT_DRIVER (state, value) {
    state.drivers.unshift(value)
  },
  SET_CURRENT_DRIVER (state, val) {
    state.currentDriver = val
  },
  RESET_CURRENT_DRIVER (state) {
    state.currentDriver = null
  },
  DELETE_DRIVER (state, uuid) {
    state.drivers = state.drivers.filter((element) => { return element.uuid !== uuid })
  },
  ADD_PHONE_TO_DRIVER (state, { uuid, phone }) {
    const driverIndex = state.drivers.findIndex((obj) => obj.uuid === uuid)
    Vue.set(state.drivers[driverIndex], 'phone', phone)
    Vue.set(state.currentDriver, 'phone', phone)
  }
}
