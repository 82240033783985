<template>
  <price-chip
    :white="white"
    class="shipment-item-price tw-text-base tw-my-auto tw-mx-0 tw-px-2 tw-py-1 tw-whitespace-nowrap"
  >
    {{ $t('price_excl_tax', {
      price: $options.filters.currency(price, currency, $i18n.locale)
    }) }}
  </price-chip>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import PriceChip from '@/components/CtkPricing/_subs/PriceTotal/_subs/PriceChip/index.vue'

  /**
   * @module component - shipmentItemPrice
   * @param {object} shipment
   * @param {boolean} [white=false] - Sets the background in white
   */
  export default defineComponent({
    name: 'ShipmentItemPrice',
    components: {
      PriceChip
    },
    props: {
      shipment: {
        type: Object,
        required: true
      },
      white: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      /**
       * Returns true if the shipment has a current booking active.
       * Since the bookings value from the currentShipment is async fetched,
       * it may have a delay before showing the real value.
       * TODO: Waits the GET booking before showing the view OR
       * TODO: Have amounts in the mission object, in the shipment
       * @function hasBooking
       * @returns {boolean}
       */
      hasBooking () {
        const { mission, bookings } = this.shipment
        if (bookings && mission) {
          const currentBooking = bookings.findIndex(booking => booking && booking.uuid === mission.uuid)
          return currentBooking !== -1
        }

        return false
      },
      currency () {
        return this.hasBooking
          ? this.currentBooking.billing.currency
          : this.shipment.offer_price.currency
      },
      /**
       * Returns the current booking
       * @function currentBooking
       * @returns {object} booking
       */
      currentBooking () {
        const { mission, bookings } = this.shipment
        if (bookings && mission) {
          return bookings.find(booking => booking.uuid === mission.uuid)
        }

        return null
      },
      price () {
        return this.hasBooking
          ? this.currentBooking.billing.price
          : this.shipment.offer_price.price
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-item-price {
    min-width: 110px;
  }

</style>
