// @ts-nocheck
import Vue from 'vue'

export default {
  SET_INVOICES_ITEMS (state, { items }) {
    state.invoices.items = items
  },
  SET_PAYMENT_ITEMS (state, { items }) {
    state.payments.items = items
  },
  PUSH_ITEMS (state, { key, items }) {
    items.forEach((item) => {
      const index = state[key].items.findIndex(i => i.uuid === item.uuid)
      if (index === -1) {
        state[key].items.push(item)
      } else {
        state[key].items[index] = Object.assign({}, state[key].items[index], item)
      }
    })
  },
  SET_INVOICES_META (state, { meta }) {
    state.invoices.meta = Object.assign({}, state.invoices.meta, meta)
  },
  SET_INVOICES_METRICS (state, { metrics }) {
    state.invoices.metrics = Object.assign({}, state.invoices.metrics, metrics)
  },
  SET_PAYMENTS_METRICS (state, { metrics }) {
    state.payments.metrics = Object.assign({}, state.payments.metrics, metrics)
  },
  SET_INVOICE_ITEMS (state, { items, options }) {
    const invoiceToKeep = []
    /**
     * Keep a specific invoice in the list when overriding the whole stack.
     */
    if (options.keep) {
      const item = state.invoices.items.find(invoice => invoice.uuid === options.keep)
      if (item && !items.find(invoice => invoice.uuid === options.keep)) invoiceToKeep.push(item)
    }

    state.invoices.items = [
      ...items,
      ...invoiceToKeep
    ]
  },
  SET_PAYMENTS_META (state, { meta }) {
    state.payments.meta = Object.assign({}, state.payments.meta, meta)
  },
  SET_PAYMENTS_CURRENT_PAGE (state, { page }) {
    state.payments.currentPage = page
  },
  SET_INVOICES_CURRENT_PAGE (state, { page }) {
    state.invoices.currentPage = page
  },
  UPDATE_INVOICE (state, { invoice }) {
    const invoiceIndex = state.invoices.items.findIndex((inv) => inv.uuid === invoice.uuid)
    if (invoiceIndex !== -1) {
      state.invoices.items[invoiceIndex] = Object.assign({}, state.invoices.items[invoiceIndex], invoice)
    } else {
      state.invoices.items.push(invoice)
    }
  },
  UPDATE_INVOICE_MISSION (state, { uuid, mission }) {
    const invoiceIndex = state.invoices.items.findIndex((invoice) => invoice.uuid === uuid)
    if (invoiceIndex !== -1) {
      const invoice = state.invoices.items[invoiceIndex]
      invoice.mission = Object.assign({}, invoice.mission, mission)

      Vue.set(state.invoices.items, invoiceIndex, invoice)
    }
  },
  UPDATE_INVOICE_SHIPMENT (state, { uuid, shipment }) {
    const invoiceIndex = state.invoices.items.findIndex((invoice) => invoice.uuid === uuid)
    if (invoiceIndex !== -1) {
      const invoice = state.invoices.items[invoiceIndex]
      invoice.shipment = Object.assign({}, invoice.shipment, shipment)

      Vue.set(state.invoices.items, invoiceIndex, invoice)
    }
  },
  UPDATE_INVOICE_MISSION_BILLING (state, { uuid, billing }) {
    const invoiceIndex = state.invoices.items.findIndex((invoice) => invoice.uuid === uuid)
    if (invoiceIndex !== -1) {
      const invoice = state.invoices.items[invoiceIndex]
      invoice.mission.billing = Object.assign({}, invoice.mission.billing, billing)

      Vue.set(state.invoices.items, invoiceIndex, invoice)
    }
  },
  UPDATE_INVOICE_SHIPMENT_BILLING (state, { uuid, billing }) {
    const invoiceIndex = state.invoices.items.findIndex((invoice) => invoice.uuid === uuid)
    if (invoiceIndex !== -1) {
      const invoice = state.invoices.items[invoiceIndex]
      invoice.shipment.billing = Object.assign({}, invoice.shipment.billing, billing)

      Vue.set(state.invoices.items, invoiceIndex, invoice)
    }
  },
  SET_CURRENT_PAYMENT_INVOICES (state, { uuid, invoices }) {
    const item = state.payments.items.findIndex(item => item.uuid === uuid)
    if (item !== -1) {
      Vue.set(state.payments.items[item], 'invoices', invoices)
    }
  }
}
