<template>
  <div
    class="ctk-summary-card-title tw-flex tw-items-center tw-bg-blue-500 tw-font-medium tw-text-center tw-text-base tw-text-white tw-rounded-sm tw-px-6"
    v-text="title"
  />
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - CtkSummaryCardTitle
   * @param {string} title
   */
  export default defineComponent({
    name: 'CtkSummaryCardTitle',
    props: {
      title: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-summary-card-title {
    min-height: 30px;
  }

</style>
