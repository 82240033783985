<script>
  import { defineComponent } from '@vue/composition-api'

  import UiLoaderSpinner from './Spinner/index.vue'
  import UiLoaderPallet from './Pallet/index.vue'

  const ALLOWED_TYPES = ['spinner', 'pallet']

  /**
   * @module component - UiLoader
   * @param {string} type
   */
  export default defineComponent({
    name: 'UiLoader',
    props: {
      type: {
        type: String,
        required: true,
        validator: (/** @type {string} */ value) => ALLOWED_TYPES.includes(value)
      },
      size: {
        type: Number,
        default: 40
      }
    },
    render (createElement) {
      const componentType = this.type === 'spinner'
        ? UiLoaderSpinner
        : UiLoaderPallet

      return createElement(componentType, {
        staticClass: 'ui-loader',
        props: {
          size: this.size
        },
        attrs: this.$attrs
      })
    }
  })
</script>
