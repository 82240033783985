<template>
  <div
    class="tw-flex tw-items-center shipment-item-header-expiration"
    :class="{
      'shipment-item-header-expiration--expiring tw-text-red-500': isExpiring
    }"
  >
    <ui-ctk-icon
      name="expiration"
      data-test="icon"
      class="tw-my-auto tw-mx-0"
    />
    <span
      v-text="$t('app.values.expires_at', {
        date: expiresAt
      })"
      class="tw-hidden 2sm:tw-inline-block tw-my-auto tw-mx-0"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - shipmentItemHeaderExpiration
   * @param {object} shipment
   */
  export default defineComponent({
    name: 'ShipmentItemHeaderExpiration',
    props: {
      shipment: {
        type: Object,
        required: true
      }
    },
    computed: {
      /**
       * Returns true if the shipment will expire in less than one hour.
       * @function isExpiring
       * @returns {boolean}
       */
      isExpiring () {
        return this.$moment(this.shipment.expires_at).diff(this.$moment(), 'minutes') <= 60
      },
      /**
       * Returns the formatted expiration date
       * @function expiresAt
       * @returns {string}
       */
      expiresAt () {
        return this.$moment(this.shipment.expires_at).format('LLL')
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-item-header-expiration {
    .ctk-font {
      font-size: 30px;
    }
  }

</style>
