export default {
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd, D MMMM YYYY HH:mm',
    l: 'D/M/YYYY',
    ll: 'Do MMM',
    lll: 'D MMM YYYY LT',
    llll: 'ddd, MMM D YYYY LT'
  }
}
