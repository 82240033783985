// @ts-nocheck
import actions from './actions'
import mutations from './mutations'
import state from './state'
import getters from './getters'

import creditCardPayment from './credit-card-payment'

export default {
  modules: {
    'credit-card-payment': creditCardPayment,
    namespaced: true
  },
  getters,
  state,
  mutations,
  actions,
  namespaced: true
}
