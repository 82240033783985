// @ts-nocheck
import Vue from 'vue'

export default {
  SET_INVOICES_TOTAL (state, invoicesTotal) {
    state.invoicesTotal = invoicesTotal
  },
  SET_INVOICES (state, invoices) {
    Vue.set(state, 'invoices', invoices)
  },
  ADD_INVOICE (state, invoice) {
    state.invoices.push(invoice)
  },
  REMOVE_INVOICE (state, invoice) {
    const invoiceIndex = state.invoices.findIndex(v => v.uuid === invoice.uuid)
    if (invoiceIndex !== -1) {
      state.invoices.splice(invoiceIndex, 1)
    }
  },
  SET_PAYMENT_INTENT (state, paymentIntent) {
    state.paymentIntent = paymentIntent
  }
}
