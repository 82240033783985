import { isTesting } from '@/../utils/env'

export default class ScriptPlugin {
  constructor () {
    /**
     * @type {string | null}
     */
    this.scriptSrc = null
  }

  loadScript () {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      if (isTesting) return resolve()
      if (!this.scriptSrc) return

      const script = document.createElement('script')
      script.async = true
      script.defer = true
      script.src = this.scriptSrc

      const head = document.head || document.getElementsByTagName('head')[0]
      head.appendChild(script)

      script.onload = resolve
      script.onerror = reject
    })
  }
}
