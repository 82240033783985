var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({staticClass:"ctk-input-select"},'div',_vm.attrs,false),[_c('button',{ref:"button",staticClass:"ctk-input-select__wrapper tw-truncate",class:{
      'is-disabled': _vm.isDisabled,
      'has-value': !!_vm.value,
      'has-error': _vm.error,
      'has-hint': _vm.hint && !_vm.value,
      'has-label': _vm.hasLabel
    },attrs:{"aria-label":_vm.hasLabel ? null : _vm.label,"aria-labelledby":_vm.hasLabel ? (_vm.id + " " + _vm.id + "-value") : null,"aria-expanded":_vm.isOpen,"type":"button","aria-haspopup":"listbox"},on:{"click":_vm.show,"keyup":_vm.checkShow}},[(_vm.hasLabel)?_c('span',{staticClass:"ctk-input-select__wrapper__label tw-truncate tw-pr-4",class:{
        'tw-text-red-500' : _vm.error
      },attrs:{"id":_vm.id,"title":_vm.hintValue || _vm.labelValue},domProps:{"textContent":_vm._s(_vm.hintValue || _vm.labelValue)}}):_vm._e(),_vm._t("currentItem",function(){return [_c('span',{staticClass:"ctk-input-select__wrapper__value tw-truncate",class:{
          'tw-text-red-500' : _vm.error
        },attrs:{"id":(_vm.id + "-value"),"title":_vm.activeItem ? _vm.activeItem.text : _vm.labelValue},domProps:{"textContent":_vm._s(_vm.activeItem ? _vm.activeItem.text : _vm.labelValue)}})]},{"item":_vm.activeItem,"id":_vm.id}),_c('ui-material-icon',{staticClass:"ctk-input-select__wrapper__arrow",attrs:{"name":"arrow_drop_down"}})],2),_c('transition',{attrs:{"name":"slide"}},[_c('ctk-input-select-list',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],ref:"select-list",class:{
        'full-width': _vm.fullWidth
      },attrs:{"id":_vm.id,"open":_vm.isOpen,"items":_vm.formattedItems},on:{"update:open":function($event){_vm.isOpen=$event},"re-focus":_vm.refocus},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_vm._t("item",null,{"item":item})]}}],null,true),model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }