// @ts-nocheck
export default {
  getUsers: state => state.users.items,
  getInvites: state => state.invites.items,
  hasMoreUsers: state => {
    if (!state.users.meta) return

    const { current_page: currentPage, page_count: pageCount } = state.users.meta
    return currentPage + 1 <= pageCount
  },
  hasMoreUserInvites: state => {
    if (!state.invites.meta) return

    const { current_page: currentPage, page_count: pageCount } = state.invites.meta
    return currentPage + 1 <= pageCount
  }
}
