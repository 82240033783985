<template>
  <div
    id="app"
    ref="app"
    class="tw-flex tw-flex-col tw-w-full"
    :class="{
      'tw-h-full': $route.name !== 'Shipment',
      'tw-overflow-hidden md:tw-overflow-visible': ['Offers', 'Searches', 'Offer', 'Proposals'].includes($route.name)
    }"
  >
    <app-header
      :has-navbar="hasNavbar"
      data-test="header"
    />
    <router-view
      :style="{
        marginTop: computedMargin,
      }"
      class="main-container tw-flex-1"
    />
    <transition
      name="fade"
    >
      <div
        v-if="!isAppReady"
        class="load-layer"
      >
        <ui-loader
          background-back="#000"
          type="pallet"
          :size="80"
        />
      </div>
    </transition>

    <!-- Common dialogs -->
    <ctk-locked-account-dialog
      v-if="isAccountLocked"
      v-model="dialogs.accountLocked.visible"
      data-test="locked-account-dialog"
    />
    <ctk-welcome-dialog
      v-if="isAppReady && !!getUserInfos && !isPreRegistered"
      v-model="welcomeVisible"
      data-test="welcome-dialog"
    />
    <ctk-email-validation-dialog
      v-model="dialogs.emailValidation.visible"
      :confirmation="dialogs.emailValidation.confirmation"
      data-test="email-validation-dialog"
    />
    <ctk-locale-dialog
      v-if="hasLocaleDialog"
      :value="hasLocaleDialog"
      data-test="locale-dialog"
    />
    <ctk-search-dialog
      v-model="dialogs.search.visible"
      :source="dialogs.search.source"
      data-test="search-dialog"
    />
    <ctk-notifications-dialog
      v-model="dialogs.notifications.visible"
      data-test="notifications-dialog"
    />
    <ctk-tooltip-dialog
      v-model="dialogs.tooltip.visible"
      :title="dialogs.tooltip.title"
      data-test="tooltip-dialog"
    >
      {{ dialogs.tooltip.content }}
    </ctk-tooltip-dialog>

    <!-- Making the carrier offers dialog available in the whole app -->
    <!-- This is temp. meanwhile we await for the legacy offer pages -->
    <!-- TODO: Remove this when we are done with the migration       -->
    <offer-dialogs />

    <portal-target
      name="dialogs"
      multiple
    />
  </div>
</template>

<script>
  import AppHeader from '@/components/AppHeader/index.vue'
  import CtkLocaleDialog from '@/components/CtkLocaleDialog/index.vue'
  import CtkWelcomeDialog from '@/components/CtkWelcomeDialog/index.vue'
  import CtkEmailValidationDialog from '@/components/CtkEmailValidationDialog/index.vue'
  import CtkSearchDialog from '@/components/CtkSearchDialog/index.vue'
  import CtkNotificationsDialog from '@/components/CtkNotificationsDialog/index.vue'
  import CtkTooltipDialog from '@/components/CtkTooltipDialog/index.vue'
  import CtkLockedAccountDialog from '@/components/CtkLockedAccount/_subs/CtkLockedAccountDialog/index.vue'
  import SmartBanner from '@/services/SmartBanner'
  import OfferDialogs from '@/views/Carriers/Offers/components/OfferDialogs/index.vue'

  import { supportedLocales } from '@/locales/constants'
  import { EventBus } from '@/services/EventBus'
  import Storage from '@/services/Storage'
  import Hotjar from '@/plugins/VueHotjar'

  import { mapGetters, mapActions, mapState } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'App',
    metaInfo () {
      return {
        title: this.title,
        titleTemplate: this.titleTemplate
      }
    },
    components: {
      AppHeader,
      CtkLocaleDialog,
      CtkWelcomeDialog,
      CtkEmailValidationDialog,
      CtkNotificationsDialog,
      CtkSearchDialog,
      CtkTooltipDialog,
      CtkLockedAccountDialog,
      OfferDialogs
    },
    data () {
      return {
        title: null,
        titleTemplate: 'Chronotruck',
        direction: 'pickup',
        dialogs: {
          emailValidation: {
            visible: false,
            confirmation: false
          },
          accountLocked: {
            visible: false
          },
          notifications: {
            visible: false
          },
          search: {
            visible: false,
            source: null
          },
          tooltip: {
            visible: false,
            title: null,
            content: null
          }
        }
      }
    },
    computed: {
      ...mapState('ui', {
        bannersHeight: 'bannersHeight'
      }),
      ...mapGetters([
        'isAppReady',
        'isUserShipper'
      ]),
      ...mapGetters('auth', [
        'getUserInfos',
        'isAccountLocked',
        'isPreRegistered'
      ]),
      ...mapGetters('ui', [
        'isImpersonateVisible',
        'getShowNavBar',
        'isWelcomeVisible'
      ]),
      /**
       * Returns a computed value according to the banners height, the header
       * height and the impersonate height. Used to move the entire content accordingly
       * since the header is fixed.
       * @function computedMargin
       * @returns {string}
       */
      computedMargin () {
        const BASE_AFFIX_HEIGHT = 55
        const IMPERSONATE_HEIGHT = 50

        if (!this.hasNavbar) return '0px'

        let marginTop = BASE_AFFIX_HEIGHT

        if (this.isImpersonateVisible) {
          marginTop += IMPERSONATE_HEIGHT
        }

        // @ts-ignore
        marginTop += this.bannersHeight

        return `${marginTop}px`
      },
      /**
       * @function hasNavbar
       * @returns {boolean}
       */
      hasNavbar () {
        // @ts-ignore
        const { path, meta } = this.$route
        return this.getShowNavBar && path !== '/' && meta && meta.hasNavbar !== false
      },
      /**
       * Returns true if we should prompt the welcome dialog to the user
       * @function welcomeVisible
       * @returns {boolean}
       */
      welcomeVisible: {
        get () {
          return this.isWelcomeVisible
        },
        set (v) {
          this.setWelcomeVisible(v)
        }
      },
      /**
       * Returns true if we must prompt the dialog
       * to the user.
       * @function hasLocaleDialog
       * @returns {boolean}
       */
      hasLocaleDialog () {
        if (!this.getUserInfos) return false

        const storageLocale = Storage && Storage.getItem('userLocale')
        const locales = supportedLocales.map(v => v.locale)

        const isLocaleSupported = locales.includes(this.getUserInfos.locale.slice(0, 2))
        const isStorageLocaleSupported = storageLocale &&
          locales.includes(storageLocale.slice(0, 2))

        return isLocaleSupported
          ? false
          : !isStorageLocaleSupported
      }
    },
    created () {
      this.$router.onReady(() => {
        this.title = ''
        this.titleTemplate = 'Chronotruck - %s'
      })
    },
    mounted () {
      EventBus.$on('dialogs:email-validation', (/** @type {boolean} */ confirmation) => {
        this.dialogs.emailValidation = {
          visible: true,
          confirmation
        }
      })
      EventBus.$on('dialogs:account-locked', () => {
        this.dialogs.accountLocked.visible = true
      })
      EventBus.$on('dialogs:notifications', () => {
        const { visible } = this.dialogs.notifications
        if (!visible) {
          this.setNotificationsAsRead()
        }

        this.dialogs.notifications.visible = true
      })

      EventBus.$on('dialogs:search', (source) => {
        this.dialogs.search.visible = true
        this.dialogs.search.source = source
      })

      EventBus.$on('dialogs:tooltip', ({ title, content }) => {
        this.dialogs.tooltip = {
          visible: true,
          title,
          content
        }
      })

      EventBus.$on('auth:authentified', (data) => {
        const roles = data.companies[0].roles

        if (!roles.includes('COMPANY')) {
          const $t = this.$t.bind(this)
          SmartBanner($t)
        }
      })

      Hotjar.inject()
    },
    methods: {
      ...mapActions('ui', [
        'setWelcomeVisible'
      ]),
      ...mapActions([
        'setNotificationsAsRead'
      ])
    },
    beforeDestroy () {
      const events = ['auth:authentified', 'dialogs:search', 'dialogs:notifications', 'dialogs:account-locked', 'dialogs:email-validation']
      events.forEach(event => {
        EventBus.$off(event)
      })
    }
  })
</script>

<style lang="scss" scoped>

  #app {
    position: relative;
    min-height: 100%;

    .load-layer {
      background: rgba(255, 255, 255, 1);
      z-index: 999;
    }

    .app-header {
      position: fixed;
      top: 0;
      left: 0;
    }

    .main-container {
      overflow-y: hidden;
    }
  }

</style>
