import Vue from 'vue'

/**
 * @function useMatomo
 * @returns {{
 *  trackEvent: Function
 * }}
 */
export default function useMatomo () {
  return Vue.prototype.$matomo
}
