import { getCurrentInstance } from '@vue/composition-api'

/**
 * @function useWait
 * @returns {import('vue-wait').VueWaitInstance}
 */
export default function useWait () {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('Must be called in setup')

  // @ts-ignore
  return vm.proxy.$wait
}
