<template>
  <button
    :class="{
      'ctk-alert-banner--danger': variant === 'danger',
      'ctk-alert-banner--warning': variant === 'warning',
      'ctk-alert-banner--info': variant === 'info'
    }"
    class="ctk-alert-banner tw-flex tw-items-center tw-border-none focus:tw-outline-none tw-appearance-none tw-p-0 tw-w-full"
  >
    <div class="tw-flex tw-h-full tw-w-full">
      <ctk-alert-banner-icon
        :variant="variant"
        data-test="icon"
      />
      <div class="ctk-alert-banner__content tw-text-left tw-px-4 tw-leading-snug tw-self-center tw-py-2">
        <div
          class="tw-text-xl tw-font-bold"
          v-text="title"
          data-test="title"
        />
        <div
          class="tw-text-sm"
          data-test="content"
        >
          <slot />
        </div>
      </div>
    </div>
  </button>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkAlertBannerIcon from './_subs/CtkAlertBannerIcon/index.vue'

  /**
   * @module component - CtkAlertBanner
   * @param {string} title
   * @param {'danger'|'warning'|'info'} variant
   */
  export default defineComponent({
    name: 'CtkAlertBanner',
    components: {
      CtkAlertBannerIcon
    },
    props: {
      title: {
        type: String,
        required: true
      },
      variant: {
        type: String,
        required: true
      }
    }
  })
</script>

<style lang="scss" scoped>
.ctk-alert-banner--danger a, .ctk-alert-banner--danger a:hover {
  --tw-text-opacity: 1;
  color: rgba(118, 51, 54, var(--tw-text-opacity));
}
.ctk-alert-banner--warning a, .ctk-alert-banner--warning a:hover {
  --tw-text-opacity: 1;
  color: rgba(137, 111, 45, var(--tw-text-opacity));
}
.ctk-alert-banner--info a, .ctk-alert-banner--info a:hover {
  --tw-text-opacity: 1;
  color: rgba(29, 86, 110, var(--tw-text-opacity));
}
.ctk-alert-banner--danger {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 204, 209, var(--tw-bg-opacity));
}
.ctk-alert-banner--danger .ctk-alert-banner__content {
  --tw-text-opacity: 1;
  color: rgba(118, 51, 54, var(--tw-text-opacity));
}
.ctk-alert-banner--warning {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 229, 203, var(--tw-bg-opacity));
}
.ctk-alert-banner--warning .ctk-alert-banner__content {
  --tw-text-opacity: 1;
  color: rgba(137, 111, 45, var(--tw-text-opacity));
}
.ctk-alert-banner--info {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 235, 240, var(--tw-bg-opacity));
}
.ctk-alert-banner--info .ctk-alert-banner__content {
  --tw-text-opacity: 1;
  color: rgba(29, 86, 110, var(--tw-text-opacity));
}
.ctk-alert-banner {
  min-height: 75px;
}
</style>
