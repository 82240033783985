/**
 * @function storage
 * @returns {WindowLocalStorage|undefined}
 */
const storage = (() => {
  /** @type {string} */
  const uid = new Date().toString()
  let storage

  /* eslint-disable */
  try {
    (storage = window.localStorage).setItem(uid, uid)
    storage.removeItem(uid)
  } catch (exception) {}
  /* eslint-enable */

  return storage
})()

export default storage
