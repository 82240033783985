<template functional>
  <div
    class="ctk-item-cancellation-date"
    :class="data.staticClass"
    :style="data.staticStyle"
  >
    <div class="tw-flex tw-flex-col">
      <div class="tw-flex tw-items-center ctk-item-cancellation-date__label">
        <div class="ctk-item-cancellation-date__label__icon tw-flex tw-mr-1 tw-text-center">
          <ui-material-icon
            name="close"
            data-test="icon"
          />
        </div>
        <span
          v-text="props.label"
          class="tw-truncate"
        />
      </div>
      <div
        class="tw-text-xs ctk-item-cancellation-date__value tw-truncate"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
  /**
   * @module component - ctkItemCancellationDate
   * @param {string} label
   */
  export default {
    name: 'CtkItemCancellationDate'
  }
</script>

<style lang="scss" scoped>

  .ctk-item-cancellation-date {
    & > div:first-child {
      position: relative;
      width: 70%;
      margin: auto;

      &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 20px;
        top: 10px;
        left: -16px;
        border: 1px solid $third-text;
        border-right: none;
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        width: 100%;

        &::after {
          display: none;
        }
      }
    }

    &__label {
      color: $danger;

      &__icon {
        background-color: $danger;
        color: white;
        width: 1em;
        height: 1em;
        line-height: 1em;
        border-radius: 1em;

        .material-icons {
          font-size: 1em;
          margin: auto;
          vertical-align: middle;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      text-align: right;

      &__label {
        justify-content: flex-end;
      }

      &.--expired {
        padding-right: 32px;
      }

      &.--expired::after {
        right: 8px;
        left: auto;
        border-left: none;
        border-right: 1px solid $third-text;
      }
    }
  }

</style>
