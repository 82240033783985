<template>
  <div class="offers-suggestion-list-navigation pr-2">
    <button
      :title="$t('offer_list.main_content.text_button.navigation.previous')"
      :disabled="prevDisabled"
      class="offers-suggestion-list-navigation-button prev p-0"
      @click="prev"
    >
      <ui-material-icon
        name="chevron_left"
        data-test="left-icon"
      />
    </button>

    <button
      :title="$t('offer_list.main_content.text_button.navigation.next')"
      :disabled="nextDisabled"
      class="offers-suggestion-list-navigation-button next p-0"
      @click="next"
    >
      <ui-material-icon
        name="chevron_right"
        data-test="right-icon"
      />
    </button>
  </div>
</template>

<script>
  /**
   * @module component - offersSuggestionListNavigation
   * @param {object} carousel - The slider element
   */
  export default {
    name: 'OffersSuggestionListNavigation',
    props: {
      carousel: {
        type: Object,
        required: true
      }
    },
    computed: {
      /**
       * @function prevDisabled
       * @returns {boolean} disabled
       */
      prevDisabled () {
        return this.carousel.currentPage === 0
      },
      /**
       * @function nextDisabled
       * @returns {boolean} disabled
       */
      nextDisabled () {
        return this.carousel.currentPage >= this.carousel.pageCount - 1
      }
    },
    methods: {
      /**
       * @function next
       */
      next () {
        if (this.carousel) {
          const page = this.carousel.getNextPage()
          this.carousel.goToPage(page)
        }
      },
      /**
       * @function prev
       */
      prev () {
        if (this.carousel) {
          const page = this.carousel.getPreviousPage()
          this.carousel.goToPage(page)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .offers-suggestion-list-navigation {
    margin-top: auto;

    &-button {
      cursor: pointer;
      border: none;
      background: none;
      color: $primary;

      &[disabled=disabled] {
        opacity: 0.2;
        cursor: not-allowed;
      }
    }
  }

</style>
