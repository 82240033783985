<template>
  <b-modal
    v-model="open"
    modal-class="dialog-cancel-proposal"
    centered
  >
    <template #modal-header>
      <div
        class="tw-w-full tw-flex tw-justify-between"
      >
        <h5 class="m-0 modal-title">
          {{ $t('cancel_proposal.title') | capitalize }}
        </h5>

        <ui-button
          :title="$t('close') | capitalize"
          class="close-modal"
          fab
          @click="open = false"
        >
          <ui-ctk-icon
            name="close"
            class="tw-block"
            data-test="icon"
          />
        </ui-button>
      </div>
    </template>

    <p class="content tw-text-center my-4">
      {{ $t('cancel_proposal.explanation') }}
    </p>

    <ctk-info-container>
      <p>
        {{ $t('cancel_proposal.info') }}
      </p>
    </ctk-info-container>

    <div class="illustration" />

    <template #modal-footer>
      <div
        class="tw-w-full footer tw-flex tw-items-center tw-justify-between"
      >
        <ui-button
          variant="link"
          class="cancel-button mr-2"
          @click="open = false"
        >
          {{ $t('cancel') | capitalize }}
        </ui-button>
        <ui-button
          :disabled="cancelling"
          class="confirm-button"
          variant="primary"
          @click="confirm"
        >
          {{ $t('confirm') | capitalize }}
        </ui-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import { Offer } from '@/resources'
  import CtkInfoContainer from '@/components/CtkInfoContainer/index.vue'
  import { EventBus } from '@/services/EventBus'
  import { showToaster } from '@/services/Toaster'

  /**
   * Single component to show the dialog for proposal cancellation.
   * @module component - dialogCancelProposal
   * @param {object} proposal - The proposal object
   * @param {object} proposal.uuid - Unique proposal identifier
   * @param {boolean} [canResetCurrentOffer=false] - Specify if after the request, should reset the current offer
   *
   * @emits input
   * @emits confirm
   *
   * @example
   * <dialog-cancel-proposal v-model="visible" @confirm="cancelProposal" />
   */
  export default defineComponent({
    name: 'DialogCancelProposal',
    components: {
      CtkInfoContainer
    },
    props: {
      proposal: {
        type: Object,
        required: true
      },
      value: {
        type: Boolean,
        default: false
      },
      canResetCurrentOffer: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        cancelling: false
      }
    },
    setup (props) {
      const { state: open } = useModelGetterSetter(props, 'value')

      return {
        open
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('offers', [
        'getCurrentOffer'
      ])
    },
    methods: {
      ...mapActions('offers', [
        'cancelProposal',
        'resetCurrentOffer',
        'setProposalKey',
        'updateCurrentOffer',
        'deleteProposalInProposalsWithId',
        'addKeyValueToAnOffer'
      ]),
      /**
       * @function confirm
       */
      confirm () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Offers', 'Confirmed Proposal Cancellation', this.getCurrentOffer.uuid)
        }

        this.cancelling = true
        this.cancelProposal(this.proposal)
          .then(async () => {
            this.setProposalKey({
              proposal: this.proposal,
              key: 'status',
              value: 'cancelled'
            })

            /**
             * Update the offer's proposal
             */
            if (this.getCurrentOffer) {
              this.addKeyValueToAnOffer({
                offerUuid: this.getCurrentOffer.uuid,
                key: 'proposal',
                value: {
                  ...this.proposal,
                  status: 'cancelled'
                }
              })

              try {
                /**
                 * TODO: Use the already-existing retrieveOffer action instead of having
                 * this duplicated code. Plus, with the retrieveOffer, the "updateCurrentOffer"
                 * below will be unnecessary as it's already done in that action.
                 */
                const response = await Offer.get({
                  oid: this.getCurrentOffer.uuid,
                  cid: this.getCid
                })

                if (response) {
                  this.updateCurrentOffer(response.data)

                  /**
                   * Update the offer in the list
                   */
                  const {
                    proposal_count: proposalCount,
                    lowest_proposal_price: lowestProposalPrice
                  } = response.data

                  this.addKeyValueToAnOffer({
                    offerUuid: this.getCurrentOffer.uuid,
                    key: 'proposal_count',
                    value: proposalCount
                  })

                  if (typeof lowestProposalPrice !== 'undefined') {
                    this.addKeyValueToAnOffer({
                      offerUuid: this.getCurrentOffer.uuid,
                      key: 'lowest_proposal_price',
                      value: lowestProposalPrice
                    })
                  }
                }
              } catch (err) {
                console.error('Could not update the offer', err)
              }
            }

            showToaster(this, this.$t('proposal_cancelled'), { type: 'success' })
            this.$emit('input', false)
            EventBus.$emit('refresh-proposal-section')

            setTimeout(() => {
              this.deleteProposalInProposalsWithId(this.proposal.uuid)
              if (this.canResetCurrentOffer) {
                this.resetCurrentOffer()
              }
            }, 1000)
          })
          .catch(err => {
            if (!err.response) return

            const { data } = err.response
            if (data && data.error) {
              const errorMessage = data.error.detail || data.error.title
              showToaster(this, errorMessage, {
                type: 'error',
                position: 'bottom-right'
              })
            }
          })
          .finally(() => {
            this.cancelling = false
          })
      }
    }
  })
</script>

<style lang="scss" scoped>

  .dialog-cancel-proposal {
    .close-modal {
      background-color: transparent;
    }
  }

</style>
