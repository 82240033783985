<template>
  <div class="choose-driver-form tw-flex tw-flex-col tw-max-h-full">
    <template
      v-if="isUserMono"
    >
      <div
        class="tw-flex tw-flex-col tw-items-center tw-text-center tw-flex-1"
        data-test="mono-confirmation"
      >
        <img
          src="@/assets/img/icons/register/truck/completed-animated.svg"
          class="tw-mb-4"
          width="80"
          height="73"
          alt=""
          data-test="icon"
        >

        <p
          v-text="$t('are_you_sure_to_book_this_offer')"
          class="tw-text-xl"
          data-test="content"
        />
      </div>
    </template>

    <template
      v-else
    >
      <choose-driver-list
        v-model="selectedDriver"
        :drivers="drivers"
        :drivers-count="driversCount"
        data-test="list"
      />
    </template>

    <div
      class="choose-driver-form__footer tw-w-full tw-py-3 tw-mt-4"
      data-test="footer"
    >
      <div
        :class="true ? 'tw-justify-end' : 'tw-justify-between'"
        class="footer-buttons tw-flex tw-flex-col-reverse md:tw-flex-row tw-items-center tw-justify-between"
      >
        <ui-button
          class="tw-w-full md:tw-w-auto tw-mt-4 md:tw-mt-0"
          variant="link"
          data-test="cancel-button"
          @click="$emit(summaryOptionsNeeded.length ? 'back' : 'close')"
        >
          {{ (summaryOptionsNeeded.length ? $t('back') : $t('cancel')) | capitalize }}
        </ui-button>
        <ui-button
          id="bookingBtnModal"
          :disabled="!isUserMono && !selectedDriver"
          class="tw-w-full md:tw-w-auto"
          variant="primary"
          data-test="validate-button"
          @click="$emit('validate', selectedDriver)"
        >
          <slot name="text-button" />
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent, ref, toRefs, watch } from '@vue/composition-api'

  import ChooseDriverList from './_subs/ChooseDriverList/index.vue'
  import useStore from '@/composables/useStore'

  export default defineComponent({
    name: 'CtkChooseDriverForm',
    components: {
      ChooseDriverList
    },
    props: {
      driversCount: {
        type: Number,
        default: null
      },
      drivers: {
        type: Array,
        default: Array
      },
      summaryOptionsNeeded: {
        type: Array,
        default: () => ([])
      }
    },
    emits: [
      'validate',
      'back',
      'close'
    ],
    setup (props) {
      const { drivers } = toRefs(props)
      const store = useStore()

      const selectedDriver = ref(null)
      const isUserMono = computed(() => store.value.getters.isUserMono)

      watch(drivers, () => {
        if (drivers.value.length === 1) {
          selectedDriver.value = drivers.value[0]
        }
      })

      return {
        selectedDriver,
        isUserMono
      }
    }
  })
</script>
