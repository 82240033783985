export default () => ({
  users: {
    items: [],
    meta: null
  },
  invites: {
    items: [],
    meta: null
  }
})
