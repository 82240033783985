// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class QuotationPriceResource extends AxiosResource {
  constructor () {
    super()

    this.path = 'v2/companies/{cid}/quotation-prices{/pid}'
  }

  acceptation (...args) {
    return this.action('POST', '/acceptation', ...args)
  }
}
