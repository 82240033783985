var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ctk-nav-bar-actions tw-flex tw-flex-col md:tw-flex-row"},[(!_vm.isPreRegistered)?_c('router-link',{staticClass:"ctk-nav-bar-actions__button tw-mb-2 md:tw-mb-0 hover:tw-no-underline",attrs:{"to":{
      name: 'Faq'
    }},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('ui-ctk-icon',{attrs:{"name":"help"}}),_c('span',{staticClass:"tw-hidden lg:tw-block",domProps:{"textContent":_vm._s(_vm.$options.filters.capitalize(_vm.$t('navigation.faq')))}})],1):_vm._e(),(_vm.isUserShipper)?[_c('button',{directives:[{name:"matomo",rawName:"v-matomo",value:({
        click: {
          category: 'Shipments',
          action: 'Initiated Search'
        }
      }),expression:"{\n        click: {\n          category: 'Shipments',\n          action: 'Initiated Search'\n        }\n      }"}],ref:"search-button",staticClass:"ctk-nav-bar-actions__button tw-mb-2 md:tw-mb-0",attrs:{"title":_vm.$t('app.buttons.search'),"type":"button"},on:{"click":_vm.search}},[_c('ui-ctk-icon',{attrs:{"name":"search"}}),_c('span',{staticClass:"tw-hidden",domProps:{"textContent":_vm._s(_vm.$t('app.buttons.search'))}})],1),_c('button',{directives:[{name:"matomo",rawName:"v-matomo",value:({
        click: {
          category: 'Global',
          action: 'Notifications',
          name: 'Clicked'
        }
      }),expression:"{\n        click: {\n          category: 'Global',\n          action: 'Notifications',\n          name: 'Clicked'\n        }\n      }"}],ref:"notifications-button",staticClass:"ctk-nav-bar-actions__button",attrs:{"title":_vm.$t('app.buttons.notifications'),"type":"button"},on:{"click":_vm.notifications}},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"tw-relative tw-flex"},[_c('ui-ctk-icon',{attrs:{"name":"alert-on"}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.getPendingNotifications.length > 0)?_c('div',{staticClass:"tw-absolute tw-top-0 tw-right-0 tw-bg-red-500 tw-text-white tw-rounded-full tw-w-4 tw-h-4 tw-text-xs",domProps:{"textContent":_vm._s(_vm.$n(_vm.getPendingNotifications.length))}}):_vm._e()])],1),_c('span',{staticClass:"tw-hidden",domProps:{"textContent":_vm._s(_vm.$t('app.buttons.notifications'))}})])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }