export default () => ({
  estimation: null,
  expressQuote: {
    load: null,
    address: {
      pickup: null,
      delivery: null
    }
  },
  shipment: null,
  dashboard: {
    metrics: {
      shipment_count: null,
      average_distance: null,
      average_weight: null,
      average_linear_centimeters: null,
      average_price: null,
      co2_emissions: null
    }
  },
  shipments: {
    items: [],
    meta: {
      item_count: 0
    },
    metrics: {
      counts: null
    },
    filters: {
      has_pending_proposals: 0,
      expires_in: null,
      tracking_to_pickup: 0,
      tracking_to_deliver: 0,
      tracking_to_upload_pod: 0
    }
  }
})
