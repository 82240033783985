<template>
  <div
    :class="{
      sidebar: inSidebar
    }"
    class="offers-suggestion-container"
  >
    <div class="offers-suggestion-container-header tw-flex tw-justify-between">
      <h2
        class="offers-suggestion-container-title m-0 tw-flex-1 tw-text-lg tw-font-light tw-text-secondary"
        v-text="$tc('offer_list.suggestions.title', getOffersSuggestions.length)"
      />
      <offers-suggestion-list-navigation
        v-if="carousel"
        :carousel="carousel"
      />
    </div>

    <offers-suggestion-list
      ref="carousel"
      :in-sidebar="inSidebar"
      :per-page-fixed="true"
      :per-page-custom="perPage"
      :space-padding="inSidebar ? 32 : 64"
      :space-padding-factor="1"
      :initial-space-padding="inModal ? 30 : 0"
      @select="select"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapActions, mapGetters } from 'vuex'

  import OffersSuggestionList from './_subs/OffersSuggestionList/index.vue'
  import OffersSuggestionListNavigation from './_subs/OffersSuggestionList/_subs/OffersSuggestionListNavigation.vue'
  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - offersSuggestionContainer
   * @param {boolean} [inSidebar=false] - Specify if the container is inside the sidebar.
   * @param {boolean} [inModal=false] - Specify if the container is inside the confirmation modal.
   */
  export default defineComponent({
    name: 'OffersSuggestionContainer',
    components: {
      OffersSuggestionList,
      OffersSuggestionListNavigation
    },
    props: {
      inSidebar: {
        type: Boolean,
        default: false
      },
      inModal: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        carousel: null
      }
    },
    computed: {
      ...mapGetters('offers', [
        'getOffersSuggestions', 'getCurrentOffer'
      ]),
      /**
       * Returns the multiples breakpoints possible according to the section.
       * @function perPage
       * @returns {Array<Array<number>>}
       */
      perPage () {
        let breakpoints = [[0, 1], [760, 2], [1280, 3]]
        if (this.inSidebar) {
          breakpoints = [[0, 1]]
        } else if (this.inModal) {
          breakpoints = [[0, 1], [760, 2]]
        } else if (this.getCurrentOffer && this.getCurrentOffer.uuid) {
          /**
           * When the offer's sidebar is open
           */
          breakpoints = [[0, 1], [760, 2]]
        }

        return breakpoints
      },
      /**
       * Returns where the component is on the page
       * @function orgiin
       */
      origin () {
        let origin = 'list'
        if (this.inModal) origin = 'modal'
        if (this.inSidebar) origin = 'sidebar'

        return origin
      }
    },
    mounted () {
      if (this.$refs.carousel) {
        this.carousel = this.$refs.carousel.$children[0]
      }
    },
    methods: {
      ...mapActions('offers', [
        'setOffersSuggestionsVisibility'
      ]),
      /**
       * Called whenever the user selects an offer
       * @function select
       * @param {object} offer
       */
      select (offer) {
        if (this.$matomo) {
          this.$matomo.trackEvent('Offers', 'Similar Clicked', offer.uuid)
        }

        EventBus.$emit('layout-update')
        this.$emit('select')
      }
    }
  })
</script>

<style lang="scss" scoped>

  .offers-suggestion-container {
    &-header {
      padding: 20px 30px 8px 30px;
    }

    &.sidebar {
      padding: 0;

      .offers-suggestion-container {
        &-header {
          padding: 16px;
        }
      }
    }
  }

</style>
