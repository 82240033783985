<template>
  <address
    class="ctk-address tw-mb-0"
  >
    <template
      v-if="inline"
    >
      {{ address.street_name }}, {{ address.postal_code }} {{ address.city }}, {{ $t(address.country) }}
    </template>
    <template v-else>
      <template
        v-if="address.street_name && hasStreetName"
      >
        <div
          v-text="address.street_name"
          data-test="street-name"
        />
      </template>
      <div
        class="tw-flex tw-items-center tw-truncate"
      >
        <ui-flag
          v-if="hasFlag"
          :country="address.country"
          inline
          data-test="flag"
          class="tw-align-middle"
        />
        <span
          class="ctk-address__content tw-truncate"
          data-test="postal-code-city"
        >
          <slot name="name" />
          {{ address.postal_code }} {{ address.city }}
          <slot name="country">
            <span
              v-if="!hasFlag"
              data-test="country"
            >
              <br>
              {{ $t(address.country) }}
            </span>
          </slot>
        </span>
      </div>
    </template>
  </address>
</template>

<script>
  /**
   * Show the address
   * @module component - ctk-address
   * @param {boolean} [hasStreetName=true] - Specify if we show the street name
   * @param {boolean} [hasFlag=true] - Specify if we show the country with a flag or plain text
   * @param {boolean} [inline=false] - Specify if the address should be formatted in-line
   * @param {object} address
   * @param {string} [address.street_name] - The street name, may be undefined
   * @param {string} address.city
   * @param {string} address.country
   * @param {string} address.postal_code
   */
  export default {
    name: 'CtkAddress',
    props: {
      hasStreetName: {
        type: Boolean,
        default: true
      },
      inline: {
        type: Boolean,
        default: false
      },
      hasFlag: {
        type: Boolean,
        default: true
      },
      address: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-address {
    &__content {
      vertical-align: middle;
    }
  }

</style>
