export default {
  longDateFormat: {
    LT: 'HH[h]mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm',
    l: 'M/D/YYYY',
    ll: 'D MMM',
    lll: 'D MMM YYYY LT',
    llll: 'ddd, MMM D YYYY LT'
  }
}
