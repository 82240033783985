// @ts-nocheck
import AxiosResource from './AxiosResource'

export default class MeMissionResource extends AxiosResource {
  constructor () {
    super()

    this.path = '/v2/me/missions{/mid}'
  }

  metrics (...args) {
    const metrics = new AxiosResource()
    metrics.path = '/v2/me/metrics/missions'
    return metrics.get(...args)
  }
}
