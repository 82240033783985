import axios from 'axios'

import { i18n } from '@/locales'
// @ts-ignore
import { version } from '@/../config/chronoinfos'

/**
 * @type {{
 *   state: () => any
 *   getters: {[key: string]: (state: any) => any},
 *   actions: {[key: string]: (context: any, payload: any) => any},
 *   mutations: {[key: string]: (state: any, payload: any) => void},
 * }}
 */
const VuexModule = {
  state () {
    return {
      interval: null,
      requiresUpdate: false
    }
  },
  getters: {
    'update/getInterval': state => state.interval,
    'update/isUpdateRequired': state => state.requiresUpdate
  },
  actions: {
    'update/setInterval': ({ commit }, interval) => commit('update/SET_INTERVAL', interval),
    'update/setRequiresUpdate': ({ commit, getters }, requires) => {
      commit('update/SET_REQUIRES_UPDATE', requires)

      if (requires && getters['update/getInterval']) {
        clearInterval(getters['update/getInterval'])
        commit('update/SET_INTERVAL', null)
      }
    }
  },
  mutations: {
    'update/SET_INTERVAL' (state, interval) {
      state.interval = interval
    },
    'update/SET_REQUIRES_UPDATE' (state, requires) {
      state.requiresUpdate = requires
    }
  }
}

class VueUpdate {
  /**
   * @constructor
   * @param {{ vue: import('vue').VueConstructor, store: import('vuex').Store<typeof VuexModule.state> }} options
   */
  constructor ({ vue, store }) {
    this.store = store

    this.store.registerModule('update', VuexModule)

    if (!store.getters['update/getInterval']) {
      const updateInterval = setInterval(async () => {
        axios.get('/config.json')
          .then(({ status, data }) => {
            if (status === 200) {
              const publicVersion = data.version

              if (version !== publicVersion) {
                if (vue.prototype.$matomo) {
                  vue.prototype.$matomo.trackEvent('Global', 'Update', 'Displayed')
                }

                store.dispatch('update/setRequiresUpdate', true)

                const message = i18n.t('app.paragraphs.update_available')
                vue.toasted.info(message.toString(), {
                  type: 'info',
                  position: 'bottom-left',
                  action: {
                    text: i18n.t('app.buttons.reload').toString(),
                    onClick: () => {
                      if (vue.prototype.$matomo) {
                        vue.prototype.$matomo.trackEvent('Global', 'Update', 'Refresh')
                      }

                      window.location.reload()
                    }
                  }
                })
              } else {
                store.dispatch('update/setRequiresUpdate', false)
              }
            }
          })
          .catch(() => {})
      }, 15000)
      store.dispatch('update/setInterval', updateInterval)
    }
  }
}

export default {
  /**
   * @function install
   * @param {import('vue').VueConstructor} Vue
   */
  install (Vue) {
    Vue.mixin({
      beforeCreate () {
        const { store, parent } = this.$options
        const storeToUse = store || (parent && parent.$store)

        if (!storeToUse) { return }
        if (Vue.prototype.$update) { return }

        const update = new VueUpdate({
          vue: Vue,
          store: storeToUse
        })

        Vue.prototype.$update = update
      }
    })
  }
}
