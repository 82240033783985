import store from '@/store'

export default (/** @type {import('axios').AxiosRequestConfig} */ config) => {
  const token = store.getters['auth/getToken']
  // @ts-ignore
  const isMockApiRequest = config.url.includes('mockapi')

  let authorization = null
  if (isMockApiRequest) {
    authorization = 'Bearer goodDispatcherDriverToken'
  } else if (token) {
    authorization = `Bearer ${token}`
  }

  if (authorization && config.headers) {
    config.headers.Authorization = authorization
  }

  return config
}
