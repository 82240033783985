// @ts-nocheck
export default {
  /**
   * Returns a list of documents sorted by their required attribute.
   * Required documents will be on the top of the list.
   * @function getDocuments
   * @returns {Array}
   */
  getDocuments (state) {
    return Array.from(state.documents)
      .sort((a, b) => b.required - a.required)
  }
}
