import Location from './../Location'

export default class Driver {
  constructor ({
    uuid,
    state,
    first_name: firstName,
    last_name: lastName,
    phone,
    email,
    location
  }) {
    this.uuid = uuid
    this.state = state
    this.first_name = firstName
    this.last_name = lastName
    this.phone = phone
    this.email = email

    if (location) {
      this.location = new Location(location)
    }
  }

  get fullName () {
    return `${this.first_name.trim()} ${this.last_name.trim()}`
  }
}
