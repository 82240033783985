<template>
  <div class="ctk-nav-bar-mobile-sidebar">
    <div
      class="ctk-nav-bar-mobile-sidebar__content"
    >
      <div
        v-if="!!$slots.navigation"
        class="ctk-nav-bar-mobile-sidebar__content-block"
      >
        <slot name="navigation" />
      </div>
      <div
        v-if="!!$slots.actions"
        class="ctk-nav-bar-mobile-sidebar__content-block"
      >
        <slot name="actions" />
      </div>
      <div
        v-if="!!$slots['user-menu']"
        class="ctk-nav-bar-mobile-sidebar__content-block"
      >
        <slot name="user-menu" />
      </div>
    </div>
    <div
      class="ctk-nav-bar-mobile-sidebar__backdrop"
      @click="close"
    />
  </div>
</template>

<script>
  /**
   * @module component - CtkNavBarMobileSidebar
   */
  export default {
    name: 'CtkNavBarMobileSidebar',
    methods: {
      close () {
        this.$emit('close')
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-nav-bar-mobile-sidebar {
    background-color: rgba($secondary, 0.8);
    height: 100vh;

    &__content {
      overflow-y: auto;
      background-color: $secondary;
      width: 85%;
      width: calc(100% - 60px);
      height: 100%;
      max-width: 450px;

      &-block {
        border-bottom: 1px solid #303144;
        padding: 20px 0;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    &__backdrop {
      position: absolute;
      right: 0;
      top: 0;
      width: 15%;
      width: calc(61px - 1px);
      min-width: calc(100% - 450px);
      height: 100%;
    }
  }

  .navigation-sidebar-transition {
    &-enter-active,
    &-leave-active {
      .ctk-nav-bar-mobile-sidebar__content {
        transition: opacity 200ms, transform 200ms ease-in-out;
      }
    }

    &-enter {
      .ctk-nav-bar-mobile-sidebar__content {
        transform: scale(1.1) translateX(-100%);
      }
    }

    &-enter-to {
      .ctk-nav-bar-mobile-sidebar__content {
        transform: translateX(0) scale(1);
      }
    }

    &-leave-to {
      .ctk-nav-bar-mobile-sidebar__content {
        transform: scale(1.1) translateX(-100%);
      }
    }

    &-enter,
    &-leave-to {
      .ctk-nav-bar-mobile-sidebar__content {
        opacity: 0;
      }
    }
  }

</style>
