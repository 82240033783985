import MeResource from './MeResource'
import AuthResource from './AuthResource'
import EstimationRequestResource from './EstimationRequestResource'
import DriverResource from './DriverResource'
import AddressResource from './AddressResource'
import QuotationPriceResource from './QuotationPriceResource'
import QuotationResource from './QuotationResource'
import CompanyMissionResource from './CompanyMissionResource'
import MeMissionResource from './MeMissionResource'
import ShipmentResource from './ShipmentResource'
import ShipmentProposalResource from './ShipmentProposalResource'
import InvoiceResource from './InvoiceResource'
import PreInvoiceResource from './PreInvoiceResource'
import PaymentResource from './PaymentResource'
import CompanyResource from './CompanyResource'
import SearchResource from './SearchResource'
import OfferResource from './OfferResource'
import OfferProposalResource from './OfferProposalResource'
import OfferBookingResource from './OfferBookingResource'
import ProposalResource from './ProposalResource'
import OnboardingResource from './OnboardingResource'
import PaymentIntentResource from './PaymentIntentResource'
import PaymentSourceResource from './PaymentSourceResource'
import RegistrationResource from './RegistrationResource'
import UserInviteResource from './UserInviteResource'
import UserResource from './UserResource'

export const Me = new MeResource()
export const Auth = new AuthResource()
export const EstimationRequest = new EstimationRequestResource()
export const Driver = new DriverResource()
export const Address = new AddressResource()
export const QuotationPrice = new QuotationPriceResource()
export const Quotation = new QuotationResource()
export const CompanyMission = new CompanyMissionResource()
export const MeMission = new MeMissionResource()
export const Shipment = new ShipmentResource()
export const ShipmentProposal = new ShipmentProposalResource()
export const Invoice = new InvoiceResource()
export const PreInvoice = new PreInvoiceResource()
export const Payment = new PaymentResource()
export const Company = new CompanyResource()
export const Search = new SearchResource()
export const Offer = new OfferResource()
export const OfferProposal = new OfferProposalResource()
export const OfferBooking = new OfferBookingResource()
export const Proposal = new ProposalResource()
export const Onboarding = new OnboardingResource()
export const PaymentIntent = new PaymentIntentResource()
export const PaymentSource = new PaymentSourceResource()
export const Registration = new RegistrationResource()
export const UserInvite = new UserInviteResource()

export const User = new UserResource()
