<template>
  <span
    :title="value"
    class="hover-on-croppable"
    v-text="value"
  />
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - HoverOnCroppable
   */
  export default defineComponent({
    name: 'HoverOnCroppable',
    props: {
      value: {
        type: String,
        required: true
      }
    }
  })
</script>
