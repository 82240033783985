<template>
  <div class="ctk-nav-bar-actions tw-flex tw-flex-col md:tw-flex-row">
    <router-link
      v-if="!isPreRegistered"
      :to="{
        name: 'Faq'
      }"
      data-test="faq-link"
      class="ctk-nav-bar-actions__button tw-mb-2 md:tw-mb-0 hover:tw-no-underline"
      @click.native="$emit('close')"
    >
      <ui-ctk-icon
        name="help"
        data-test="icon"
      />
      <span
        v-text="$options.filters.capitalize($t('navigation.faq'))"
        class="tw-hidden lg:tw-block"
        data-test="content"
      />
    </router-link>

    <template
      v-if="isUserShipper"
    >
      <button
        ref="search-button"
        :title="$t('app.buttons.search')"
        class="ctk-nav-bar-actions__button tw-mb-2 md:tw-mb-0"
        type="button"
        data-test="search"
        v-matomo="{
          click: {
            category: 'Shipments',
            action: 'Initiated Search'
          }
        }"
        @click="search"
      >
        <ui-ctk-icon
          name="search"
        />
        <span
          class="tw-hidden"
          v-text="$t('app.buttons.search')"
        />
      </button>

      <button
        ref="notifications-button"
        :title="$t('app.buttons.notifications')"
        class="ctk-nav-bar-actions__button"
        type="button"
        data-test="notifications"
        v-matomo="{
          click: {
            category: 'Global',
            action: 'Notifications',
            name: 'Clicked'
          }
        }"
        @click="notifications"
      >
        <div class="tw-flex tw-items-center">
          <div class="tw-relative tw-flex">
            <ui-ctk-icon
              name="alert-on"
              data-test="icon"
            />
            <transition name="fade">
              <!-- TODO: Was a Badge but badges were used to something else. Maybe create a new component for real "badges" and rename the current one to "chips" instead? -->
              <div
                v-if="getPendingNotifications.length > 0"
                class="tw-absolute tw-top-0 tw-right-0 tw-bg-red-500 tw-text-white tw-rounded-full tw-w-4 tw-h-4 tw-text-xs"
                v-text="$n(getPendingNotifications.length)"
                data-test="badge"
              />
            </transition>
          </div>
          <span
            class="tw-hidden"
            v-text="$t('app.buttons.notifications')"
          />
        </div>
      </button>
    </template>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - CtkNavBarActions
   * @emits close
   */
  export default defineComponent({
    name: 'CtkNavBarActions',
    computed: {
      ...mapGetters('auth', [
        'isPreRegistered'
      ]),
      ...mapGetters([
        'isUserShipper',
        'getPendingNotifications'
      ])
    },
    mounted () {
      if (this.$matomo && this.getPendingNotifications && this.getPendingNotifications.length > 0) {
        this.$matomo.trackEvent('Global', 'Notifications', 'Displayed')
      }
    },
    methods: {
      /**
       * Show the search dialog
       * @function search
       */
      search () {
        this.$emit('close')
        EventBus.$emit('dialogs:search', this.$refs['search-button'])
      },
      /**
       * Show the notifications dialog
       * @function notifications
       */
      notifications () {
        this.$emit('close')
        EventBus.$emit('dialogs:notifications', this.$refs['notifications-button'])
      }
    }
  })
</script>

<style lang="scss" scoped>
.ctk-nav-bar-actions__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 55px;
  color: #818B91;
  background-color: transparent;
  transition: background-color 200ms, border-bottom 200ms;
}
.ctk-nav-bar-actions__button .ctk-font {
  font-size: 30px;
}
.ctk-nav-bar-actions__button:hover, .ctk-nav-bar-actions__button:focus {
  background-color: rgba(#303144, 0.8);
  color: rgba(255, 255, 255, 0.8);
  outline: none;
}
.ctk-nav-bar-actions__button.router-link-active {
  background-color: $secondary-lighten;
  color: white;
  outline: none;
}
@media (min-width: 770px) {
  .ctk-nav-bar-actions__button.router-link-active {
    border-bottom: 3px solid white;
  }
}
.ctk-nav-bar-actions--mobile {
  padding: 0 10px;
}
.ctk-nav-bar-actions--mobile .ctk-nav-bar-actions__button {
  width: 100%;
  text-align: left;
  max-height: 30px;
  padding-left: 0;
}
.ctk-nav-bar-actions--mobile .ctk-nav-bar-actions__button span {
  display: inline-block;
}
</style>
