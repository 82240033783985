import VueI18n from 'vue-i18n'

/**
 * @param {number} n
 * @param {number} choicesLength
 */
VueI18n.prototype.getChoiceIndex = function (n, choicesLength) {
  if (this.locale === 'pl') {
    /**
     * Override the getChoiceIndex method to handle the polish case
     * that has more than one pluralization key.
     * Some sources:
     * - https://developer.mozilla.org/en-US/docs/Mozilla/Localization/Localization_and_Plurals
     * - https://typeofweb.com/2018/01/22/odmiana-rzeczownikow-przy-liczebnikach-jezyku-polskim/
     */
    if (n === 1) {
      return 0
    } else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
      return 1
    } else {
      return choicesLength > 2 ? 2 : 1
    }
  } else {
    // Default (old) getChoiceIndex implementation - english-compatible
    // Source: https://github.com/kazupon/vue-i18n/blob/8f75b1f2db4e8cf803a36186565477916b8fa18e/src/index.js
    /**
     * @param {number} _choice
     * @param {number} _choicesLength
     */
    const defaultImpl = (_choice, _choicesLength) => {
      _choice = Math.abs(_choice)

      if (_choicesLength === 2) {
        return _choice
          ? _choice > 1
            ? 1
            : 0
          : 1
      }

      return _choice ? Math.min(_choice, 2) : 0
    }

    if (this.locale in this.pluralizationRules) {
      return this.pluralizationRules[this.locale].apply(this, [n, choicesLength])
    } else {
      return defaultImpl(n, choicesLength)
    }
  }
}
